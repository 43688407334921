(function(WE) {

	WE.events = {

    _counter: {},

    _key_base: $('input.trackingKeyBase').val(),

    _timer: null,

		_t_events: [],

    _send: function(event, callback) {
      var promise = $.ajax({
        dataType: 'json',
        type: 'POST',
        url: '/event',
        data: event
      });

      if(callback) {
        promise.done(callback);
        promise.fail(callback);
      }
    },

    // type : [L, E, C, O, D, F, P, S, ERR] == [Left, Entered, Clicked, Open, Dismissed/Done, Finished, Pending, Submit, ERROR]
    _get: function(name, type, data, isCustomEventName) {
      var eventName = name + '_' + type;
      var eventData = data ? data : {};
      if (isCustomEventName !== true) {
       eventName = this._key_base + '_' + eventName;
      }
      if (!this._counter[eventName]) {
        this._counter[eventName] = 0;
      }
      eventData.attempt = ++this._counter[eventName];
      return {
        name: eventName,
        value: eventData
      };
    },

		_flush: function() {
      var event;
      while((event = this._t_events.pop())) {
        this._send(event);
      }
      for(var name in this._unique_events) { // eslint-disable-line guard-for-in
       this._send(this._unique_events[name]);
      }
      this._unique_events = {};
		},

    push: function(name, type, data, async, callback) {
      var event = this._get(name, type, data);
      if(async) {
        this._t_events.push(event);
      }
      else {
        this._send(event, callback);
      }
    },

    pushCustom: function(name, type, data, async, callback) {
      var event = this._get(name, type, data, true);
      if(async) {
        this._t_events.push(event);
      }
      else {
        this._send(event, callback);
      }
    }
	};

  WE.events._timer = setInterval(function() {
    WE.events._flush();
  }, 500);

  $(window).unload(function() {
    WE.events._flush();
  });

}(window.WE));

$(document).ready(function() {

  function push_event(type, $ele, val) {
    var value = val ? val : $ele.val();
    var name = $ele.attr('name');
    var state = $('[data-bv-for=' + name + ']').filter(':visible').attr('data-bv-result');
    var error = $('[data-bv-for=' + name + ']').filter(':visible').text();
    if(!state && !value) { //Neutral State
      return;
    }
    value = $ele.hasClass('weTrackBlind') && value ? 'N/A' : value;
    if(/\d{15,20}/.test(value)) {
      value = 'CC_NUM';
    }
    WE.events.push(name, type, {inp_state: state || 'VALID', inp_value: value || 'EMPTY', error: error || 'N/A'});
  }

  $.each($('.weTrack, .weTrackBlind'), function(index, ele) {

    (function($ele) {
      var event = $ele.attr('we-event');
      var field = $ele.attr('data-bv-field');

      if($ele.is('input[type=checkbox]')) {

          $ele.change(function() {
            push_event('C', $ele, $ele.is(':checked') ? 'true' : 'false');
          });

      } else if($ele.is('input') && field) {

        $ele.on('blur', function() {
          push_event('L', $ele);
        });
        $ele.on('focus', function() {
          push_event('E', $ele);
        });

      } else if($ele.is('a')) {

        $ele.bind('click', function() {
          var name = ($ele.attr('data-event') || $ele.attr('data-target')).replace('#', '');
          var value = $ele.attr('data-value') || $ele.attr('value');
          WE.events.push(name, 'C', {inp_state: 'clicked', inp_value: value}, false);
        });

      } else if($ele.is('button')) {

        $ele.bind('click', function() {
          var name = $ele.attr('data-event') || $ele.attr('name');
          var value = $ele.attr('data-value') || $ele.attr('value');
          WE.events.push(name, 'C', {inp_state: 'clicked', inp_value: value}, false);
        });

      }

    }($(ele)));

	});

});