var weForm = require('../../../utils/weForm');
var noCreditsHelper = require('../../../helpers/nocredits');

function getPurchaseDataExistingCCHandler($form) {
  var serverData = {
    plan: weForm.radioValue($form, 'plan'),
    cardId: weForm.radioValue($form, 'cardId'),
    email: weForm.inputValue($form, 'email')
  };
  return serverData;
}

function getPurchaseDataAddCCHandler($form) {
  var cc = weForm.inputValue($form, 'ccnumber').replaceAll(' ', '');

  var serverData = {
    name: weForm.inputValue($form, 'name'),
    plan: weForm.radioValue($('#existingPaymentForm'), 'plan'),
    email: weForm.inputValue($form, 'payload'),
    bin: cc.slice(0, 6),
    last4: cc.slice(-4)
  };

  return serverData;
}

module.exports = function () {
  noCreditsHelper.init(window.noCreditsEmailURL, getPurchaseDataExistingCCHandler, getPurchaseDataAddCCHandler);
};
