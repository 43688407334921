/* globals hMapAppId: true, hMapAppCode: true, H: true */
var radar;

function init(container) {
  var platform = new H.service.Platform({
    app_id: hMapAppId,
    app_code: hMapAppCode,
    useHTTPS: true
  });
  var defaultLayers = platform.createDefaultLayers();
  var map = new H.Map(document.getElementById(container), defaultLayers.satellite.xbase, {
    center: {lat: 30.8904725, lng: -100.466748},
    zoom: 4
  });
  return map;
}

function addMarker(map, location) {
  var icon = new H.map.Icon(window.weStaticAssetPrefix + '/images/cell-tower.png');
  var marker = new H.map.Marker({ lat: location.lat, lng: location.lng }, { icon: icon });
  map.addObject(marker);
  return marker;
}

function clearMarker(map, marker) {
  map.removeObject(marker);
}

function getDistance(point1, point2) {
  var placePosition = new H.geo.Point(point1.lat, point1.lng);
  return placePosition.distance(new H.geo.Point(point2.lat, point2.lng));
}

function setOffsetBounds(map, bounds) {
  var leftBottom = map.geoToScreen(new H.geo.Point(bounds.getBottom(), bounds.getLeft()));
  var center = map.screenToGeo(
    leftBottom.x - 50,
    leftBottom.y + 75
  );
  map.setCenter(center, true);
  map.setViewBounds(bounds.mergePoint(center), true);
}

function getCircle(center) {
  return new H.map.Circle({ lat: center.lat, lng: center.lng }, 2000000, { style: { strokeColor: '#C0C0C0', lineWidth: 1 } });
}

function showRadar(map, center, callback) {
  if (!radar) {
    radar = getCircle(center);
  }

  var radius = radar.getRadius();
  map.addObject(radar);

  var redraw = function() {
    var r = radar.getRadius() - parseInt(radius * 0.05);
    radar.setRadius(r);
    if(r > radius/4 ) {
      setTimeout(redraw, 100);
      return;
    }
    setOffsetBounds(map, radar.getBounds());
    if(callback) {
      callback();
    }
  };
  redraw();
}

module.exports = {
  init: init,
  addMarker: addMarker,
  clearMarker: clearMarker,
  getDistance: getDistance,
  showRadar: showRadar
};