var weAjax = require('../../../utils/weAjax');

function setupUpPopupEvents(monitorPopup) {
  monitorPopup.on('hidden.bs.modal', function () {
    WE.events.push('monitorPopup', 'D', {inp_state: 'dismissed'}, false);
  });
  monitorPopup.on('shown.bs.modal', function () {
    WE.events.push('monitorPopup', 'O', {inp_state: 'open'}, false);
  });
  $('button', monitorPopup).click(function () {
    WE.events.push('monitorPopup', 'S', { inp_state: 'submit' }, false);
    monitorPayload(monitorPopup);
  })
}

function monitorPayload(monitorPopup) {
  var plan = $('#payloadMonitorPlan').val();
  var payload = $('#payloadToMonitor').val();
  var button = $('button', monitorPopup);

  button.attr('disabled', true);

  weAjax.post(window.monitorPayloadURL, {payload: payload, plan: plan}, function () {
    monitorPopup.modal('hide');
    $('#subSuccess').removeClass('hidden');
  }, function () {
    monitorPopup.modal('hide');
    $('#subError').removeClass('hidden');
  });
}

module.exports = function () {
  var monitorPopup = $('#payloadMonitorModal');
  if (!monitorPopup.get(0)) {
    return;
  }

  setupUpPopupEvents(monitorPopup);
  monitorPopup.modal({ show: true });
};