var validators = require('../utils/validators');
var weForm = require('../utils/weForm');
var weAjax = require('../utils/weAjax');

function getOptoutFormData(type, contactForm) {
  return {
    type: type,
    payload: weForm.inputValue(contactForm, 'payload'),
    feedback: weForm.textareaValue(contactForm, 'feedback'),
    'g-recaptcha-response': weForm.textareaValue(contactForm, 'g-recaptcha-response')
  }
}

function renderReCaptcha(reCaptchaId) {
  if (!window.reCaptchaJSLoaded || !window.grecaptcha) {
    return -1;
  }
  try {
    return window.grecaptcha.render(reCaptchaId, {'sitekey' : window.reCaptchaSiteKey});
  } catch (e) {}
  return -1;
}

function resetReCaptcha(reCaptchaId) {
  try {
    window.grecaptcha.reset(reCaptchaId);
  } catch (e) {}
}

function initOptoutForm(type, container, formId, reCaptchaEleId) {
  var submitInProgress = false;
  var optoutContainer = $(container);
  var optoutForm = $(formId);
  var optoutButton = $('.btn-primary', optoutContainer);
  var optoutError = $('.optout-error', optoutContainer);
  var captchaHint = $('.captcha-hint', optoutContainer);
  var captchaError = $('.captcha-error', optoutContainer);
  var optoutDone = $('.optout-done', optoutContainer);
  var optoutFormContainer = $('.optout-form', optoutContainer);
  var reCaptchaWidgetId, reCapTimer;

  optoutForm.bootstrapValidator({
    fields: {
      payload: type === 'email' ? validators.EmailValidator : validators.PhoneValidator,
      feedback: validators.StringValidator(5, 500)
    }
  });

  optoutForm.on('success.form.bv', function(e) {
    if(submitInProgress) {
      return;
    }

    optoutButton.attr('disabled', true);

    submitInProgress = true;
    e.preventDefault();

    optoutError.addClass('hidden');

    weAjax.post('/optout', getOptoutFormData(type, optoutForm), function () {
      optoutDone.removeClass('hidden');
      optoutFormContainer.addClass('hidden');
    }, function () {
      submitInProgress = false;
      optoutButton.attr('disabled', false);
      optoutError.removeClass('hidden');
      resetReCaptcha(reCaptchaWidgetId);
    });

  });

  optoutForm.submit(function() {
    var bvForm = optoutForm.data('bootstrapValidator');

    optoutError.addClass('hidden');

    if (bvForm.isValid() !== true) {
      bvForm.validate();
    } else {
      optoutForm.trigger('success.form.bv')
    }
    return false;
  });

  validators.enableLazyValidation(optoutForm);

  function validateCaptchaLoad() {
    reCaptchaWidgetId = renderReCaptcha(reCaptchaEleId);
    if (reCaptchaWidgetId >= 0) {
      clearInterval(reCapTimer);
      captchaHint.addClass('hidden');
      captchaError.addClass('hidden');
      optoutFormContainer.removeClass('hidden');
    } else {
      captchaError.removeClass('hidden');
      optoutFormContainer.addClass('hidden');
    }
  }

  reCapTimer = setInterval(validateCaptchaLoad, 1500);
}

module.exports = function () {
  initOptoutForm('phone', '#phoneOptout', '#phoneOptoutForm', 'phoneReCaptcha');
  initOptoutForm('email', '#emailOptout', '#emailOptoutForm', 'emailReCaptcha');

};