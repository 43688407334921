/* globals mapBoxAPI: true, L: true */
var radar;

function init(container) {
  L.mapbox.config.FORCE_HTTPS = true;
  L.mapbox.accessToken = mapBoxAPI;

  var map = L.mapbox.map(container, 'mapbox.satellite', {
    legendControl: false,
    dragging: false,
    zoomControl: false,
    touchZoom: false,
    scrollWheelZoom: false,
    keyboard: false,
    center: [30.8904725, -100.466748],
    zoom: 4
  });

  setTimeout(map.invalidateSize.bind(map), 500);
  return map;
}

function addMarker(map, location) {
  var icon = L.icon({
    iconUrl: window.weStaticAssetPrefix + '/images/cell-tower.png'
  });
  return L.marker([location.lat, location.lng], {icon: icon}).addTo(map);
}

function clearMarker(map, marker) {
  map.removeLayer(marker);
}

function getDistance(point1, point2) {
  var placePosition = L.latLng(point1.lat, point1.lng);
  return placePosition.distanceTo(L.latLng(point2.lat, point2.lng));
}

function setOffsetBounds(map, bounds) {
  var leftBottom = map.project(bounds.getSouthWest());
  var center = map.unproject(L.point(
    leftBottom.x - 50,
    leftBottom.y + 75
  ));
  map.setView(center);
  map.fitBounds(bounds.extend(center));
}

function getCircle(center) {
  return L.circle(L.latLng(center.lat, center.lng), 2000000, { stroke: true, color: '#C0C0C0', weight: 1 });
}

function showRadar(map, center, callback) {
  if (!radar) {
    radar = getCircle(center);
  }

  var radius = radar.getRadius();
  map.addLayer(radar);

  var redraw = function() {
    var r = radar.getRadius() - parseInt(radius * 0.05);
    radar.setRadius(r);
    if(r > radius/4 ) {
      setTimeout(redraw, 100);
      return;
    }
    setOffsetBounds(map, radar.getBounds());
    if(callback) {
      callback();
    }
  };
  redraw();
}

module.exports = {
  init: init,
  addMarker: addMarker,
  clearMarker: clearMarker,
  getDistance: getDistance,
  showRadar: showRadar
};