require('../external/libphonenumber-utils');

module.exports = function() {
  var telInput = $(".w-phone");
  var errorMsg = $(".phone-error-msg");
  var validMsg = $(".phone-valid-msg");
  var warningMsg = $(".phone-warning-msg");
  var hintMsg = $(".phone-search-hint");
  var searchButton = $(".w-phone-search-button");

  var prev_err_phone = null;

  function validatePhone() {
    var phoneNo = telInput.intlTelInput("getNumber");
    var baseDigits = phoneNo.replace("+1", "");
    if (phoneNo) {
      hintMsg.addClass("hide");
      if (telInput.intlTelInput("isValidNumber")) {
        telInput.removeClass("error");
        validMsg.removeClass("hide");
        warningMsg.addClass("hide");
        errorMsg.addClass("hide");
        searchButton.removeClass("disabled");
        return true;
      } else {
        searchButton.addClass("disabled");
        telInput.addClass("error");
        validMsg.addClass("hide");

        if (baseDigits.length < 10) {
          warningMsg.removeClass("hide");
          errorMsg.addClass("hide");
        } else {
          warningMsg.addClass("hide");
          errorMsg.removeClass("hide");
          if(baseDigits !== prev_err_phone) {
            WE.events.push('PhoneSearchBar', 'ERR', {inp_state: 'INVALID', inp_value: baseDigits, error: errorMsg.html()});
            prev_err_phone = baseDigits;
          }
        }
      }
    } else {
      warningMsg.addClass("hide");
      errorMsg.addClass("hide");
      validMsg.addClass("hide");
      searchButton.addClass("disabled");
      hintMsg.removeClass("hide");
    }
    return false;
  }

  if (telInput && telInput.length !== 0) {

    telInput.intlTelInput({
      onlyCountries: ["us", "ca", "ai", "ag", "bs", "bb", "bm", "vg", "ky", "dm", "do", "gd", "jm", "ms", "sx", "kn", "lc", "vc", "tt", "tc"],
      nationalMode: true,
      autoFormat: true,
      preventInvalidNumbers: true
    });

    telInput.intlTelInput("utilsLoaded");

    telInput.bind("keyup", function() {
      validatePhone();
    });

    telInput.bind("paste", function(e) {
      try {
        var clipboardData = e.originalEvent.clipboardData;
        telInput.intlTelInput("setNumber", clipboardData.getData("text/plain"));
        validatePhone();
        return false;
      } catch(e) {}
      validatePhone();
    });

    var telInputForm = telInput.parents("form:first");
    var telInputFormSubmit = $("[type='submit']", telInputForm);

    telInputForm.submit(function() {
      var valid = validatePhone();
      if(valid) {
        telInputFormSubmit.attr('disabled', 'disabled');
      }
      return valid;
    });

    if(telInput.intlTelInput("isValidNumber")) {
      searchButton.removeClass("disabled");
    }
    telInput.focus();
  }
};