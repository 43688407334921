/* globals EMAIL_INFO */

var STEPS_DEFERRED = [], STEPS_PROMISES = [], MODAL_DEFERRED = [], MODAL_PROMISES = [];

function handleLookupProgress(container, num_steps, interval, callback) {
  var $container = $(container);
  var $percentage = $('.percentage', $container);
  var $eface = $('.email-face', $container);
  var $texts = $('.progress-texts', $container);
  var $bar = $('.progress-bar', $container);
  var $states = $('.progress-states', $container);
  var $icons = $('.icons-listing', $container);

  var step_interval = window.isDevMode ? 1000 : (interval * 1000);
  var percentage = 0;
  var icons_index = 0;
  var total_duration = num_steps * step_interval;
  var interval_per_percent = total_duration /100;
  var face_per_interval = (40 * interval_per_percent) / total_duration;

  var icons = [
    'Facebook', 'Twitter', 'YouTube', 'LinkedIn', 'Pinterest', 'Skype', 'Google Plus', 'Flickr',
    'Pandora', 'Picasa', 'FourSquare', 'Reddit', 'Smug Mug', 'Tumblr', 'Vimeo', 'Yelp', 'Aol Chat',
    'Facebook Chat', 'ICQ Chat', 'Gravatar', 'GTalk', 'LiveJournal', 'Meetup', 'Google Profile', 'Bebo',
    'MySpace', 'About.me', 'Blogger', 'Delicious', 'Digg', 'Friendster', 'IMDB', 'Hi5', 'Last.FM',
    'Quora', 'Scribd', 'VK', 'WordPress', 'Xing', 'Meadiciona', 'Plancast', 'Bandcamp',
    'Academia.edu', 'Hacker News', 'GitHub', 'Blippy', 'Dribble', 'Amplify', 'Qik', 'Orkut', 'Steam',
    'IRC', 'eConsultancy', 'Gist', 'Sound Cloud', 'BlipFM', 'FriendFeed', 'DandyId', 'Stumble Upon',
    'Mixcloud', 'gdgt', 'StackExchange', 'Get Satisfaction', 'Hunch', 'SlideShare', 'AngelList',
    'Do You Buzz', 'Ohloh', 'Mixi', 'Type Pad', 'Disqus', 'FullContact', 'Good Reads',
    'Klout', 'Deviant Art', 'Flavors.me', 'Hyves', 'Diigo', 'Lanyrd', 'Ren Ren', 'BitBucket',
    'Crunchbase', 'Plurk', 'Get Glue', 'Shelfari', 'Tagged', 'Pin Board', 'Identi.ca', 'Plaxo',
    'HypeMachine', 'Intense Debate', 'StackOverflow', 'Tripit'
  ];

  var $progresser = setInterval(function () {

    var step = 0;
    $percentage.html(percentage++ + '%');
    $bar.css('width', percentage + '%');

    var face = parseInt(face_per_interval * percentage);
    $eface.removeClass('step-' + face);
    $eface.addClass('step-' + (face + 1));

    if(percentage >= 100) {
      clearInterval($progresser);
      callback(num_steps);
    } else {
      step = parseInt(percentage / (100/num_steps));
      $states.removeClass('step-' + step);
      $states.addClass('step-' + (step + 1));
      $texts.removeClass('step-' + step);
      $texts.addClass('step-' + (step + 1));
      callback(step);
    }

  }, interval_per_percent);


  var $listing = setInterval(function () {
    var icon = icons[icons_index++];
    var label, url;
    if(icon) {
      label = icon;
      icon = icon.replaceAll('\\s+', '').replaceAll('\\.', '').toLowerCase();
      url = window.weStaticAssetPrefix + '/images/social-nw/64px/' + icon + '.png';
      $icons.prepend('<div class="img-thumbnail animated zoomIn"><img src="' + url + '">' + label);
    }

    if(icons_index >= total_duration/1000) {
      clearInterval($listing);
    }

  }, 1000);

}

function initLoadingAnimation() {
  setTimeout(function () {
    handleLookupProgress('.email-initiated-modal', 5, 5, function (step) {
      STEPS_DEFERRED[step].resolve();
    });
  }, 250);
}

module.exports = function () {

  $.each(new Array(6), function (index) {
    STEPS_DEFERRED[index] = $.Deferred();
    STEPS_PROMISES[index] = STEPS_DEFERRED[index].promise();
  });

  $.each(new Array(1), function (index) {
    MODAL_DEFERRED[index] = $.Deferred();
    MODAL_PROMISES[index] = MODAL_DEFERRED[index].promise();
  });

  STEPS_PROMISES[0].then(function () {
  });

  STEPS_PROMISES[1].then(function () {
  });

  STEPS_PROMISES[2].then(function () {
  });

  STEPS_PROMISES[3].then(function () {
  });

  STEPS_PROMISES[4].then(function () {
  });

  STEPS_PROMISES[5].then(function () {
    MODAL_DEFERRED[0].resolve();
  });

  MODAL_PROMISES[0].then(function () {
    window.location = window.redirectURL;
  });

  initLoadingAnimation();

};