/* globals timeoutURL, resultURL */

var validators = require('../../../utils/validators');

function init() {
  var navigateAway = true;
  setInterval(function() {
    if(navigateAway) {
      window.location = window.timeoutURL || window.resultURL;
    }
    navigateAway = true;
  }, 30000);

  $('body').click(function () {
    navigateAway = false;
  });
  $('body').keyup(function () {
    navigateAway = false;
  });
}

module.exports = function () {
  $('#completeSignupForm').bootstrapValidator({
    verbose: false,
    fields: {
      email: validators.EmailAjaxValidator,
      password: validators.IdenticalPassword,
      confirmPassword: validators.IdenticalConfirmPassword
    }
  });

  var correctEmail = $('#correctEmail');
  var inCorrectEmail = $('#inCorrectEmail');
  var inputEmail = $('#inputEmail');
  var donotSuggest = $('#donotSuggest');

  correctEmail.click(function () {
    var email = correctEmail.attr('email');
    inputEmail.val(email);
    donotSuggest.val("true");
    correctEmail.parent('.alert').addClass('hidden');
    inputEmail.parent('.has-error').removeClass('has-error');
  });

  inCorrectEmail.click(function () {
    donotSuggest.val("true");
    inCorrectEmail.parent('.alert').addClass('hidden');
    inputEmail.parent('.has-error').removeClass('has-error');
  });

  init();
};