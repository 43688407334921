var validators = require('../utils/validators');
var weAjax = require('../utils/weAjax');
var weForm = require('../utils/weForm');
var purchaseFormHandler = require('../modules/purchaseForm');

function setCurrentForPlan(plan) {
  var visibleCharge = $('.choose-plan #' + plan + ' .visibleCharge').text();
  var numCredits = $('.choose-plan #' + plan + ' .numCredits').text();

  $('.selected-plan .visibleCharge').text(visibleCharge);
  $('.selected-plan .numCredits').text(numCredits);
}

function currentPlanPriceHandling() {
  var currentPlan = $('input[name="plan"]:checked').val();
  setCurrentForPlan(currentPlan);

  $('input[name="plan"]').click(function() {
    setCurrentForPlan(this.value);
  });
}

function purchaseFormExisting(purchaseUrl, existingCCDataHandler) {
  var purchaseForm = $('#existingPaymentForm');

  purchaseForm
    .bootstrapValidator({
      fields: {
      }
    })
    .on('success.form.bv', function(e) {
      e.preventDefault();

      var $form = $(e.target);
      $('form .error').addClass('hidden');

      var purchaseData = existingCCDataHandler($form);
      weAjax.post(purchaseUrl, purchaseData);
    });
}

function handlePurchaseError(responseObject, bvForm, tryAnotherCard) {
}

function handlePurchaseSuccess(purchaseData) {
}

function purchaseFormAddCC(purchaseUrl, addCCDataHandler) {
  var addCCButton = $('#addCCButton');
  var fields = {
    name: validators.CardHolderNameValidator,
    ccnumber: validators.CCVAlidator,
    month: validators.MonthValidator,
    cvv: validators.CVVValidator,
    year: validators.YearValidator
  };
  purchaseFormHandler('#addCCPaymentForm', 'button[name="submitNoCredits"]', fields, purchaseUrl, addCCDataHandler, handlePurchaseSuccess, handlePurchaseError);
}

function setCurrentForm(val) {
  if(val === 'new') {
    $('.cc-add-form').show();
  }
  else {
    $('.cc-add-form').hide();
  }

  $('#addCCPaymentForm').data('bootstrapValidator').resetForm();
  $('form .error').addClass('hidden');
}

function currentCCFormHandling() {
  var existingPaymentForm = $('#existingPaymentForm');

  var currentForm = $('input[name="cardId"]:checked').val();
  setCurrentForm(currentForm);

  $('input[name="cardId"]', existingPaymentForm).change(function() {
    setCurrentForm(this.value);
  });
}

function init(purchaseUrl, existingCCDataHandler, addCCDataHandler) {
  var existingPaymentForm = $('#existingPaymentForm');
  var addCCPaymentForm = $('#addCCPaymentForm');

  $('#choose-plan-submit').submit(function(event) {
    event.preventDefault();
    var cardId = weForm.radioValue(existingPaymentForm, 'cardId');
    if(cardId === 'new') {
      addCCPaymentForm.trigger('submit');
    }
    else {
      existingPaymentForm.trigger('submit');
    }
    return false;
  });

  currentPlanPriceHandling();
  purchaseFormExisting(purchaseUrl, existingCCDataHandler);
  purchaseFormAddCC(purchaseUrl, addCCDataHandler);
  currentCCFormHandling();
}

module.exports = {
  init: init
};