/* globals PHONE_INFO: true */
/* globals weMapModule: true */

function handleLookupProgress(num_steps, interval, callback) {
  var $percentage = $('.percentage');
  var $texts = $('.progress-texts'), $bar = $('.progress-bar'), $states = $('.progress-states');
  var step_interval = window.isDevMode ? 1000 : (interval * 1000);
  var percentage = 0;
  var interval_per_percent = num_steps * step_interval/100;

  var $progresser = setInterval(function () {

    var step = 0;
    $percentage.html(percentage++ + '%');
    $bar.css('width', percentage + '%');

    if(percentage >= 100) {
      clearInterval($progresser);
      callback();
    } else {
      step = parseInt(percentage / (100/num_steps));
      $states.removeClass('step-' + step);
      $states.addClass('step-' + (step + 1));
      $texts.removeClass('step-' + step);
      $texts.addClass('step-' + (step + 1));
    }

  }, interval_per_percent);
}

function initLoadingAnimation(numSteps, stepInterval, callback) {
  setTimeout(function () {
    handleLookupProgress(numSteps, stepInterval, callback);
  }, 250);
}

module.exports = function () {
  initLoadingAnimation(5, 2, function () {
    window.location = window.redirectURL;
  });
};