module.exports = {
'./pages/contact': require('./pages/contact'),
'./pages/feedback': require('./pages/feedback'),
'./pages/index': require('./pages/index'),
'./pages/optout': require('./pages/optout'),
'./pages/purchase-hosted': require('./pages/purchase-hosted'),
'./pages/purchase': require('./pages/purchase'),
'./pages/a/up': require('./pages/a/up'),
'./pages/auth/forgot': require('./pages/auth/forgot'),
'./pages/auth/login': require('./pages/auth/login'),
'./pages/auth/passwordreset': require('./pages/auth/passwordreset'),
'./pages/auth/passwordresetsent': require('./pages/auth/passwordresetsent'),
'./pages/emi/loading': require('./pages/emi/loading'),
'./pages/emi/purchase-hosted': require('./pages/emi/purchase-hosted'),
'./pages/emi/purchase': require('./pages/emi/purchase'),
'./pages/pni/loading-map': require('./pages/pni/loading-map'),
'./pages/pni/loading-nomap': require('./pages/pni/loading-nomap'),
'./pages/pni/purchase-hosted': require('./pages/pni/purchase-hosted'),
'./pages/pni/purchase': require('./pages/pni/purchase'),
'./pages/a/emi/nocreditsemail': require('./pages/a/emi/nocreditsemail'),
'./pages/a/emi/premiumemail': require('./pages/a/emi/premiumemail'),
'./pages/a/pni/nocreditsphone': require('./pages/a/pni/nocreditsphone'),
'./pages/a/pni/premiumname': require('./pages/a/pni/premiumname'),
'./pages/a/pni/premiumsearch': require('./pages/a/pni/premiumsearch'),
'./pages/a/user/changeSubConfirm': require('./pages/a/user/changeSubConfirm'),
'./pages/a/user/changeSubTerms': require('./pages/a/user/changeSubTerms'),
'./pages/a/user/completeSignup': require('./pages/a/user/completeSignup'),
'./pages/a/user/profile': require('./pages/a/user/profile'),
'./external/bootstrapValidator.min': require('./external/bootstrapValidator.min'),
'./external/intltelinput.min': require('./external/intltelinput.min'),
'./external/libphonenumber-utils': require('./external/libphonenumber-utils'),
};