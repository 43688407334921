var validators = require('../utils/validators');

module.exports = function() {
  var emailInput = $('.w-email');
  var errorMsg = $(".email-error-msg");
  var validMsg = $(".email-valid-msg");
  var warningMsg = $(".email-warning-msg");
  var hintMsg = $(".email-search-hint");
  var searchButton = $(".w-email-search-button");
  var formSubmit = false;

  function validateEmail() {
    var emailAddress = $.trim(emailInput.val());
    if (emailAddress) {
      hintMsg.addClass("hide");
      if (emailAddress.isEmail()) {
        emailInput.removeClass("error");
        validMsg.removeClass("hide");
        warningMsg.addClass("hide");
        errorMsg.addClass("hide");
        searchButton.removeClass("disabled");
        return true;
      } else {
        searchButton.addClass("disabled");
        emailInput.addClass("error");
        validMsg.addClass("hide");
        if (formSubmit) {
          warningMsg.addClass("hide");
          errorMsg.removeClass("hide");
        } else {
          warningMsg.removeClass("hide");
          errorMsg.addClass("hide");
        }
      }
    } else {
      warningMsg.addClass("hide");
      errorMsg.addClass("hide");
      validMsg.addClass("hide");
      searchButton.addClass("disabled");
      hintMsg.removeClass("hide");
    }
    return false;
  }

  if (emailInput && emailInput.length !== 0) {

    emailInput.bind("keyup", function(e) {
      var code = e && e.which;
      formSubmit = code === 13;
      validateEmail();
      formSubmit = false;
    });

    emailInput.bind("paste", function(e) {
      try {
        var clipboardData = e.originalEvent.clipboardData;
        emailInput.val(clipboardData.getData("text/plain"));
        validateEmail();
        return false;
      } catch(e) {}
      validateEmail();
    });

    var emailInputForm = emailInput.parents("form:first");
    var emailInputFormSubmit = $("[type='submit']", emailInputForm);

    emailInputForm.submit(function() {
      var emailAddress = $.trim(emailInput.val());
      var valid = validateEmail();
      if(valid) {
        emailInputFormSubmit.attr('disabled', 'disabled');
      } else {
        WE.events.push('EmailSearchBar', 'ERR', { inp_state: 'INVALID', inp_value: emailAddress });
      }
      return valid;
    });

    if(emailInput.val().isEmail()) {
      searchButton.removeClass("disabled");
    }
    emailInput.focus();
  }
};