var emailFormHandler = require('./emailForm');
var phoneFormHandler = require('./phoneForm');

module.exports = function () {
  phoneFormHandler();
  emailFormHandler();

  $('.search-number-form a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    var panel = $(e.target).attr('href');
    $(panel + ' input').focus();
  });
  
};
