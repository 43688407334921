/* globals PHONE_INFO: true */
/* globals weMapModule: true */

var USA_MAP = require('../../utils/usa-map');
var mapHelper;

function initMapHelper(mapModule) {
  mapHelper = require('../../helpers/map-' + mapModule);
}

var STEPS_DEFERRED = [], STEPS_PROMISES = [], MARKERS = [], MODAL_DEFERRED = [], MODAL_PROMISES = [];
var MAP = null, CITY = null, STATE_OR_COUNTRY = null, CITY_MARKER = null;

function clearMarkers() {
  $.each(MARKERS, function (index, marker) {
    mapHelper.clearMarker(MAP, marker)
  });
  MARKERS = [];
}

function addMarker(pos) {
  var marker = mapHelper.addMarker(MAP, pos);
  MARKERS.push(marker);
  return marker;
}

function showCity(city) {
  CITY_MARKER = addMarker(city);
}

function showCityAndNearbyPlaces() {
  clearMarkers();
  showCity(CITY);

  if(STATE_OR_COUNTRY.country !== true) {
    addPlaces(PHONE_INFO.nearby, CITY);
  }
}

function showStateOrCountry(stateOrCountry) {
  clearMarkers();
  addCities(stateOrCountry.cities, stateOrCountry);

  if(MARKERS.length === 0) {
    showCity(CITY);
  }
}

function addCities(cities, center) {
  var numCityTowers = center.country ? 5 : 10;

  $.each(cities, function (key, city) {
    if(MARKERS.length > numCityTowers) {
      return;
    }
    var distance = mapHelper.getDistance(city, center);
    if(distance > 15000) {
      addMarker(city);
    }
  });
}

function addPlaces(places, center) {
  var numPlacesTowers = center.country ? 3 : 5;

  $.each(places, function (index, place) {
    if(MARKERS.length > numPlacesTowers) {
      return;
    }
    var distance = mapHelper.getDistance(place, center);
    if(distance > 15000) {
      addMarker(place);
    }
  });
}

function showRadar(callback) {
  mapHelper.showRadar(MAP, CITY, callback);
}

function handleLookupProgress(num_steps, interval, callback) {
  var $percentage = $('.percentage');
  var $texts = $('.progress-texts'), $bar = $('.progress-bar'), $states = $('.progress-states');
  var step_interval = window.isDevMode ? 1000 : (interval * 1000);
  var percentage = 0;
  var interval_per_percent = num_steps * step_interval/100;

  var $progresser = setInterval(function () {

    var step = 0;
    $percentage.html(percentage++ + '%');
    $bar.css('width', percentage + '%');

    if(percentage >= 100) {
      clearInterval($progresser);
      callback(num_steps);
    } else {
      step = parseInt(percentage / (100/num_steps));
      $states.removeClass('step-' + step);
      $states.addClass('step-' + (step + 1));
      $texts.removeClass('step-' + step);
      $texts.addClass('step-' + (step + 1));
      callback(step);
    }

  }, interval_per_percent);
}

function initLoadingAnimation(numSteps, zoomInterval) {
  MAP = mapHelper.init('map');

  $.each(USA_MAP, function (key, state) {
    if(state.show) {
      addMarker(state);
    }
  });

  setTimeout(function () {
    handleLookupProgress(numSteps, zoomInterval, function (step) {
      STEPS_DEFERRED[step].resolve();
    });
  }, 250);
}

// Track map tiles loading at given stage
function trackMapLoading(numSteps, step, zoomInterval, callback) {
  var stepPercentage = 100 * step / numSteps;
  WE.events.push('step', 'F', { map_step: step, map_zoom: MAP.getZoom(), map_loaded: true, step_percent: stepPercentage }, false, callback);
}

module.exports = function () {
  var zoomInterval = PHONE_INFO.zoomInterval || 6;
  var numSteps = 5;

  $.each(new Array(6), function (index) {
    STEPS_DEFERRED[index] = $.Deferred();
    STEPS_PROMISES[index] = STEPS_DEFERRED[index].promise();
  });

  $.each(new Array(1), function (index) {
    MODAL_DEFERRED[index] = $.Deferred();
    MODAL_PROMISES[index] = MODAL_DEFERRED[index].promise();
  });

  STEPS_PROMISES[0].then(function () {
    MAP && showRadar(function () {
      trackMapLoading(numSteps, 1, zoomInterval);
    });
  });

  STEPS_PROMISES[1].then(function () {
    MAP && showRadar(function () {
      trackMapLoading(numSteps, 2, zoomInterval);
    });
  });

  STEPS_PROMISES[2].then(function () {
    MAP && showStateOrCountry(STATE_OR_COUNTRY);
  });

  STEPS_PROMISES[3].then(function () {
    MAP && showRadar(function () {
      trackMapLoading(numSteps, 3, zoomInterval);
    });
    setTimeout(showCityAndNearbyPlaces, zoomInterval * 1000/2);
  });

  STEPS_PROMISES[4].then(function () {
    MAP && showRadar(function () {
      trackMapLoading(numSteps, 4, zoomInterval);
    });
  });

  STEPS_PROMISES[5].then(function () {
    MAP && showRadar(function () {
      trackMapLoading(numSteps, 5, 2, function () {
        MODAL_DEFERRED[0].resolve();
      });
    });
  });

  MODAL_PROMISES[0].then(function () {
    window.location = window.redirectURL;
  });

  if (PHONE_INFO.location) {
    CITY = {
      lat: PHONE_INFO.location.lat,
      lng: PHONE_INFO.location.lng
    }
  }
  STATE_OR_COUNTRY = USA_MAP[PHONE_INFO.state] || USA_MAP[PHONE_INFO.isocc];
  CITY = CITY || STATE_OR_COUNTRY;

  initMapHelper(weMapModule);
  initLoadingAnimation(numSteps, zoomInterval);

};