var validators = require('../../utils/validators');

module.exports = function () {
  $('#forgotForm').bootstrapValidator({
    fields: {
      email: validators.EmailValidator
    }
  });
  validators.enableLazyValidation($('#forgotForm'));
};
