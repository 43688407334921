var purchaseHandler = require('../purchase');

function setupUpPopupEvents() {
  var popup = $('#reportCompiled');
  popup.on('hidden.bs.modal', function () {
    WE.events.push('compilePopup', 'D', {inp_state: 'dismissed'}, false);
  });
  popup.on('shown.bs.modal', function () {
    WE.events.push('compilePopup', 'O', {inp_state: 'open'}, false);
  });
  popup.modal('show');
}

module.exports = function () {
  setupUpPopupEvents();
  purchaseHandler(window.phonePurchaseURL);
};