module.exports = {
  "AL": {
    "name": "Alabama", "lat": 32.7990, "lng": -86.8073, "show": true,
    "cities": [
      { "name": "Anniston", "lat": 33.58 , "lng": -85.85 },
      { "name": "Auburn", "lat": 32.67 , "lng": -85.44 },
      { "name": "Birmingham", "lat": 33.57 , "lng": -86.75 },
      { "name": "Centreville", "lat": 32.90 , "lng": -87.25 },
      { "name": "Dothan", "lat": 31.32 , "lng": -85.45 },
      { "name": "Fort Rucker", "lat": 31.28 , "lng": -85.72 },
      { "name": "Gadsden", "lat": 33.97 , "lng": -86.09 },
      { "name": "Huntsville", "lat": 34.65 , "lng": -86.77 },
      { "name": "Maxwell AFB", "lat": 32.38 , "lng": -86.37 },
      { "name": "Mobile", "lat": 30.68 , "lng": -88.25 },
      { "name": "Mobile Aeros", "lat": 30.63 , "lng": -88.07 },
      { "name": "Montgomery", "lat": 32.30 , "lng": -86.40 },
      { "name": "Muscle Shoal", "lat": 34.75 , "lng": -87.62 },
      { "name": "Selma", "lat": 32.34 , "lng": -86.99 },
      { "name": "Troy", "lat": 31.87 , "lng": -86.02 },
      { "name": "Tuscaloosa", "lat": 33.23 , "lng": -87.62 }
    ]
  },
  "AK": {
    "name": "Alaska", "lat": 61.3850, "lng": -152.2683,
    "cities": [
      { "name": "Adak", "lat": 51.88, "lng": -176.65 },
      { "name": "Ambler", "lat": 67.10, "lng": -157.85 },
      { "name": "Anchorage", "lat": 61.17, "lng": -150.02 },
      { "name": "Anchorage", "lat": 61.22, "lng": -149.85 },
      { "name": "Angoon", "lat": 57.83, "lng": -134.97 },
      { "name": "Aniak", "lat": 61.58, "lng": -159.53 },
      { "name": "Annette Isl", "lat": 55.03, "lng": -131.57 },
      { "name": "Attu", "lat": 53.50, "lng": -173.30 },
      { "name": "Barrow", "lat": 71.30, "lng": -156.78 },
      { "name": "Barter Islan", "lat": 70.13, "lng": -143.63 },
      { "name": "Bethel Arpt", "lat": 60.78, "lng": -161.80 },
      { "name": "Bettles", "lat": 66.92, "lng": -151.52 },
      { "name": "Big Delta", "lat": 64.00, "lng": -145.73 },
      { "name": "Big River Lk", "lat": 60.82, "lng": -152.30 },
      { "name": "Cantwell", "lat": 63.38, "lng": -148.95 },
      { "name": "Cape Decis", "lat": 56.00, "lng": -134.22 },
      { "name": "Cape Hinchi", "lat": 60.38, "lng": -147.08 },
      { "name": "Cape Spence", "lat": 58.33, "lng": -137.05 },
      { "name": "Cape St Eli", "lat": 60.33, "lng": -145.00 },
      { "name": "Chandalar Lk", "lat": 67.50, "lng": -148.48 },
      { "name": "Chulitna", "lat": 62.88, "lng": -149.83 },
      { "name": "Circle City", "lat": 65.83, "lng": -144.07 },
      { "name": "Cold Bay", "lat": 55.20, "lng": -162.72 },
      { "name": "Cordova", "lat": 60.50, "lng": -145.50 },
      { "name": "Craig", "lat": 55.80, "lng": -133.25 },
      { "name": "Deadhorse", "lat": 70.20, "lng": -148.47 },
      { "name": "Dillingham", "lat": 59.05, "lng": -158.52 },
      { "name": "Duffys Tavern", "lat": 62.73, "lng": -143.92 },
      { "name": "Dutch Harbor", "lat": 53.88, "lng": -166.53 },
      { "name": "Eagle Arpt", "lat": 64.77, "lng": -141.15 },
      { "name": "Eldred Rock", "lat": 59.62, "lng": -135.37 },
      { "name": "Elfin Cove", "lat": 58.20, "lng": -136.35 },
      { "name": "Fairbanks", "lat": 64.82, "lng": -147.87 },
      { "name": "Farewell", "lat": 62.85, "lng": -154.47 },
      { "name": "Five Finger", "lat": 57.45, "lng": -134.03 },
      { "name": "Five Mile", "lat": 65.93, "lng": -149.83 },
      { "name": "Fort Yukon", "lat": 66.57, "lng": -145.27 },
      { "name": "Funter Bay", "lat": 58.25, "lng": -134.90 },
      { "name": "Galbraith Lk", "lat": 68.48, "lng": -149.48 },
      { "name": "Gambell", "lat": 63.77, "lng": -171.73 },
      { "name": "Gulkana Arpt", "lat": 62.15, "lng": -145.45 },
      { "name": "Gustavus", "lat": 58.42, "lng": -135.73 },
      { "name": "Haines Hrbor", "lat": 59.23, "lng": -135.43 },
      { "name": "Hayes River", "lat": 61.98, "lng": -152.08 },
      { "name": "Healy River", "lat": 63.83, "lng": -149.00 },
      { "name": "Homer", "lat": 59.63, "lng": -151.50 },
      { "name": "Hoonah Seapl", "lat": 58.10, "lng": -135.45 },
      { "name": "Iliamna Arpt", "lat": 59.75, "lng": -154.92 },
      { "name": "Juneau", "lat": 58.37, "lng": -134.58 },
      { "name": "Kake Seaplan", "lat": 56.97, "lng": -133.95 },
      { "name": "Kenai", "lat": 60.57, "lng": -151.25 },
      { "name": "Ketchikan", "lat": 55.35, "lng": -131.70 },
      { "name": "King Salmon", "lat": 58.68, "lng": -156.65 },
      { "name": "Kodiak", "lat": 57.75, "lng": -152.50 },
      { "name": "Kotzebue", "lat": 66.87, "lng": -162.63 },
      { "name": "Lazy Mtn", "lat": 61.63, "lng": -149.03 },
      { "name": "Lonely (dew)", "lat": 70.92, "lng": -153.23 },
      { "name": "Manley Ht Sp", "lat": 65.00, "lng": -150.65 },
      { "name": "Mccarthy", "lat": 61.43, "lng": -142.92 },
      { "name": "Mcgrath", "lat": 62.97, "lng": -155.62 },
      { "name": "Mekoryuk", "lat": 60.37, "lng": -166.27 },
      { "name": "Middleton Is", "lat": 59.43, "lng": -146.33 },
      { "name": "Minchumina", "lat": 63.90, "lng": -152.27 },
      { "name": "Nenana", "lat": 64.55, "lng": -149.08 },
      { "name": "New Andreafs", "lat": 62.07, "lng": -163.30 },
      { "name": "Nikolski", "lat": 53.58, "lng": -169.42 },
      { "name": "Nome", "lat": 64.50, "lng": -165.43 },
      { "name": "Northway", "lat": 62.97, "lng": -141.93 },
      { "name": "Oliktok Dew", "lat": 70.50, "lng": -149.88 },
      { "name": "Palmer", "lat": 61.60, "lng": -149.08 },
      { "name": "Paxson", "lat": 63.03, "lng": -145.50 },
      { "name": "Petersburg", "lat": 56.82, "lng": -132.97 },
      { "name": "Point Lay", "lat": 69.82, "lng": -162.92 },
      { "name": "Port Alexndr", "lat": 56.25, "lng": -134.65 },
      { "name": "Port Clarnce", "lat": 65.25, "lng": -166.87 },
      { "name": "Port Heiden", "lat": 56.95, "lng": -158.62 },
      { "name": "Prospect Crk", "lat": 66.82, "lng": -150.65 },
      { "name": "Prudhoe Bay", "lat": 70.25, "lng": -148.33 },
      { "name": "Pt Alsworth", "lat": 60.20, "lng": -154.30 },
      { "name": "Puntilla", "lat": 62.17, "lng": -153.25 },
      { "name": "Sand Point", "lat": 55.33, "lng": -160.50 },
      { "name": "Seward", "lat": 60.18, "lng": -149.75 },
      { "name": "Shemy", "lat": 53.20, "lng": -174.13 },
      { "name": "Shishmaref", "lat": 66.27, "lng": -166.03 },
      { "name": "Sitka", "lat": 57.07, "lng": -135.35 },
      { "name": "Sitkinak", "lat": 56.88, "lng": -154.20 },
      { "name": "Skagway", "lat": 59.75, "lng": -135.53 },
      { "name": "Skwentna", "lat": 61.97, "lng": -151.20 },
      { "name": "Snowshoe Lk", "lat": 62.03, "lng": -146.67 },
      { "name": "St Paul Is", "lat": 57.15, "lng": -170.22 },
      { "name": "Tahneta Pass", "lat": 61.90, "lng": -147.30 },
      { "name": "Talkeetna", "lat": 62.30, "lng": -150.10 },
      { "name": "Tanana", "lat": 65.17, "lng": -152.10 },
      { "name": "Umiat", "lat": 69.37, "lng": -152.13 },
      { "name": "Unalakleet", "lat": 63.88, "lng": -160.80 },
      { "name": "Valdez", "lat": 61.13, "lng": -146.35 },
      { "name": "Valdez 2", "lat": 61.13, "lng": -146.25 },
      { "name": "Wainwright", "lat": 70.62, "lng": -159.85 },
      { "name": "Wales", "lat": 66.03, "lng": -168.08 },
      { "name": "Whittier", "lat": 60.78, "lng": -148.72 },
      { "name": "Willow Arpt", "lat": 61.75, "lng": -150.05 },
      { "name": "Wrangell", "lat": 56.48, "lng": -132.37 },
      { "name": "Yakutat", "lat": 59.52, "lng": -139.67 }
    ]
  },
  "AZ": {
    "name": "Arizona", "lat": 33.7712, "lng": -111.3877, "show": true,
    "cities": [
      { "name": "Davis-M AFB", "lat": 32.17, "lng": -110.88 },
      { "name": "Deer Valley", "lat": 33.68, "lng": -112.08 },
      { "name": "Douglas", "lat": 31.45, "lng": -109.60 },
      { "name": "Falcon Fld", "lat": 33.47, "lng": -111.73 },
      { "name": "Flagstaff", "lat": 35.13, "lng": -111.67 },
      { "name": "Fort Huachuc", "lat": 31.60, "lng": -110.35 },
      { "name": "Gila Bend", "lat": 33.55, "lng": -113.17 },
      { "name": "Goodyear", "lat": 33.42, "lng": -112.38 },
      { "name": "Grand Canyon", "lat": 35.95, "lng": -112.15 },
      { "name": "Kingman", "lat": 35.27, "lng": -113.95 },
      { "name": "Luke", "lat": 33.53, "lng": -112.38 },
      { "name": "Page", "lat": 36.93, "lng": -111.45 },
      { "name": "Payson", "lat": 34.23, "lng": -111.33 },
      { "name": "Phoenix", "lat": 33.43, "lng": -112.02 },
      { "name": "Prescott", "lat": 34.65, "lng": -112.43 },
      { "name": "Safford Awrs", "lat": 32.82, "lng": -109.68 },
      { "name": "Scottsdale", "lat": 33.62, "lng": -111.92 },
      { "name": "Show Low", "lat": 34.27, "lng": -110.00 },
      { "name": "Show Low", "lat": 34.27, "lng": -110.00 },
      { "name": "Tucson", "lat": 32.12, "lng": -110.93 },
      { "name": "Williams AFB", "lat": 33.30, "lng": -111.67 },
      { "name": "Winslow", "lat": 35.02, "lng": -110.73 },
      { "name": "Yuma", "lat": 33.10, "lng": -115.00 },
      { "name": "Yuma Mcas", "lat": 32.65, "lng": -114.62 },
      { "name": "Yuma Prv Gd", "lat": 32.85, "lng": -114.40 }
    ]
  },
  "AR": {
    "name": "Arkansas", "lat": 34.9513, "lng": -92.3809, "show": true,
    "cities": [
      { "name": "Blytheville", "lat": 35.97 , "lng": -89.95 },
      { "name": "Camden", "lat": 33.52 , "lng": -92.40 },
      { "name": "El Dorado", "lat": 33.22 , "lng": -92.80 },
      { "name": "Fayetteville", "lat": 36.00 , "lng": -94.17 },
      { "name": "Ft Smith", "lat": 35.33 , "lng": -94.37 },
      { "name": "Harrison", "lat": 36.27 , "lng": -93.15 },
      { "name": "Hot Springs", "lat": 34.48 , "lng": -93.10 },
      { "name": "Jonesboro", "lat": 35.83 , "lng": -90.65 },
      { "name": "Little Rock", "lat": 35.22 , "lng": -92.38 },
      { "name": "Little Rock", "lat": 34.92 , "lng": -92.15 },
      { "name": "Little Rock", "lat": 34.83 , "lng": -92.25 },
      { "name": "Pine Bluff", "lat": 34.17 , "lng": -91.93 },
      { "name": "Springdale", "lat": 36.18 , "lng": -94.13 },
      { "name": "Texarkana", "lat": 33.45 , "lng": -94.00 },
      { "name": "Walnut Ridge", "lat": 36.13 , "lng": -90.93 }
    ]
  },
  "CA": {
    "name": "California", "lat": 36.1700, "lng": -119.7462, "show": true,
    "cities": [
      { "name": "Alameda NAS", "lat": 37.78, "lng": -122.32 },
      { "name": "Alturas", "lat": 41.48, "lng": -120.53 },
      { "name": "Arcata", "lat": 40.98, "lng": -124.10 },
      { "name": "Bakersfield", "lat": 35.43, "lng": -119.05 },
      { "name": "Beale AFB", "lat": 39.13, "lng": -121.45 },
      { "name": "Beaumont", "lat": 33.93, "lng": -116.95 },
      { "name": "Bicycle Lk", "lat": 35.28, "lng": -116.62 },
      { "name": "Big Bear Apt", "lat": 34.27, "lng": -116.68 },
      { "name": "Bishop", "lat": 37.60, "lng": -118.60 },
      { "name": "Blue Canyon", "lat": 39.28, "lng": -120.70 },
      { "name": "Blythe", "lat": 33.62, "lng": -114.72 },
      { "name": "Burbank", "lat": 34.20, "lng": -118.37 },
      { "name": "Camp Pendlet", "lat": 33.30, "lng": -117.35 },
      { "name": "Campo", "lat": 32.62, "lng": -116.47 },
      { "name": "Carlsbad", "lat": 33.13, "lng": -117.28 },
      { "name": "Castle AFB", "lat": 37.38, "lng": -120.57 },
      { "name": "Chico", "lat": 39.78, "lng": -121.85 },
      { "name": "China Lake", "lat": 35.68, "lng": -117.68 },
      { "name": "Chino", "lat": 33.97, "lng": -117.63 },
      { "name": "Concord", "lat": 37.98, "lng": -122.05 },
      { "name": "Crescent Cty", "lat": 41.78, "lng": -124.23 },
      { "name": "Daggett", "lat": 34.87, "lng": -116.78 },
      { "name": "Edwards AFB", "lat": 34.90, "lng": -117.88 },
      { "name": "El Centro", "lat": 32.82, "lng": -115.68 },
      { "name": "El Monte", "lat": 34.08, "lng": -118.03 },
      { "name": "El Toro", "lat": 33.67, "lng": -117.73 },
      { "name": "Eureka", "lat": 41.33, "lng": -124.28 },
      { "name": "Fort Hunter", "lat": 36.00, "lng": -121.32 },
      { "name": "Fort Ord", "lat": 36.68, "lng": -121.77 },
      { "name": "Fresno", "lat": 36.77, "lng": -119.72 },
      { "name": "Fullerton", "lat": 33.87, "lng": -117.97 },
      { "name": "George AFB", "lat": 34.58, "lng": -117.38 },
      { "name": "Hawthorne", "lat": 33.92, "lng": -118.33 },
      { "name": "Hayward", "lat": 37.65, "lng": -122.12 },
      { "name": "Imperial", "lat": 32.83, "lng": -115.57 },
      { "name": "Imperial Bch", "lat": 32.57, "lng": -117.12 },
      { "name": "La Verne", "lat": 34.10, "lng": -117.78 },
      { "name": "Lake Tahoe", "lat": 38.90, "lng": -120.00 },
      { "name": "Lancaster", "lat": 34.73, "lng": -118.22 },
      { "name": "Lemoore NAS", "lat": 36.33, "lng": -119.95 },
      { "name": "Livermore", "lat": 37.70, "lng": -121.82 },
      { "name": "Long Beach", "lat": 33.82, "lng": -118.15 },
      { "name": "Los Alamitos", "lat": 33.78, "lng": -118.05 },
      { "name": "Los Angeles", "lat": 33.93, "lng": -118.40 },
      { "name": "Mammoth Lks", "lat": 37.63, "lng": -118.92 },
      { "name": "March AFB", "lat": 33.88, "lng": -117.27 },
      { "name": "Marysville", "lat": 39.10, "lng": -121.57 },
      { "name": "Mather AFB", "lat": 38.57, "lng": -121.30 },
      { "name": "Mcclellan", "lat": 38.67, "lng": -121.40 },
      { "name": "Merced", "lat": 37.28, "lng": -120.52 },
      { "name": "Miramar NAS", "lat": 32.87, "lng": -117.15 },
      { "name": "Modesto", "lat": 37.63, "lng": -120.95 },
      { "name": "Moffet NAS", "lat": 37.42, "lng": -122.05 },
      { "name": "Mojave", "lat": 35.05, "lng": -118.15 },
      { "name": "Montague", "lat": 41.73, "lng": -122.53 },
      { "name": "Monterey", "lat": 36.58, "lng": -121.85 },
      { "name": "Mount Shasta", "lat": 41.32, "lng": -122.32 },
      { "name": "Mount Wilson", "lat": 34.23, "lng": -118.07 },
      { "name": "Napa", "lat": 38.22, "lng": -122.28 },
      { "name": "Needles", "lat": 34.77, "lng": -114.62 },
      { "name": "North Is", "lat": 32.70, "lng": -117.20 },
      { "name": "Norton AFB", "lat": 34.10, "lng": -117.23 },
      { "name": "Oakland", "lat": 37.73, "lng": -122.22 },
      { "name": "Ontario Intl", "lat": 34.05, "lng": -117.62 },
      { "name": "Oxnard", "lat": 34.20, "lng": -119.20 },
      { "name": "Palm Springs", "lat": 33.83, "lng": -116.50 },
      { "name": "Palmdale", "lat": 35.05, "lng": -118.13 },
      { "name": "Palo Alto", "lat": 37.47, "lng": -122.12 },
      { "name": "Paso Robles", "lat": 35.67, "lng": -120.63 },
      { "name": "Pillaro Pt", "lat": 37.83, "lng": -122.83 },
      { "name": "Point Mugu", "lat": 34.12, "lng": -119.12 },
      { "name": "Pt Arena", "lat": 39.58, "lng": -124.22 },
      { "name": "Pt Arguello", "lat": 34.95, "lng": -121.12 },
      { "name": "Pt Piedras", "lat": 35.67, "lng": -121.28 },
      { "name": "Pt Piedras", "lat": 36.12, "lng": -121.47 },
      { "name": "Red Bluff", "lat": 40.15, "lng": -122.25 },
      { "name": "Redding", "lat": 40.50, "lng": -122.30 },
      { "name": "Riverside", "lat": 33.95, "lng": -117.45 },
      { "name": "Sacramento", "lat": 38.52, "lng": -121.50 },
      { "name": "Sacramento", "lat": 38.70, "lng": -121.60 },
      { "name": "Salinas", "lat": 36.67, "lng": -121.60 },
      { "name": "San Carlos", "lat": 37.52, "lng": -122.25 },
      { "name": "San Clemente", "lat": 33.42, "lng": -117.62 },
      { "name": "San Clemente", "lat": 33.02, "lng": -118.58 },
      { "name": "San Diego", "lat": 32.82, "lng": -117.13 },
      { "name": "San Diego", "lat": 32.73, "lng": -117.17 },
      { "name": "San Diego", "lat": 32.57, "lng": -116.98 },
      { "name": "San Diego", "lat": 32.82, "lng": -116.97 },
      { "name": "San Francisco", "lat": 37.75, "lng": -122.68 },
      { "name": "San Francisco", "lat": 37.62, "lng": -122.38 },
      { "name": "San Jose", "lat": 37.37, "lng": -121.92 },
      { "name": "San Jose/Rei", "lat": 37.33, "lng": -121.82 },
      { "name": "San Luis Obi", "lat": 35.23, "lng": -120.65 },
      { "name": "San Mateo", "lat": 33.38, "lng": -117.58 },
      { "name": "San Miguel", "lat": 34.03, "lng": -120.40 },
      { "name": "San Nic Isl", "lat": 33.25, "lng": -119.45 },
      { "name": "Sandburg", "lat": 34.75, "lng": -118.73 },
      { "name": "Santa Ana", "lat": 33.67, "lng": -117.88 },
      { "name": "Santa Barb", "lat": 34.43, "lng": -119.83 },
      { "name": "Santa Maria", "lat": 34.90, "lng": -120.45 },
      { "name": "Santa Monica", "lat": 34.02, "lng": -118.45 },
      { "name": "Santa Rosa", "lat": 38.52, "lng": -122.82 },
      { "name": "Shelter Cove", "lat": 40.03, "lng": -124.07 },
      { "name": "Siskiyou", "lat": 41.78, "lng": -122.47 },
      { "name": "Stockton", "lat": 37.90, "lng": -121.25 },
      { "name": "Superior Val", "lat": 35.33, "lng": -117.10 },
      { "name": "Susanville", "lat": 40.63, "lng": -120.95 },
      { "name": "Thermal", "lat": 33.63, "lng": -116.17 },
      { "name": "Torrance", "lat": 33.80, "lng": -118.33 },
      { "name": "Travis AFB", "lat": 38.27, "lng": -121.93 },
      { "name": "Truckee-Tahoe", "lat": 39.32, "lng": -120.13 },
      { "name": "Tustin Mcas", "lat": 33.70, "lng": -117.83 },
      { "name": "Twenty9 Palm", "lat": 34.28, "lng": -116.15 },
      { "name": "Ukiah", "lat": 39.13, "lng": -123.20 },
      { "name": "Van Nuys", "lat": 34.22, "lng": -118.48 },
      { "name": "Vandenberg", "lat": 35.20, "lng": -120.95 },
      { "name": "Visalia", "lat": 36.32, "lng": -119.40 }
    ]
  },
  "CO": {
    "name": "Colorado", "lat": 39.0646, "lng": -105.3272, "show": true,
    "cities": [
      { "name": "Air Force A", "lat": 39.52, "lng": -105.35 },
      { "name": "Akron", "lat": 40.17, "lng": -103.22 },
      { "name": "Alamosa", "lat": 37.45, "lng": -105.87 },
      { "name": "Aspen", "lat": 39.22, "lng": -106.87 },
      { "name": "Brmfield/Jef", "lat": 39.90, "lng": -105.12 },
      { "name": "Buckley", "lat": 39.72, "lng": -104.75 },
      { "name": "Colo Sprgs", "lat": 38.82, "lng": -104.72 },
      { "name": "Cortez", "lat": 37.30, "lng": -108.63 },
      { "name": "Craig-Moffat", "lat": 40.50, "lng": -107.53 },
      { "name": "Denver", "lat": 39.75, "lng": -104.87 },
      { "name": "Durango", "lat": 37.15, "lng": -107.75 },
      { "name": "Eagle", "lat": 39.65, "lng": -106.92 },
      { "name": "Englewood", "lat": 39.57, "lng": -104.83 },
      { "name": "Fort Carson", "lat": 38.68, "lng": -104.77 },
      { "name": "Fraser", "lat": 39.57, "lng": -105.50 },
      { "name": "Ft Col/Lovel", "lat": 40.45, "lng": -105.02 },
      { "name": "Ft Collins", "lat": 40.58, "lng": -105.08 },
      { "name": "Grand Jct", "lat": 39.12, "lng": -108.53 },
      { "name": "Greeley-Wld", "lat": 40.43, "lng": -104.63 },
      { "name": "Gunnison", "lat": 38.55, "lng": -106.93 },
      { "name": "Gunnison", "lat": 38.55, "lng": -106.92 },
      { "name": "La Junta", "lat": 38.05, "lng": -103.52 },
      { "name": "Lamar", "lat": 38.12, "lng": -102.60 },
      { "name": "Leadville", "lat": 39.25, "lng": -106.30 },
      { "name": "Limon", "lat": 39.18, "lng": -103.70 },
      { "name": "Montrose", "lat": 38.50, "lng": -107.88 },
      { "name": "Montrose", "lat": 38.50, "lng": -107.88 },
      { "name": "Pueblo", "lat": 38.28, "lng": -104.52 },
      { "name": "Rifle", "lat": 39.53, "lng": -107.80 },
      { "name": "Salida", "lat": 38.53, "lng": -106.05 },
      { "name": "Steamboat Sp", "lat": 40.48, "lng": -106.82 },
      { "name": "Trinidad", "lat": 37.25, "lng": -104.33 },
      { "name": "Winter Park", "lat": 40.00, "lng": -105.87 }
    ]
  },
  "CT": {
    "name": "Connecticut", "lat": 41.5834, "lng": -72.7622, "show": true,
    "cities": [
      { "name": "Bridgeport", "lat": 41.17 , "lng": -73.13 },
      { "name": "Danbury", "lat": 41.37 , "lng": -73.48 },
      { "name": "Groton", "lat": 41.33 , "lng": -72.05 },
      { "name": "Hartford", "lat": 41.73 , "lng": -72.65 },
      { "name": "New Haven", "lat": 41.22 , "lng": -72.67 },
      { "name": "New Haven", "lat": 41.27 , "lng": -72.88 },
      { "name": "New Haven", "lat": 41.27 , "lng": -72.90 },
      { "name": "New London", "lat": 41.30 , "lng": -72.08 },
      { "name": "Windsor Loc", "lat": 41.93 , "lng": -72.68 }
    ]
  },
  "DE": {
    "name": "Delaware", "lat": 39.3498, "lng": -75.5148,
    "cities": [
      { "name": "Dover", "lat": 39.13 , "lng": -75.47 },
      { "name": "Wilmington", "lat": 39.67 , "lng": -75.60 }
    ]
  },
  "DC": {
    "name": "District of Columbia", "lat": 38.8964, "lng": -77.0262, "show": true,
    "cities": [
      { "name": "Washington/Dulles", "lat": 38.95 , "lng": -77.46 },
      { "name": "Washington/Natl", "lat": 38.85 , "lng": -77.04 }
    ]
  },
  "FL": {
    "name": "Florida", "lat": 27.8333, "lng": -81.7170, "show": true,
    "cities": [
      { "name": "Apalachicola", "lat": 29.73 , "lng": -85.03 },
      { "name": "Astor NAS", "lat": 29.12 , "lng": -81.57 },
      { "name": "Avon Park G", "lat": 28.08 , "lng": -81.55 },
      { "name": "Cape Canaveral", "lat": 28.47 , "lng": -80.55 },
      { "name": "Cecil", "lat": 30.22 , "lng": -81.88 },
      { "name": "Crestview", "lat": 30.78 , "lng": -86.52 },
      { "name": "Cross City", "lat": 29.62 , "lng": -83.10 },
      { "name": "Daytona Bch", "lat": 29.18 , "lng": -81.05 },
      { "name": "Duke Fld", "lat": 30.65 , "lng": -86.52 },
      { "name": "Eglin AFB", "lat": 30.48 , "lng": -86.53 },
      { "name": "Egmont Key", "lat": 27.60 , "lng": -82.77 },
      { "name": "Fort Lauderd", "lat": 26.13 , "lng": -80.13 },
      { "name": "Fort Myers", "lat": 26.58 , "lng": -81.87 },
      { "name": "Ft Lauderdale", "lat": 26.07 , "lng": -80.15 },
      { "name": "Ft Myers", "lat": 26.65 , "lng": -81.87 },
      { "name": "Gainesville", "lat": 29.68 , "lng": -82.27 },
      { "name": "Homestead", "lat": 25.48 , "lng": -80.38 },
      { "name": "Hurlburt Fld", "lat": 30.43 , "lng": -86.68 },
      { "name": "Jacksonville", "lat": 30.33 , "lng": -81.52 },
      { "name": "Jacksonville", "lat": 30.50 , "lng": -81.70 },
      { "name": "Jacksonville", "lat": 30.23 , "lng": -81.68 },
      { "name": "Key West", "lat": 24.55 , "lng": -81.75 },
      { "name": "Key West NAS", "lat": 24.57 , "lng": -81.68 },
      { "name": "Lakeland", "lat": 28.03 , "lng": -81.95 },
      { "name": "Macdill AFB", "lat": 27.85 , "lng": -82.52 },
      { "name": "Marianna", "lat": 30.84 , "lng": -85.18 },
      { "name": "Mayport NAS", "lat": 30.40 , "lng": -81.42 },
      { "name": "Melbourne", "lat": 28.10 , "lng": -80.63 },
      { "name": "Miami Intl", "lat": 25.82 , "lng": -80.28 },
      { "name": "Miami/Opa", "lat": 25.92 , "lng": -80.28 },
      { "name": "Miami/Tamiami", "lat": 25.65 , "lng": -80.43 },
      { "name": "Naples", "lat": 26.13 , "lng": -81.80 },
      { "name": "Nasa Shuttle", "lat": 28.62 , "lng": -80.68 },
      { "name": "Orlando", "lat": 28.43 , "lng": -81.32 },
      { "name": "Orlando", "lat": 28.55 , "lng": -81.33 },
      { "name": "Panama City", "lat": 30.20 , "lng": -85.68 },
      { "name": "Patrick AFB", "lat": 28.23 , "lng": -80.60 },
      { "name": "Pensacola", "lat": 30.35 , "lng": -87.32 },
      { "name": "Pensacola", "lat": 30.47 , "lng": -87.20 },
      { "name": "Ruskin", "lat": 27.97 , "lng": -82.60 },
      { "name": "Saint Peters", "lat": 27.92 , "lng": -82.68 },
      { "name": "Sanford", "lat": 28.78 , "lng": -81.25 },
      { "name": "Sarasota", "lat": 27.40 , "lng": -82.55 },
      { "name": "Tallahassee", "lat": 30.38 , "lng": -84.37 },
      { "name": "Tampa Intl", "lat": 27.97 , "lng": -82.53 },
      { "name": "Titusville", "lat": 28.52 , "lng": -80.80 },
      { "name": "Tyndall AFB", "lat": 30.07 , "lng": -85.58 },
      { "name": "Vero Beach", "lat": 27.65 , "lng": -80.42 },
      { "name": "W Palm Beach", "lat": 26.68 , "lng": -80.12 },
      { "name": "Whiting Fld", "lat": 30.72 , "lng": -87.02 }
    ]
  },
  "GA": {
    "name": "Georgia", "lat": 32.9866, "lng": -83.6487,
    "cities": [
      { "name": "Albany", "lat": 31.53 , "lng": -84.18 },
      { "name": "Alma", "lat": 31.53 , "lng": -82.52 },
      { "name": "Athens", "lat": 33.95 , "lng": -83.32 },
      { "name": "Atlanta", "lat": 33.65 , "lng": -84.42 },
      { "name": "Atlanta/Dklb", "lat": 33.88 , "lng": -84.30 },
      { "name": "Atlanta/Fltn", "lat": 33.78 , "lng": -84.52 },
      { "name": "Augusta/Bush", "lat": 33.37 , "lng": -81.97 },
      { "name": "Brunswick", "lat": 31.15 , "lng": -81.38 },
      { "name": "Columbus", "lat": 32.52 , "lng": -84.93 },
      { "name": "Dobbins AFB", "lat": 33.92 , "lng": -84.52 },
      { "name": "Dobbins AFB", "lat": 34.53 , "lng": -84.87 },
      { "name": "Fort Benning", "lat": 32.33 , "lng": -85.00 },
      { "name": "Ft Stewart", "lat": 31.88 , "lng": -81.57 },
      { "name": "Hunter Aaf", "lat": 32.02 , "lng": -81.15 },
      { "name": "La Grange", "lat": 33.01 , "lng": -85.07 },
      { "name": "Macon/Lewis", "lat": 32.70 , "lng": -83.65 },
      { "name": "Moody AFB", "lat": 30.97 , "lng": -83.20 },
      { "name": "Robins AFB", "lat": 32.63 , "lng": -83.60 },
      { "name": "Rome/Russell", "lat": 34.35 , "lng": -85.17 },
      { "name": "Savannah Mun", "lat": 32.13 , "lng": -81.20 },
      { "name": "Valdosta", "lat": 30.78 , "lng": -83.28 },
      { "name": "Waycross", "lat": 31.25 , "lng": -82.40 }
    ]
  },
  "HI": {
    "name": "Hawaii", "lat": 21.1098, "lng": -157.5311,
    "cities": [
      { "name": "Barbers Pt", "lat": 21.53, "lng": -158.12 },
      { "name": "Barking San", "lat": 22.05, "lng": -160.30 },
      { "name": "Fr Frigate", "lat": 24.45, "lng": -166.47 },
      { "name": "Hilo", "lat": 19.72, "lng": -155.07 },
      { "name": "Honolulu Int", "lat": 21.35, "lng": -157.93 },
      { "name": "Kahului Maui", "lat": 20.90, "lng": -156.43 },
      { "name": "Kaneohe Mca", "lat": 21.75, "lng": -158.28 },
      { "name": "Ke-Ahole/Kon", "lat": 19.75, "lng": -156.05 },
      { "name": "Kilauea Pt", "lat": 22.38, "lng": -159.67 },
      { "name": "Lanai-Lanai", "lat": 20.80, "lng": -156.95 },
      { "name": "Lihue-Kauai", "lat": 21.98, "lng": -159.35 },
      { "name": "Maui", "lat": 20.97, "lng": -156.83 },
      { "name": "Molokai", "lat": 21.15, "lng": -157.10 },
      { "name": "Upolo Pt Ln", "lat": 20.42, "lng": -156.47 },
      { "name": "Waimea-Koha", "lat": 20.00, "lng": -156.12 }
    ]
  },
  "ID": {
    "name": "Idaho", "lat": 44.2394, "lng": -114.5103,
    "cities": [
      { "name": "Boise", "lat": 43.57, "lng": -116.22 },
      { "name": "Burley", "lat": 42.53, "lng": -113.77 },
      { "name": "Challis", "lat": 44.52, "lng": -114.22 },
      { "name": "Coeur d'Alene", "lat": 47.77, "lng": -116.82 },
      { "name": "Elk City", "lat": 45.82, "lng": -115.43 },
      { "name": "Gooding", "lat": 43.00, "lng": -115.17 },
      { "name": "Grangeville", "lat": 45.92, "lng": -116.13 },
      { "name": "Idaho Falls", "lat": 43.52, "lng": -112.07 },
      { "name": "Lewiston", "lat": 46.38, "lng": -117.02 },
      { "name": "Malad City", "lat": 42.17, "lng": -112.32 },
      { "name": "Malta", "lat": 42.30, "lng": -113.37 },
      { "name": "Mccall", "lat": 44.88, "lng": -116.10 },
      { "name": "Mountn Home", "lat": 43.05, "lng": -115.87 },
      { "name": "Mullan", "lat": 47.47, "lng": -115.80 },
      { "name": "Pocatello", "lat": 42.92, "lng": -112.60 },
      { "name": "Salmon", "lat": 45.18, "lng": -113.90 },
      { "name": "Salmon", "lat": 45.17, "lng": -114.47 },
      { "name": "Soda Springs", "lat": 42.65, "lng": -111.58 },
      { "name": "Sun Valley", "lat": 43.50, "lng": -114.30 },
      { "name": "Twin Falls", "lat": 42.48, "lng": -114.48 }
    ]
  },
  "IL": {
    "name": "Illinois", "lat": 40.3363, "lng": -89.0022, "show": true,
    "cities": [
      { "name": "Alton", "lat": 38.88 , "lng": -90.05 },
      { "name": "Aurora", "lat": 41.77 , "lng": -88.32 },
      { "name": "Bistate Park", "lat": 38.57 , "lng": -90.15 },
      { "name": "Bloomington", "lat": 40.48 , "lng": -88.93 },
      { "name": "Bradford", "lat": 41.16 , "lng": -89.60 },
      { "name": "Cairo", "lat": 37.07 , "lng": -89.22 },
      { "name": "Carbondale", "lat": 37.78 , "lng": -89.25 },
      { "name": "Centralia", "lat": 38.51 , "lng": -89.09 },
      { "name": "Champaign/Urbana", "lat": 40.03 , "lng": -88.28 },
      { "name": "Chicago", "lat": 41.90 , "lng": -87.65 },
      { "name": "Chicago/Meigs", "lat": 41.87 , "lng": -87.62 },
      { "name": "Chicago/Midway", "lat": 41.78 , "lng": -87.75 },
      { "name": "Chicago/O'hare", "lat": 41.98 , "lng": -87.90 },
      { "name": "Danville", "lat": 40.20 , "lng": -87.60 },
      { "name": "DeKalb", "lat": 41.93 , "lng": -88.72 },
      { "name": "Decatur", "lat": 39.83 , "lng": -88.87 },
      { "name": "Du Page", "lat": 41.92 , "lng": -88.25 },
      { "name": "Galesburg", "lat": 40.93 , "lng": -90.43 },
      { "name": "Glenview NAS", "lat": 42.08 , "lng": -87.82 },
      { "name": "Kankakee", "lat": 41.07 , "lng": -87.85 },
      { "name": "Macomb", "lat": 40.52 , "lng": -90.66 },
      { "name": "Marion", "lat": 37.75 , "lng": -89.00 },
      { "name": "Marseilles", "lat": 41.37 , "lng": -88.68 },
      { "name": "Mattoon", "lat": 39.48 , "lng": -88.28 },
      { "name": "Moline/Quad", "lat": 41.45 , "lng": -90.52 },
      { "name": "Mount Vernon", "lat": 38.32 , "lng": -88.86 },
      { "name": "Peoria", "lat": 40.67 , "lng": -89.68 },
      { "name": "Quincy", "lat": 39.93 , "lng": -91.20 },
      { "name": "Rockford", "lat": 42.20 , "lng": -89.10 },
      { "name": "Salem", "lat": 38.63 , "lng": -88.96 },
      { "name": "Scott AFB", "lat": 38.55 , "lng": -89.85 },
      { "name": "Springfield", "lat": 39.85 , "lng": -89.67 },
      { "name": "Sterling", "lat": 41.74 , "lng": -89.67 },
      { "name": "Taylorville", "lat": 39.53 , "lng": -89.33 },
      { "name": "Vandalia", "lat": 38.99 , "lng": -89.17 }
    ]
  },
  "IN": {
    "name": "Indiana", "lat": 39.8647, "lng": -86.2604,
    "cities": [
      { "name": "Bakalar Af", "lat": 39.38 , "lng": -86.50 },
      { "name": "Bloomington", "lat": 39.13 , "lng": -86.62 },
      { "name": "Elkhart", "lat": 41.72 , "lng": -86.00 },
      { "name": "Evansville", "lat": 38.05 , "lng": -87.53 },
      { "name": "Fort Wayne", "lat": 41.00 , "lng": -85.20 },
      { "name": "Gary", "lat": 41.62 , "lng": -87.42 },
      { "name": "Grissom AFB", "lat": 40.65 , "lng": -86.15 },
      { "name": "Indianapolis", "lat": 39.73 , "lng": -86.27 },
      { "name": "Muncie", "lat": 40.23 , "lng": -85.38 },
      { "name": "South Bend", "lat": 41.70 , "lng": -86.32 },
      { "name": "Terre Haute", "lat": 39.45 , "lng": -87.30 },
      { "name": "W Lafayette", "lat": 40.42 , "lng": -86.93 }
    ]
  },
  "IA": {
    "name": "Iowa", "lat": 42.0046, "lng": -93.2140, "show": true,
    "cities": [
      { "name": "Burlington", "lat": 40.78 , "lng": -91.12 },
      { "name": "Cedar Rapids", "lat": 41.88 , "lng": -91.70 },
      { "name": "Des Moines", "lat": 41.53 , "lng": -93.65 },
      { "name": "Dubuque", "lat": 42.40 , "lng": -90.70 },
      { "name": "Estherville", "lat": 43.40 , "lng": -94.75 },
      { "name": "Fort Dodge", "lat": 42.55 , "lng": -94.18 },
      { "name": "Lamoni", "lat": 40.62 , "lng": -93.93 },
      { "name": "Mason City", "lat": 43.15 , "lng": -93.33 },
      { "name": "Ottumwa", "lat": 41.10 , "lng": -92.45 },
      { "name": "Sioux City", "lat": 42.40 , "lng": -96.38 },
      { "name": "Spencer", "lat": 43.17 , "lng": -95.15 },
      { "name": "Waterloo Mun", "lat": 42.55 , "lng": -92.40 }
    ]
  },
  "KS": {
    "name": "Kansas", "lat": 38.5111, "lng": -96.8005, "show": true,
    "cities": [
      { "name": "Chanute", "lat": 37.67 , "lng": -95.48 },
      { "name": "Col. J Jabar", "lat": 37.75 , "lng": -97.22 },
      { "name": "Concordia", "lat": 39.55 , "lng": -97.65 },
      { "name": "Dodge City", "lat": 37.77 , "lng": -99.97 },
      { "name": "Elkhart", "lat": 37.00, "lng": -101.88 },
      { "name": "Emporia", "lat": 38.33 , "lng": -96.20 },
      { "name": "Ft Leavnwrth", "lat": 39.37 , "lng": -94.92 },
      { "name": "Ft Riley", "lat": 39.05 , "lng": -96.77 },
      { "name": "Garden City", "lat": 37.93, "lng": -100.72 },
      { "name": "Goodland", "lat": 39.37, "lng": -101.70 },
      { "name": "Hays", "lat": 38.85 , "lng": -99.27 },
      { "name": "Hill City", "lat": 39.38 , "lng": -99.83 },
      { "name": "Hutchinson", "lat": 38.07 , "lng": -97.87 },
      { "name": "Johnson Cnty", "lat": 38.82 , "lng": -94.88 },
      { "name": "Liberal", "lat": 37.05, "lng": -100.97 },
      { "name": "Manhatten", "lat": 39.15 , "lng": -96.67 },
      { "name": "Mcconnell Af", "lat": 37.62 , "lng": -97.27 },
      { "name": "Medicine Ldg", "lat": 37.30 , "lng": -98.58 },
      { "name": "Olathe", "lat": 38.85 , "lng": -94.90 },
      { "name": "Russell", "lat": 38.87 , "lng": -98.82 },
      { "name": "Salina", "lat": 38.80 , "lng": -97.65 },
      { "name": "Topeka", "lat": 39.07 , "lng": -95.62 },
      { "name": "Topeka/Forbe", "lat": 38.95 , "lng": -95.67 },
      { "name": "Wichita", "lat": 37.65 , "lng": -97.43 }
    ]
  },
  "KY": {
    "name": "Kentucky", "lat": 37.6690, "lng": -84.6514,
    "cities": [
      { "name": "Bowling Gren", "lat": 36.97 , "lng": -86.43 },
      { "name": "Ft Campbell", "lat": 36.67 , "lng": -87.50 },
      { "name": "Ft Knox", "lat": 37.90 , "lng": -85.97 },
      { "name": "Jackson", "lat": 37.60 , "lng": -83.32 },
      { "name": "Lexington", "lat": 38.05 , "lng": -85.00 },
      { "name": "London", "lat": 37.08 , "lng": -84.07 },
      { "name": "Louisville", "lat": 38.23 , "lng": -85.67 },
      { "name": "Louisville", "lat": 38.18 , "lng": -85.73 },
      { "name": "Owensboro", "lat": 37.75 , "lng": -87.17 },
      { "name": "Paducah", "lat": 37.07 , "lng": -88.77 },
      { "name": "Pikeville", "lat": 37.48 , "lng": -82.52 }
    ]
  },
  "LA": {
    "name": "Louisiana", "lat": 31.1801, "lng": -91.8749, "show": true,
    "cities": [
      { "name": "Alexandria", "lat": 31.38 , "lng": -92.30 },
      { "name": "Barksdale", "lat": 32.50 , "lng": -93.67 },
      { "name": "Baton Rouge", "lat": 30.53 , "lng": -91.15 },
      { "name": "Boothville", "lat": 29.55 , "lng": -89.67 },
      { "name": "Cameron Heli", "lat": 29.78 , "lng": -93.30 },
      { "name": "Claiborne R", "lat": 31.22 , "lng": -92.95 },
      { "name": "England AFB", "lat": 31.33 , "lng": -92.55 },
      { "name": "Eugene Is.", "lat": 28.47 , "lng": -91.78 },
      { "name": "Fort Polk", "lat": 31.05 , "lng": -93.20 },
      { "name": "Grand Isle", "lat": 29.18 , "lng": -90.07 },
      { "name": "High Is", "lat": 28.22 , "lng": -93.75 },
      { "name": "High Island", "lat": 28.13 , "lng": -94.40 },
      { "name": "Houma", "lat": 29.57 , "lng": -90.65 },
      { "name": "Intercoastal", "lat": 29.73 , "lng": -92.12 },
      { "name": "Lafayette", "lat": 30.20 , "lng": -92.00 },
      { "name": "Lake Charles", "lat": 30.12 , "lng": -93.22 },
      { "name": "Lk Palourde", "lat": 29.70 , "lng": -91.10 },
      { "name": "Missippi Can", "lat": 28.78 , "lng": -89.05 },
      { "name": "Monroe", "lat": 32.52 , "lng": -92.05 },
      { "name": "Morgan City", "lat": 29.70 , "lng": -91.20 },
      { "name": "New Iberia", "lat": 30.03 , "lng": -91.88 },
      { "name": "New Orleans", "lat": 29.98 , "lng": -90.25 },
      { "name": "New Orleans", "lat": 29.83 , "lng": -90.02 },
      { "name": "New Orleans", "lat": 30.03 , "lng": -90.03 },
      { "name": "S Marsh Isl", "lat": 28.30 , "lng": -91.98 },
      { "name": "Shreveport", "lat": 32.52 , "lng": -93.75 },
      { "name": "Shreveport", "lat": 32.47 , "lng": -93.82 },
      { "name": "Slidel", "lat": 30.35 , "lng": -89.82 }
    ]
  },
  "ME": {
    "name": "Maine", "lat": 44.6074, "lng": -69.3977, "show": true,
    "cities": [
      { "name": "Augusta", "lat": 44.32 , "lng": -69.80 },
      { "name": "Bangor", "lat": 44.80 , "lng": -68.82 },
      { "name": "Bar Harbor", "lat": 44.45 , "lng": -68.37 },
      { "name": "Brunswick", "lat": 43.88 , "lng": -69.93 },
      { "name": "Caribou Mun", "lat": 46.87 , "lng": -68.02 },
      { "name": "Greenville", "lat": 45.45 , "lng": -69.55 },
      { "name": "Greenville", "lat": 45.78 , "lng": -69.63 },
      { "name": "Houlton", "lat": 46.13 , "lng": -67.78 },
      { "name": "Loring AFB", "lat": 46.95 , "lng": -67.88 },
      { "name": "Portland", "lat": 43.65 , "lng": -70.32 },
      { "name": "Presque Isle", "lat": 46.68 , "lng": -68.05 },
      { "name": "Rockland", "lat": 44.07 , "lng": -69.12 },
      { "name": "Rumford", "lat": 44.88 , "lng": -70.88 }
    ]
  },
  "MD": {
    "name": "Maryland", "lat": 39.0724, "lng": -76.7902,
    "cities": [
      { "name": "Andrews AFB", "lat": 38.82 , "lng": -76.87 },
      { "name": "Baltimore", "lat": 39.18 , "lng": -76.67 },
      { "name": "Baltimore", "lat": 39.18 , "lng": -76.67 },
      { "name": "Baltimore", "lat": 39.33 , "lng": -76.42 },
      { "name": "Fort Meade", "lat": 39.08 , "lng": -76.77 },
      { "name": "Hagerstown", "lat": 39.70 , "lng": -77.72 },
      { "name": "Ocean City", "lat": 38.55 , "lng": -75.13 },
      { "name": "Patuxent", "lat": 38.28 , "lng": -76.40 },
      { "name": "Phillips", "lat": 39.47 , "lng": -76.17 },
      { "name": "Salisbury", "lat": 38.33 , "lng": -75.50 }
    ]
  },
  "MA": {
    "name": "Massachusetts", "lat": 42.2373, "lng": -71.5314,
    "cities": [
      { "name": "Bedford", "lat": 42.47 , "lng": -71.28 },
      { "name": "Beverly", "lat": 42.58 , "lng": -70.92 },
      { "name": "Boston", "lat": 42.37 , "lng": -71.03 },
      { "name": "Cape Cod Can", "lat": 41.78 , "lng": -70.50 },
      { "name": "Chatham", "lat": 41.67 , "lng": -69.97 },
      { "name": "Fort Devens", "lat": 42.57 , "lng": -71.60 },
      { "name": "Hyannis", "lat": 41.67 , "lng": -70.28 },
      { "name": "Lawrence", "lat": 42.72 , "lng": -71.12 },
      { "name": "Marthas Vine", "lat": 41.40 , "lng": -70.62 },
      { "name": "Nantucket", "lat": 41.25 , "lng": -70.07 },
      { "name": "New Bedford", "lat": 41.68 , "lng": -70.97 },
      { "name": "Norwood", "lat": 42.18 , "lng": -71.18 },
      { "name": "Otis ANGB", "lat": 41.65 , "lng": -70.52 },
      { "name": "Pittsfield", "lat": 42.26 , "lng": -73.18 },
      { "name": "S Weymouth", "lat": 42.15 , "lng": -70.93 },
      { "name": "Westfield", "lat": 42.17 , "lng": -72.72 },
      { "name": "Westover", "lat": 42.20 , "lng": -72.53 },
      { "name": "Worcester", "lat": 42.27 , "lng": -71.87 }
    ]
  },
  "MI": {
    "name": "Michigan", "lat": 43.3504, "lng": -84.5603,
    "cities": [
      { "name": "Alpena", "lat": 45.07 , "lng": -83.57 },
      { "name": "Ann Arbor", "lat": 42.22 , "lng": -83.75 },
      { "name": "Battle Creek", "lat": 42.30 , "lng": -85.23 },
      { "name": "Benton Harbor", "lat": 42.13 , "lng": -86.43 },
      { "name": "Chippewa", "lat": 46.25 , "lng": -84.47 },
      { "name": "Coopersville", "lat": 43.07 , "lng": -85.95 },
      { "name": "Copper Harb", "lat": 47.47 , "lng": -87.85 },
      { "name": "Detroit", "lat": 42.42 , "lng": -83.02 },
      { "name": "Detroit", "lat": 42.23 , "lng": -83.33 },
      { "name": "Escanaba", "lat": 45.73 , "lng": -87.08 },
      { "name": "Flint/Bishop", "lat": 42.97 , "lng": -83.75 },
      { "name": "Grand Rapids", "lat": 42.88 , "lng": -85.52 },
      { "name": "Hancock", "lat": 47.17 , "lng": -88.50 },
      { "name": "Harbor Beach", "lat": 43.83 , "lng": -82.53 },
      { "name": "Houghton Lake", "lat": 44.37 , "lng": -84.68 },
      { "name": "Iron Mtn", "lat": 45.82 , "lng": -88.12 },
      { "name": "Ironwood", "lat": 46.53 , "lng": -90.13 },
      { "name": "Jackson", "lat": 42.27 , "lng": -84.47 },
      { "name": "Kalamazoo", "lat": 42.23 , "lng": -85.55 },
      { "name": "Lansing", "lat": 42.77 , "lng": -84.60 },
      { "name": "Manistee", "lat": 44.27 , "lng": -86.25 },
      { "name": "Marquette", "lat": 46.88 , "lng": -87.95 },
      { "name": "Menominee", "lat": 45.12 , "lng": -87.63 },
      { "name": "Muskegon", "lat": 43.17 , "lng": -86.25 },
      { "name": "Pellston", "lat": 45.57 , "lng": -84.80 },
      { "name": "Pontiac", "lat": 42.67 , "lng": -83.42 },
      { "name": "Saginaw", "lat": 43.53 , "lng": -84.08 },
      { "name": "Sault Ste M", "lat": 46.47 , "lng": -84.37 },
      { "name": "Sawyer AFB", "lat": 46.35 , "lng": -87.40 },
      { "name": "Selfridge", "lat": 42.62 , "lng": -82.83 },
      { "name": "Seul Choix", "lat": 45.92 , "lng": -85.92 },
      { "name": "Traverse Cty", "lat": 44.73 , "lng": -85.58 },
      { "name": "Wurtsmith", "lat": 44.45 , "lng": -83.40 },
      { "name": "Ypsilanti", "lat": 42.23 , "lng": -83.53 }
    ]
  },
  "MN": {
    "name": "Minnesota", "lat": 45.7326, "lng": -93.9196, "show": true,
    "cities": [
      { "name": "Albert Lea", "lat": 43.68 , "lng": -93.37 },
      { "name": "Alexandria", "lat": 45.87 , "lng": -95.38 },
      { "name": "Bemidji Muni", "lat": 47.50 , "lng": -94.93 },
      { "name": "Brainerd-Crw", "lat": 46.40 , "lng": -94.13 },
      { "name": "Detroit Laks", "lat": 46.82 , "lng": -95.88 },
      { "name": "Duluth", "lat": 46.83 , "lng": -92.18 },
      { "name": "Ely", "lat": 47.90 , "lng": -91.82 },
      { "name": "Fairmont", "lat": 43.65 , "lng": -94.42 },
      { "name": "Fergus Falls", "lat": 46.30 , "lng": -96.07 },
      { "name": "Grand Rapids", "lat": 47.22 , "lng": -93.52 },
      { "name": "Hibbing", "lat": 47.38 , "lng": -92.85 },
      { "name": "Intl Falls", "lat": 48.57 , "lng": -93.38 },
      { "name": "Litchfield", "lat": 45.13 , "lng": -94.52 },
      { "name": "Mankato", "lat": 44.22 , "lng": -93.92 },
      { "name": "Marshall Arpt", "lat": 44.45 , "lng": -95.82 },
      { "name": "Minneapolis", "lat": 44.83 , "lng": -93.47 },
      { "name": "Minneapolis", "lat": 45.07 , "lng": -93.38 },
      { "name": "Minneapolis", "lat": 44.88 , "lng": -93.22 },
      { "name": "Park Rapids", "lat": 46.90 , "lng": -95.07 },
      { "name": "Pequot Lake", "lat": 46.60 , "lng": -94.32 },
      { "name": "Redwood Falls", "lat": 44.55 , "lng": -95.08 },
      { "name": "Rochester", "lat": 43.92 , "lng": -92.50 },
      { "name": "Saint Paul", "lat": 44.93 , "lng": -93.05 },
      { "name": "St Cloud", "lat": 45.55 , "lng": -94.07 },
      { "name": "Thief River", "lat": 48.07 , "lng": -96.18 },
      { "name": "Tofte", "lat": 47.58 , "lng": -90.83 },
      { "name": "Warroad", "lat": 48.93 , "lng": -95.35 },
      { "name": "Worthington", "lat": 43.65 , "lng": -95.58 }
    ]
  },
  "MS": {
    "name": "Mississippi", "lat": 32.7673, "lng": -89.6812,
    "cities": [
      { "name": "Columbus AFB", "lat": 33.65 , "lng": -88.45 },
      { "name": "Golden Trian", "lat": 33.45 , "lng": -88.58 },
      { "name": "Greenville", "lat": 33.48 , "lng": -90.98 },
      { "name": "Greenwood", "lat": 33.50 , "lng": -90.08 },
      { "name": "Gulfport", "lat": 30.40 , "lng": -89.07 },
      { "name": "Hattiesburg", "lat": 31.47 , "lng": -89.33 },
      { "name": "Jackson", "lat": 32.32 , "lng": -90.08 },
      { "name": "Keesler AFB", "lat": 30.42 , "lng": -88.92 },
      { "name": "Laurel", "lat": 31.67 , "lng": -89.17 },
      { "name": "Mccomb", "lat": 31.18 , "lng": -90.47 },
      { "name": "Meridian NAS", "lat": 32.55 , "lng": -88.57 },
      { "name": "Meridian/Key", "lat": 32.33 , "lng": -88.75 },
      { "name": "Natchez", "lat": 31.62 , "lng": -91.25 },
      { "name": "Oxford", "lat": 34.39 , "lng": -89.54 },
      { "name": "Tupelo", "lat": 34.27 , "lng": -88.77 }
    ]
  },
  "MO": {
    "name": "Missouri", "lat": 38.4623, "lng": -92.3020,
    "cities": [
      { "name": "Columbia", "lat": 38.82 , "lng": -92.22 },
      { "name": "Cp Girardeau", "lat": 37.23 , "lng": -89.58 },
      { "name": "Ft Leonard", "lat": 37.75 , "lng": -92.13 },
      { "name": "Jefferson City", "lat": 38.60 , "lng": -92.17 },
      { "name": "Joplin", "lat": 37.17 , "lng": -94.50 },
      { "name": "Kansas City", "lat": 39.32 , "lng": -94.72 },
      { "name": "Kansas City", "lat": 39.12 , "lng": -94.60 },
      { "name": "Kirksville", "lat": 40.10 , "lng": -92.55 },
      { "name": "Monett", "lat": 37.33 , "lng": -94.35 },
      { "name": "Muskogee", "lat": 35.66 , "lng": -95.36 },
      { "name": "Poplar Bluff", "lat": 36.77 , "lng": -90.47 },
      { "name": "Richards-Geb", "lat": 38.85 , "lng": -94.55 },
      { "name": "Spickard", "lat": 40.25 , "lng": -93.72 },
      { "name": "Springfield", "lat": 37.23 , "lng": -93.38 },
      { "name": "St Joseph", "lat": 40.28 , "lng": -95.53 },
      { "name": "St Louis", "lat": 38.75 , "lng": -90.37 },
      { "name": "StLouis/Spirit", "lat": 38.65 , "lng": -90.63 },
      { "name": "Vichy/Rolla", "lat": 38.13 , "lng": -91.77 },
      { "name": "West Plains", "lat": 37.22 , "lng": -92.42 },
      { "name": "West Plains", "lat": 36.88 , "lng": -91.90 },
      { "name": "Whiteman AFB", "lat": 38.73 , "lng": -93.55 }
    ]
  },
  "MT": {
    "name": "Montana", "lat": 46.9048, "lng": -110.3261, "show": true,
    "cities": [
      { "name": "Billings", "lat": 45.80, "lng": -108.53 },
      { "name": "Bozeman", "lat": 45.78, "lng": -111.15 },
      { "name": "Broadus", "lat": 45.67, "lng": -105.67 },
      { "name": "Butte", "lat": 45.95, "lng": -112.50 },
      { "name": "Cut Bank", "lat": 48.60, "lng": -112.37 },
      { "name": "Dillon", "lat": 45.25, "lng": -112.55 },
      { "name": "Drummond", "lat": 46.67, "lng": -113.15 },
      { "name": "Glasgow", "lat": 48.22, "lng": -106.62 },
      { "name": "Glendive", "lat": 47.13, "lng": -104.80 },
      { "name": "Great Falls", "lat": 47.48, "lng": -111.37 },
      { "name": "Harlowton", "lat": 46.43, "lng": -109.83 },
      { "name": "Havre", "lat": 48.55, "lng": -109.77 },
      { "name": "Helena", "lat": 46.60, "lng": -112.00 },
      { "name": "Jordan", "lat": 47.33, "lng": -106.93 },
      { "name": "Kalispell", "lat": 48.30, "lng": -114.27 },
      { "name": "Lewiston", "lat": 47.05, "lng": -109.45 },
      { "name": "Livingston", "lat": 45.70, "lng": -110.43 },
      { "name": "Malmstrom", "lat": 47.50, "lng": -111.18 },
      { "name": "Miles City", "lat": 46.43, "lng": -105.87 },
      { "name": "Missoula", "lat": 46.92, "lng": -114.08 },
      { "name": "Monida", "lat": 44.57, "lng": -112.32 },
      { "name": "Sidney", "lat": 47.72, "lng": -104.18 },
      { "name": "Thompson Fal", "lat": 47.60, "lng": -115.37 },
      { "name": "W Yellowston", "lat": 44.65, "lng": -111.10 },
      { "name": "W Yellowston", "lat": 44.68, "lng": -111.12 },
      { "name": "Wolf Point", "lat": 48.10, "lng": -105.58 }
    ]
  },
  "NE": {
    "name": "Nebraska", "lat": 41.1289, "lng": -98.2883, "show": true,
    "cities": [
      { "name": "Ainsworth", "lat": 42.58 , "lng": -99.98 },
      { "name": "Alliance", "lat": 42.05, "lng": -102.80 },
      { "name": "Beatrice", "lat": 40.32 , "lng": -96.75 },
      { "name": "Broken Bow", "lat": 41.43 , "lng": -99.65 },
      { "name": "Burwell", "lat": 41.78 , "lng": -99.15 },
      { "name": "Chadron", "lat": 42.83, "lng": -103.08 },
      { "name": "Columbus", "lat": 41.45 , "lng": -97.35 },
      { "name": "Cozad", "lat": 40.87, "lng": -100.00 },
      { "name": "Falls City", "lat": 40.07 , "lng": -95.58 },
      { "name": "Grand Island", "lat": 40.97 , "lng": -98.32 },
      { "name": "Hastings", "lat": 40.60 , "lng": -98.43 },
      { "name": "Imperial", "lat": 40.33, "lng": -101.39 },
      { "name": "Imperial", "lat": 40.53, "lng": -101.63 },
      { "name": "Kearney", "lat": 40.73 , "lng": -99.00 },
      { "name": "Lincoln Muni", "lat": 40.85 , "lng": -96.75 },
      { "name": "Mccook", "lat": 40.22, "lng": -100.58 },
      { "name": "Mullen", "lat": 42.05, "lng": -101.05 },
      { "name": "Norfolk", "lat": 41.98 , "lng": -97.43 },
      { "name": "North Omaha", "lat": 41.37 , "lng": -96.02 },
      { "name": "North Platte", "lat": 41.13, "lng": -100.68 },
      { "name": "O'neill", "lat": 42.47 , "lng": -98.68 },
      { "name": "Offutt AFB", "lat": 41.12 , "lng": -95.92 },
      { "name": "Omaha/Eppley", "lat": 41.30 , "lng": -95.90 },
      { "name": "Ord/Sharp", "lat": 41.62 , "lng": -98.95 },
      { "name": "Scottsbluff", "lat": 41.87, "lng": -103.60 },
      { "name": "Sidney Muni", "lat": 41.10, "lng": -102.98 },
      { "name": "Valentine", "lat": 42.87, "lng": -100.55 }
    ]
  },
  "NV": {
    "name": "Nevada", "lat": 38.4199, "lng": -117.1219,
    "cities": [
      { "name": "Austin", "lat": 39.83, "lng": -117.13 },
      { "name": "Austin", "lat": 39.50, "lng": -117.08 },
      { "name": "Battle Mtn", "lat": 40.62, "lng": -116.87 },
      { "name": "Caliente", "lat": 37.62, "lng": -114.52 },
      { "name": "Elko", "lat": 40.83, "lng": -115.78 },
      { "name": "Ely/Yelland", "lat": 39.28, "lng": -114.85 },
      { "name": "Eureka", "lat": 39.50, "lng": -115.97 },
      { "name": "Fallon NAS", "lat": 39.42, "lng": -118.70 },
      { "name": "Hawthorne", "lat": 38.55, "lng": -118.63 },
      { "name": "Ind Sprng Rn", "lat": 36.53, "lng": -115.57 },
      { "name": "Ind Sprng Rn", "lat": 36.57, "lng": -115.67 },
      { "name": "Las Vegas", "lat": 36.08, "lng": -115.17 },
      { "name": "Lovelock", "lat": 40.10, "lng": -118.92 },
      { "name": "Mercury", "lat": 36.62, "lng": -116.02 },
      { "name": "Nellis AFB", "lat": 36.23, "lng": -115.03 },
      { "name": "Owyhee", "lat": 42.58, "lng": -116.17 },
      { "name": "Reno", "lat": 39.50, "lng": -119.78 },
      { "name": "Tonopah", "lat": 38.07, "lng": -117.08 },
      { "name": "Wildhorse", "lat": 41.33, "lng": -116.25 },
      { "name": "Winnemucca", "lat": 40.90, "lng": -117.80 },
      { "name": "Yucca Flat", "lat": 37.58, "lng": -116.08 }
    ]
  },
  "NH": {
    "name": "New Hampshire", "lat": 43.4108, "lng": -71.5653,
    "cities": [
      { "name": "Berlin", "lat": 44.58 , "lng": -71.18 },
      { "name": "Concord", "lat": 43.20 , "lng": -71.50 },
      { "name": "Jaffrey", "lat": 42.80 , "lng": -72.00 },
      { "name": "Keene", "lat": 42.90 , "lng": -72.27 },
      { "name": "Laconia", "lat": 43.57 , "lng": -71.43 },
      { "name": "Lebanon", "lat": 43.63 , "lng": -72.30 },
      { "name": "Manchester", "lat": 42.93 , "lng": -71.43 },
      { "name": "Mt Washingtn", "lat": 44.27 , "lng": -71.30 },
      { "name": "Nashua", "lat": 42.78 , "lng": -71.52 },
      { "name": "Pease AFB", "lat": 43.08 , "lng": -70.82 },
      { "name": "Wolfeboro", "lat": 44.00 , "lng": -71.38 }
    ]
  },
  "NJ": {
    "name": "New Jersey", "lat": 40.3140, "lng": -74.5089,
    "cities": [
      { "name": "Atlantic Cty", "lat": 39.45 , "lng": -74.57 },
      { "name": "Barnegat Ls", "lat": 40.28 , "lng": -74.28 },
      { "name": "Fairfield", "lat": 40.87 , "lng": -74.28 },
      { "name": "Lakehurst", "lat": 40.03 , "lng": -74.35 },
      { "name": "Mcguire AFB", "lat": 40.02 , "lng": -74.60 },
      { "name": "Millville", "lat": 39.37 , "lng": -75.07 },
      { "name": "Morristown", "lat": 40.80 , "lng": -74.42 },
      { "name": "Newark Intl", "lat": 40.70 , "lng": -74.17 },
      { "name": "Teterboro", "lat": 40.85 , "lng": -74.05 },
      { "name": "Trenton", "lat": 40.28 , "lng": -74.82 }
    ]
  },
  "NM": {
    "name": "New Mexico",
    "cities": [
      { "name": "Albuquerque", "lat": 35.05, "lng": -106.60 },
      { "name": "Cannon", "lat": 34.38, "lng": -103.32 },
      { "name": "Carlsbad", "lat": 32.33, "lng": -104.27 },
      { "name": "Clayton Arpt", "lat": 36.45, "lng": -103.15 },
      { "name": "Corona", "lat": 34.10, "lng": -105.68 },
      { "name": "Cuba Awrs", "lat": 36.02, "lng": -106.95 },
      { "name": "Deming", "lat": 32.25, "lng": -107.70 },
      { "name": "Farmington", "lat": 36.75, "lng": -108.23 },
      { "name": "Gallup/Clark", "lat": 35.52, "lng": -108.78 },
      { "name": "Grants", "lat": 35.17, "lng": -107.90 },
      { "name": "Hobbs", "lat": 32.68, "lng": -103.20 },
      { "name": "Holloman AFB", "lat": 32.85, "lng": -106.10 },
      { "name": "Las Cruces", "lat": 32.30, "lng": -106.77 },
      { "name": "Las Vegas", "lat": 35.65, "lng": -105.15 },
      { "name": "Los Alamos", "lat": 35.88, "lng": -106.28 },
      { "name": "Moriarity", "lat": 34.98, "lng": -106.05 },
      { "name": "Northrup Str", "lat": 32.90, "lng": -106.40 },
      { "name": "Raton", "lat": 36.74, "lng": -104.50 },
      { "name": "Roswell", "lat": 33.30, "lng": -104.53 },
      { "name": "Santa Fe", "lat": 35.62, "lng": -106.08 },
      { "name": "Silver City", "lat": 32.63, "lng": -108.17 },
      { "name": "Socorro", "lat": 34.07, "lng": -106.90 },
      { "name": "Taos", "lat": 36.42, "lng": -105.57 },
      { "name": "Truth Or Con", "lat": 33.23, "lng": -107.27 },
      { "name": "Tucumcari", "lat": 35.18, "lng": -103.60 },
      { "name": "White Sands", "lat": 32.63, "lng": -106.40 }
    ]
  },
  "NY": {
    "name": "New York", "lat": 42.1497, "lng": -74.9384,
    "cities": [
      { "name": "Albany", "lat": 42.75 , "lng": -73.80 },
      { "name": "Ambrose", "lat": 40.75 , "lng": -74.37 },
      { "name": "Binghamton", "lat": 42.22 , "lng": -75.98 },
      { "name": "Buffalo", "lat": 42.93 , "lng": -78.73 },
      { "name": "Dansville", "lat": 42.97 , "lng": -78.20 },
      { "name": "Elmira", "lat": 42.17 , "lng": -76.90 },
      { "name": "Farmingdale", "lat": 40.73 , "lng": -73.43 },
      { "name": "Fort Drum", "lat": 44.05 , "lng": -75.73 },
      { "name": "Glens Falls", "lat": 43.35 , "lng": -73.62 },
      { "name": "Griffiss AFB", "lat": 43.23 , "lng": -75.40 },
      { "name": "Islip", "lat": 40.78 , "lng": -73.10 },
      { "name": "Ithaca", "lat": 42.48 , "lng": -76.47 },
      { "name": "Jamestown", "lat": 42.15 , "lng": -79.25 },
      { "name": "Massena", "lat": 44.93 , "lng": -74.85 },
      { "name": "Monticello", "lat": 41.70 , "lng": -74.80 },
      { "name": "New York", "lat": 40.77 , "lng": -73.98 },
      { "name": "New York/JFK", "lat": 40.65 , "lng": -73.78 },
      { "name": "New York/LGA", "lat": 40.77 , "lng": -73.90 },
      { "name": "Newburgh", "lat": 41.50 , "lng": -74.10 },
      { "name": "Niagara Fall", "lat": 43.10 , "lng": -78.95 },
      { "name": "Ogdensburg", "lat": 44.68 , "lng": -75.40 },
      { "name": "Oneonta", "lat": 42.87 , "lng": -75.12 },
      { "name": "Plattsburgh", "lat": 44.65 , "lng": -73.47 },
      { "name": "Poughkeepsie", "lat": 41.63 , "lng": -73.88 },
      { "name": "Rochester", "lat": 43.12 , "lng": -77.67 },
      { "name": "Saranac Lk", "lat": 44.38 , "lng": -74.20 },
      { "name": "Schenectady", "lat": 42.85 , "lng": -73.93 },
      { "name": "Syracuse", "lat": 43.12 , "lng": -76.12 },
      { "name": "Utica", "lat": 43.15 , "lng": -75.38 },
      { "name": "Watertown", "lat": 44.00 , "lng": -76.02 },
      { "name": "Westhampton", "lat": 40.85 , "lng": -72.63 },
      { "name": "White Plains", "lat": 41.07 , "lng": -73.72 }
    ]
  },
  "NC": {
    "name": "North Carolina", "lat": 35.6411, "lng": -79.8431,
    "cities": [
      { "name": "Asheville", "lat": 35.43 , "lng": -82.55 },
      { "name": "Cape Hattera", "lat": 35.27 , "lng": -75.55 },
      { "name": "Charlotte", "lat": 35.22 , "lng": -80.93 },
      { "name": "Cherry Point", "lat": 34.90 , "lng": -76.88 },
      { "name": "Dare Co Gr", "lat": 36.13 , "lng": -76.50 },
      { "name": "Diamond Sho", "lat": 35.25 , "lng": -75.50 },
      { "name": "Elizabeth", "lat": 36.27 , "lng": -76.18 },
      { "name": "Fayetteville", "lat": 35.00 , "lng": -78.88 },
      { "name": "Fort Bragg", "lat": 35.13 , "lng": -78.93 },
      { "name": "Greensboro", "lat": 36.08 , "lng": -79.95 },
      { "name": "Hickory", "lat": 35.75 , "lng": -81.38 },
      { "name": "Hot Springs", "lat": 35.90 , "lng": -82.82 },
      { "name": "Jacksonville", "lat": 34.82 , "lng": -77.62 },
      { "name": "Kinston", "lat": 35.32 , "lng": -77.63 },
      { "name": "Mackall Aaf", "lat": 35.03 , "lng": -79.50 },
      { "name": "Manteo Arpt", "lat": 35.92 , "lng": -75.68 },
      { "name": "New Bern", "lat": 35.08 , "lng": -77.05 },
      { "name": "New River", "lat": 34.70 , "lng": -77.43 },
      { "name": "Pope AFB", "lat": 35.17 , "lng": -79.02 },
      { "name": "Raleigh-Durh", "lat": 35.87 , "lng": -78.78 },
      { "name": "Rocky Mt", "lat": 35.85 , "lng": -77.88 },
      { "name": "Seymour-John", "lat": 35.33 , "lng": -77.97 },
      { "name": "Southern Pin", "lat": 35.24 , "lng": -79.39 },
      { "name": "Wilmington", "lat": 34.27 , "lng": -77.92 },
      { "name": "Winston-Salem", "lat": 36.13 , "lng": -80.23 }
    ]
  },
  "ND": {
    "name": "North Dakota", "lat": 47.5362, "lng": -99.7930, "show": true,
    "cities": [
      { "name": "Bismarck", "lat": 46.77, "lng": -100.75 },
      { "name": "Devil's Lake", "lat": 48.12 , "lng": -98.90 },
      { "name": "Devil's Lake", "lat": 48.10 , "lng": -98.87 },
      { "name": "Dickenson", "lat": 46.78, "lng": -102.80 },
      { "name": "Fargo", "lat": 46.90 , "lng": -96.80 },
      { "name": "Grand Forks", "lat": 47.95 , "lng": -97.18 },
      { "name": "Grand Forks", "lat": 47.97 , "lng": -97.40 },
      { "name": "Jamestown", "lat": 46.92 , "lng": -98.68 },
      { "name": "Lidgerwood", "lat": 46.10 , "lng": -97.15 },
      { "name": "Minot AFB", "lat": 48.42, "lng": -101.35 },
      { "name": "Minot Intl", "lat": 48.27, "lng": -101.28 },
      { "name": "Roseglen", "lat": 47.75, "lng": -101.83 },
      { "name": "Williston", "lat": 48.18, "lng": -103.63 }
    ]
  },
  "OH": {
    "name": "Ohio", "lat": 40.3736, "lng": -82.7755, "show": true,
    "cities": [
      { "name": "Athens/Albany", "lat": 39.21 , "lng": -82.23 },
      { "name": "Canton", "lat": 40.92 , "lng": -81.43 },
      { "name": "Cincinnati", "lat": 39.05 , "lng": -84.67 },
      { "name": "Cincinnati", "lat": 39.10 , "lng": -84.43 },
      { "name": "Cleveland", "lat": 41.52 , "lng": -81.68 },
      { "name": "Cleveland", "lat": 41.57 , "lng": -81.48 },
      { "name": "Cleveland", "lat": 41.42 , "lng": -81.87 },
      { "name": "Columbus", "lat": 40.00 , "lng": -82.88 },
      { "name": "Columbus Osu", "lat": 40.08 , "lng": -83.07 },
      { "name": "Dayton", "lat": 39.90 , "lng": -84.20 },
      { "name": "Findlay", "lat": 41.02 , "lng": -83.67 },
      { "name": "Mansfield", "lat": 40.82 , "lng": -82.52 },
      { "name": "Rickenbacker", "lat": 39.82 , "lng": -82.93 },
      { "name": "Toledo", "lat": 41.60 , "lng": -83.80 },
      { "name": "Willoughby", "lat": 41.63 , "lng": -81.40 },
      { "name": "Wright-Pat AFB", "lat": 39.83 , "lng": -84.05 },
      { "name": "Youngstown", "lat": 41.27 , "lng": -80.67 },
      { "name": "Zanesville", "lat": 39.95 , "lng": -81.90 }
    ]
  },
  "OK": {
    "name": "Oklahoma", "lat": 35.5376, "lng": -96.9247,
    "cities": [
      { "name": "Altus AFB", "lat": 34.67 , "lng": -99.27 },
      { "name": "Ardmore", "lat": 34.30 , "lng": -97.02 },
      { "name": "Bartlesville", "lat": 36.75 , "lng": -96.00 },
      { "name": "Clinton", "lat": 35.35 , "lng": -99.20 },
      { "name": "Enid", "lat": 36.38 , "lng": -97.80 },
      { "name": "Fort Sill", "lat": 34.65 , "lng": -98.40 },
      { "name": "Gage", "lat": 36.30 , "lng": -99.77 },
      { "name": "Hobart", "lat": 35.00 , "lng": -99.05 },
      { "name": "Lawton", "lat": 34.57 , "lng": -98.42 },
      { "name": "Mcalester", "lat": 34.88 , "lng": -95.78 },
      { "name": "Norman", "lat": 35.23 , "lng": -97.47 },
      { "name": "Oklahoma Cty", "lat": 35.40 , "lng": -97.60 },
      { "name": "Oklahoma Cty", "lat": 35.53 , "lng": -97.65 },
      { "name": "Page", "lat": 34.68 , "lng": -94.62 },
      { "name": "Ponca City", "lat": 36.73 , "lng": -97.10 },
      { "name": "Stillwater", "lat": 36.16 , "lng": -97.09 },
      { "name": "Tinker AFB", "lat": 35.42 , "lng": -97.38 },
      { "name": "Tulsa", "lat": 36.20 , "lng": -95.90 },
      { "name": "Vance AFB", "lat": 36.33 , "lng": -97.92 }
    ]
  },
  "OR": {
    "name": "Oregon", "lat": 44.5672, "lng": -122.1269, "show": true,
    "cities": [
      { "name": "Astoria", "lat": 46.15, "lng": -123.88 },
      { "name": "Aurora", "lat": 45.25, "lng": -122.75 },
      { "name": "Baker", "lat": 44.83, "lng": -117.82 },
      { "name": "Brookings", "lat": 42.08, "lng": -124.47 },
      { "name": "Burns Arpt", "lat": 43.60, "lng": -118.95 },
      { "name": "Cape Blanco", "lat": 43.38, "lng": -124.95 },
      { "name": "Cascade", "lat": 45.68, "lng": -121.88 },
      { "name": "Corvallis", "lat": 44.50, "lng": -123.28 },
      { "name": "Eugene", "lat": 44.12, "lng": -123.22 },
      { "name": "Hillsboro", "lat": 45.53, "lng": -122.95 },
      { "name": "Klamath Fall", "lat": 42.15, "lng": -121.73 },
      { "name": "La Grande", "lat": 45.28, "lng": -118.00 },
      { "name": "Lake View", "lat": 42.18, "lng": -120.35 },
      { "name": "Meacham", "lat": 45.50, "lng": -118.40 },
      { "name": "Medford", "lat": 42.37, "lng": -122.87 },
      { "name": "Newport", "lat": 44.63, "lng": -124.05 },
      { "name": "North Bend", "lat": 43.42, "lng": -124.25 },
      { "name": "Ontario", "lat": 44.02, "lng": -117.02 },
      { "name": "Pendleton", "lat": 45.68, "lng": -118.85 },
      { "name": "Portland", "lat": 45.60, "lng": -122.60 },
      { "name": "Redmond", "lat": 44.27, "lng": -121.15 },
      { "name": "Roseburg", "lat": 43.23, "lng": -123.37 },
      { "name": "Salem", "lat": 44.92, "lng": -123.00 },
      { "name": "Sexton", "lat": 42.62, "lng": -123.37 },
      { "name": "The Dalles", "lat": 45.62, "lng": -121.15 },
      { "name": "Troutdale", "lat": 45.55, "lng": -122.40 }
    ]
  },
  "PA": {
    "name": "Pennsylvania", "lat": 40.5773, "lng": -77.2640,
    "cities": [
      { "name": "Allentown", "lat": 40.65 , "lng": -75.43 },
      { "name": "Altoona", "lat": 40.30 , "lng": -78.32 },
      { "name": "Beaver Falls", "lat": 40.75 , "lng": -80.33 },
      { "name": "Blairsville", "lat": 40.27 , "lng": -79.09 },
      { "name": "Bradford", "lat": 41.80 , "lng": -78.63 },
      { "name": "Dubois", "lat": 41.18 , "lng": -78.90 },
      { "name": "Erie", "lat": 42.08 , "lng": -80.18 },
      { "name": "Franklin", "lat": 41.38 , "lng": -79.87 },
      { "name": "Harrisburg", "lat": 40.22 , "lng": -76.85 },
      { "name": "Harrisburg", "lat": 40.37 , "lng": -77.42 },
      { "name": "Johnstown", "lat": 40.32 , "lng": -78.83 },
      { "name": "Lancaster", "lat": 40.13 , "lng": -76.30 },
      { "name": "Latrobe", "lat": 40.28 , "lng": -79.40 },
      { "name": "Middletown", "lat": 40.20 , "lng": -76.77 },
      { "name": "Muir", "lat": 40.43 , "lng": -76.57 },
      { "name": "Nth Philadel", "lat": 40.08 , "lng": -75.02 },
      { "name": "Philadelphia", "lat": 39.88 , "lng": -75.25 },
      { "name": "Philipsburg", "lat": 41.47 , "lng": -78.13 },
      { "name": "Pittsburgh", "lat": 40.35 , "lng": -79.93 },
      { "name": "Pittsburgh", "lat": 40.50 , "lng": -80.22 },
      { "name": "Reading", "lat": 40.38 , "lng": -75.97 },
      { "name": "Site R", "lat": 39.73 , "lng": -77.43 },
      { "name": "State Colleg", "lat": 40.85 , "lng": -77.83 },
      { "name": "Wilkes-Barre", "lat": 41.33 , "lng": -75.73 },
      { "name": "Williamsport", "lat": 41.25 , "lng": -76.92 },
      { "name": "Willow Grove", "lat": 40.20 , "lng": -75.15 }
    ]
  },
  "RI": {
    "name": "Rhode Island", "lat": 41.6772, "lng": -71.5101,
    "cities": [
      { "name": "Block Island", "lat": 41.17 , "lng": -71.58 },
      { "name": "Nth Kingston", "lat": 41.60 , "lng": -71.42 },
      { "name": "Providence", "lat": 41.73 , "lng": -71.43 },
      { "name": "Quonset Pt NAS", "lat": 41.36 , "lng": -71.25 }
    ]
  },
  "SC": {
    "name": "South Carolina", "lat": 33.8191, "lng": -80.9066, "show": true,
    "cities": [
      { "name": "Anderson", "lat": 34.50 , "lng": -82.72 },
      { "name": "Beaufort Mca", "lat": 32.48 , "lng": -80.72 },
      { "name": "Charleston", "lat": 32.90 , "lng": -80.03 },
      { "name": "Columbia", "lat": 33.95 , "lng": -81.12 },
      { "name": "Florence", "lat": 34.18 , "lng": -79.72 },
      { "name": "Greenville", "lat": 34.85 , "lng": -82.35 },
      { "name": "Greenville", "lat": 34.90 , "lng": -82.22 },
      { "name": "Mcentire", "lat": 33.92 , "lng": -80.80 },
      { "name": "Myrtle Beach", "lat": 33.68 , "lng": -78.93 },
      { "name": "Nth Myrtle B", "lat": 33.82 , "lng": -78.72 },
      { "name": "Shaw AFB", "lat": 33.97 , "lng": -80.47 },
      { "name": "Spartanburg", "lat": 34.92 , "lng": -81.96 }
    ]
  },
  "SD": {
    "name": "South Dakota", "lat": 44.2853, "lng": -99.4632, "show": true,
    "cities": [
      { "name": "Aberdeen", "lat": 45.45 , "lng": -98.43 },
      { "name": "Brookings", "lat": 44.30 , "lng": -96.80 },
      { "name": "Chamberlain", "lat": 43.80 , "lng": -99.32 },
      { "name": "Chamberlain", "lat": 43.75 , "lng": -99.32 },
      { "name": "Custer", "lat": 43.77, "lng": -103.60 },
      { "name": "Ellsworth Af", "lat": 44.15, "lng": -103.10 },
      { "name": "Huron", "lat": 44.38 , "lng": -98.22 },
      { "name": "Lemmon", "lat": 45.93, "lng": -102.17 },
      { "name": "Mitchell", "lat": 43.77 , "lng": -98.03 },
      { "name": "Mobridge", "lat": 45.53, "lng": -100.43 },
      { "name": "Philip", "lat": 44.05, "lng": -101.60 },
      { "name": "Philip", "lat": 44.05, "lng": -101.60 },
      { "name": "Pierre", "lat": 44.38, "lng": -100.28 },
      { "name": "Rapid City", "lat": 44.05, "lng": -103.07 },
      { "name": "Redig", "lat": 45.16, "lng": -103.32 },
      { "name": "Sioux Falls", "lat": 43.58 , "lng": -96.73 },
      { "name": "Watertown", "lat": 44.92 , "lng": -97.15 },
      { "name": "Yankton", "lat": 42.92 , "lng": -97.38 }
    ]
  },
  "TN": {
    "name": "Tennessee", "lat": 35.7449, "lng": -86.7489, "show": true,
    "cities": [
      { "name": "Bristol", "lat": 36.48 , "lng": -82.40 },
      { "name": "Chattanooga", "lat": 35.03 , "lng": -85.20 },
      { "name": "Clarksville", "lat": 36.62 , "lng": -87.42 },
      { "name": "Crossville", "lat": 35.95 , "lng": -85.08 },
      { "name": "Dyersburg", "lat": 36.02 , "lng": -89.40 },
      { "name": "Jackson", "lat": 35.60 , "lng": -88.92 },
      { "name": "Knoxville", "lat": 35.82 , "lng": -83.98 },
      { "name": "Memphis Intl", "lat": 35.05 , "lng": -90.00 },
      { "name": "Memphis NAS", "lat": 35.35 , "lng": -89.87 },
      { "name": "Monteagle", "lat": 35.15 , "lng": -85.51 },
      { "name": "Nashville", "lat": 36.12 , "lng": -86.68 },
      { "name": "Smyrna", "lat": 36.00 , "lng": -86.50 }
    ]
  },
  "TX": {
    "name": "Texas", "lat": 31.1060, "lng": -97.6475, "show": true,
    "cities": [
      { "name": "Abilene", "lat": 32.42 , "lng": -99.68 },
      { "name": "Alice", "lat": 27.73 , "lng": -98.03 },
      { "name": "Amarillo", "lat": 35.23, "lng": -101.70 },
      { "name": "Austin", "lat": 30.30 , "lng": -97.70 },
      { "name": "Bergstrom Af", "lat": 30.20 , "lng": -97.68 },
      { "name": "Big Sky", "lat": 32.39, "lng": -101.48 },
      { "name": "Big Spring", "lat": 32.30, "lng": -101.45 },
      { "name": "Brownsville", "lat": 25.90 , "lng": -97.43 },
      { "name": "Brownwood", "lat": 31.79 , "lng": -98.96 },
      { "name": "Carswell AFB", "lat": 32.78 , "lng": -97.43 },
      { "name": "Chase NAS", "lat": 28.37 , "lng": -97.67 },
      { "name": "Childress", "lat": 34.43, "lng": -100.28 },
      { "name": "College Stn", "lat": 30.58 , "lng": -96.37 },
      { "name": "Corpus Chrst", "lat": 27.77 , "lng": -97.50 },
      { "name": "Corpus Chrst", "lat": 27.70 , "lng": -97.28 },
      { "name": "Cotulla", "lat": 28.45 , "lng": -99.22 },
      { "name": "D.w. Hooks", "lat": 30.07 , "lng": -95.55 },
      { "name": "Dalhart", "lat": 36.02, "lng": -102.55 },
      { "name": "Dallas NAS", "lat": 32.73 , "lng": -96.97 },
      { "name": "Dallas/Addis", "lat": 32.97 , "lng": -96.83 },
      { "name": "Dallas/FW", "lat": 32.90 , "lng": -97.03 },
      { "name": "Dallas/Love", "lat": 32.85 , "lng": -96.85 },
      { "name": "Dallas/Redbr", "lat": 32.68 , "lng": -96.87 },
      { "name": "Del Rio", "lat": 29.37, "lng": -100.92 },
      { "name": "Dyess AFB", "lat": 32.43 , "lng": -99.85 },
      { "name": "El Paso", "lat": 31.80, "lng": -106.40 },
      { "name": "Ellington Af", "lat": 29.62 , "lng": -95.17 },
      { "name": "Fort Worth", "lat": 32.82 , "lng": -97.35 },
      { "name": "Ft Hood Aaf", "lat": 31.15 , "lng": -97.72 },
      { "name": "Galveston", "lat": 29.27 , "lng": -94.87 },
      { "name": "Gray AFB", "lat": 31.07 , "lng": -97.83 },
      { "name": "Greenville", "lat": 33.07 , "lng": -96.07 },
      { "name": "Guadalupe Ps", "lat": 31.83, "lng": -104.80 },
      { "name": "Harlingen", "lat": 26.23 , "lng": -97.67 },
      { "name": "Hondo", "lat": 29.35 , "lng": -99.17 },
      { "name": "Houston", "lat": 29.97 , "lng": -95.35 },
      { "name": "Houston/Hobby", "lat": 29.65 , "lng": -95.28 },
      { "name": "Junction", "lat": 30.50 , "lng": -99.77 },
      { "name": "Kelly AFB", "lat": 29.38 , "lng": -98.58 },
      { "name": "Kerrville", "lat": 29.98 , "lng": -99.08 },
      { "name": "Killeen", "lat": 31.08 , "lng": -97.68 },
      { "name": "Kingsville", "lat": 27.50 , "lng": -97.82 },
      { "name": "Laredo Intl", "lat": 27.53 , "lng": -99.47 },
      { "name": "Laughlin AFB", "lat": 29.37, "lng": -100.78 },
      { "name": "Longview", "lat": 32.38 , "lng": -94.72 },
      { "name": "Lubbock", "lat": 33.65, "lng": -101.82 },
      { "name": "Lufkin", "lat": 31.23 , "lng": -94.75 },
      { "name": "Marfa", "lat": 30.37, "lng": -104.02 },
      { "name": "Mcallen", "lat": 26.18 , "lng": -98.23 },
      { "name": "Midland", "lat": 31.95, "lng": -102.18 },
      { "name": "Mineral Wlls", "lat": 32.78 , "lng": -98.07 },
      { "name": "Palacios", "lat": 28.72 , "lng": -96.25 },
      { "name": "Paris/Cox", "lat": 33.63 , "lng": -95.45 },
      { "name": "Plainview", "lat": 34.17, "lng": -101.71 },
      { "name": "Port Arthur", "lat": 30.58 , "lng": -94.02 },
      { "name": "Randolph AFB", "lat": 29.53 , "lng": -98.28 },
      { "name": "Reese AFB", "lat": 33.60, "lng": -102.05 },
      { "name": "Rockport", "lat": 28.08 , "lng": -97.03 },
      { "name": "San Angelo", "lat": 31.37, "lng": -100.50 },
      { "name": "San Antonio", "lat": 29.53 , "lng": -98.47 },
      { "name": "San Antonio", "lat": 29.33 , "lng": -98.47 },
      { "name": "Sanderson", "lat": 30.17, "lng": -102.42 },
      { "name": "Sherman-Deni", "lat": 33.72 , "lng": -96.67 },
      { "name": "South Brazos", "lat": 28.03 , "lng": -95.87 },
      { "name": "Stephenville", "lat": 32.22 , "lng": -98.18 },
      { "name": "Temple", "lat": 31.15 , "lng": -97.42 },
      { "name": "Tyler/Pounds", "lat": 32.37 , "lng": -95.40 },
      { "name": "Victoria", "lat": 28.85 , "lng": -96.92 },
      { "name": "Waco-Madison", "lat": 31.62 , "lng": -97.22 },
      { "name": "Wichita Flls", "lat": 33.98 , "lng": -98.50 },
      { "name": "Wink", "lat": 31.78, "lng": -103.20 }
    ]
  },
  "UT": {
    "name": "Utah", "lat": 40.1135, "lng": -111.8535, "show": true,
    "cities": [
      { "name": "Blanding", "lat": 38.03, "lng": -109.78 },
      { "name": "Bullfrog Mar", "lat": 37.50, "lng": -110.70 },
      { "name": "Cedar City", "lat": 37.70, "lng": -113.10 },
      { "name": "Delta", "lat": 39.33, "lng": -112.58 },
      { "name": "Dugway Prvgr", "lat": 40.20, "lng": -112.93 },
      { "name": "Eagle Range", "lat": 41.05, "lng": -113.07 },
      { "name": "Green River", "lat": 39.00, "lng": -110.15 },
      { "name": "Hanksville", "lat": 38.37, "lng": -110.72 },
      { "name": "Hill AFB", "lat": 41.12, "lng": -111.97 },
      { "name": "Logan", "lat": 41.78, "lng": -111.85 },
      { "name": "Milford", "lat": 38.72, "lng": -113.03 },
      { "name": "Moab", "lat": 38.77, "lng": -109.75 },
      { "name": "Ogden", "lat": 41.18, "lng": -112.02 },
      { "name": "Price/Carbon", "lat": 39.62, "lng": -110.75 },
      { "name": "Provo", "lat": 40.22, "lng": -111.72 },
      { "name": "Roosevelt", "lat": 40.50, "lng": -110.63 },
      { "name": "Saint George", "lat": 37.08, "lng": -113.60 },
      { "name": "Salt Lake Ct", "lat": 40.78, "lng": -111.97 },
      { "name": "Tooele", "lat": 40.17, "lng": -112.20 },
      { "name": "Vernal", "lat": 40.45, "lng": -109.52 },
      { "name": "Wendover", "lat": 41.22, "lng": -114.05 }
    ]
  },
  "VT": {
    "name": "Vermont", "lat": 44.0407, "lng": -72.7093, "show": true,
    "cities": [
      { "name": "Burlington", "lat": 44.47 , "lng": -73.15 },
      { "name": "Montpelier", "lat": 44.20 , "lng": -72.57 },
      { "name": "Newport", "lat": 45.55 , "lng": -72.33 },
      { "name": "Rutland", "lat": 43.53 , "lng": -73.95 },
      { "name": "St Johnsbury", "lat": 44.42 , "lng": -72.02 },
      { "name": "Wilmington", "lat": 42.88 , "lng": -72.88 }
    ]
  },
  "VA": {
    "name": "Virginia", "lat": 37.7680, "lng": -78.2057,
    "cities": [
      { "name": "Charlottesvi", "lat": 38.13 , "lng": -78.45 },
      { "name": "Chesapeake", "lat": 37.50 , "lng": -76.20 },
      { "name": "Danville", "lat": 36.57 , "lng": -79.33 },
      { "name": "Fort Belvoir", "lat": 38.72 , "lng": -77.18 },
      { "name": "Fort Eustis", "lat": 37.13 , "lng": -76.62 },
      { "name": "Hot Springs", "lat": 37.95 , "lng": -79.82 },
      { "name": "Langley AFB", "lat": 37.08 , "lng": -76.37 },
      { "name": "Lynchburg", "lat": 37.33 , "lng": -79.20 },
      { "name": "Newport News", "lat": 37.13 , "lng": -76.50 },
      { "name": "Norfolk NAS", "lat": 36.93 , "lng": -76.28 },
      { "name": "Norfolk Rgnl", "lat": 36.90 , "lng": -76.20 },
      { "name": "Oceana NAS", "lat": 36.82 , "lng": -76.03 },
      { "name": "Quantico Mca", "lat": 38.50 , "lng": -77.30 },
      { "name": "Richmond", "lat": 37.50 , "lng": -77.33 },
      { "name": "Roanoke Muni", "lat": 37.32 , "lng": -79.97 },
      { "name": "Staunton", "lat": 38.27 , "lng": -78.85 },
      { "name": "Volens", "lat": 36.95 , "lng": -78.98 },
      { "name": "Wallops Sta", "lat": 37.85 , "lng": -75.48 }
    ]
  },
  "WA": {
    "name": "Washington", "lat": 47.3917, "lng": -121.5708, "show": true,
    "cities": [
      { "name": "Bellingham", "lat": 48.80, "lng": -122.53 },
      { "name": "Bremerton", "lat": 47.48, "lng": -122.77 },
      { "name": "Burlington", "lat": 48.50, "lng": -122.33 },
      { "name": "Colville", "lat": 48.88, "lng": -118.47 },
      { "name": "Ephrata", "lat": 47.32, "lng": -119.52 },
      { "name": "Everet/Paine", "lat": 47.92, "lng": -122.28 },
      { "name": "Fairchild", "lat": 47.62, "lng": -117.65 },
      { "name": "Fort Lewis", "lat": 47.08, "lng": -122.58 },
      { "name": "Hanford", "lat": 46.57, "lng": -119.60 },
      { "name": "Hoquiam", "lat": 46.97, "lng": -123.97 },
      { "name": "Mcchord AFB", "lat": 47.15, "lng": -122.48 },
      { "name": "Moses Lake", "lat": 47.20, "lng": -119.32 },
      { "name": "Oak Harbor", "lat": 48.25, "lng": -122.68 },
      { "name": "Olympia", "lat": 46.97, "lng": -122.90 },
      { "name": "Omak", "lat": 48.42, "lng": -119.53 },
      { "name": "Pasco", "lat": 46.27, "lng": -119.12 },
      { "name": "Port Angeles", "lat": 48.12, "lng": -123.50 },
      { "name": "Port Angeles", "lat": 48.22, "lng": -123.67 },
      { "name": "Pullman", "lat": 46.75, "lng": -117.12 },
      { "name": "Quillayute", "lat": 47.95, "lng": -124.55 },
      { "name": "Renton", "lat": 47.50, "lng": -122.22 },
      { "name": "Seattle", "lat": 47.45, "lng": -122.30 },
      { "name": "Seattle/Boeing", "lat": 47.53, "lng": -122.30 },
      { "name": "Shelton", "lat": 47.25, "lng": -123.15 },
      { "name": "Spokane", "lat": 47.63, "lng": -117.53 },
      { "name": "Spokane", "lat": 47.67, "lng": -117.33 },
      { "name": "Stampede Pas", "lat": 47.28, "lng": -121.33 },
      { "name": "Tacoma", "lat": 47.27, "lng": -122.58 },
      { "name": "Toledo", "lat": 46.48, "lng": -122.80 },
      { "name": "Walla Walla", "lat": 46.10, "lng": -118.28 },
      { "name": "Wenatchee", "lat": 47.40, "lng": -120.20 },
      { "name": "Whidbey Is", "lat": 48.35, "lng": -122.65 },
      { "name": "Yakima", "lat": 46.57, "lng": -120.53 }
    ]
  },
  "WV": {
    "name": "West Virginia", "lat": 38.4680, "lng": -80.9696, "show": true,
    "cities": [
      { "name": "Beckley", "lat": 37.78 , "lng": -81.12 },
      { "name": "Bluefield", "lat": 37.30 , "lng": -81.22 },
      { "name": "Charleston", "lat": 38.37 , "lng": -81.60 },
      { "name": "Clarksburg", "lat": 39.28 , "lng": -80.23 },
      { "name": "Elkins", "lat": 38.88 , "lng": -79.85 },
      { "name": "Huntington", "lat": 38.37 , "lng": -82.55 },
      { "name": "Lewisburg", "lat": 37.87 , "lng": -80.40 },
      { "name": "Martinsburg", "lat": 39.40 , "lng": -77.98 },
      { "name": "Morgantown", "lat": 39.65 , "lng": -79.92 },
      { "name": "Parkersburg", "lat": 39.35 , "lng": -81.43 },
      { "name": "Wheeling", "lat": 40.18 , "lng": -80.65 },
      { "name": "White Sulph", "lat": 37.46 , "lng": -80.20 }
    ]
  },
  "WI": {
    "name": "Wisconsin", "lat": 44.2563, "lng": -89.6385, "show": true,
    "cities": [
      { "name": "Appleton", "lat": 44.25 , "lng": -88.52 },
      { "name": "Eau Claire", "lat": 44.87 , "lng": -91.48 },
      { "name": "Green Bay", "lat": 44.48 , "lng": -88.13 },
      { "name": "Janesville", "lat": 42.62 , "lng": -89.03 },
      { "name": "La Crosse", "lat": 43.87 , "lng": -91.25 },
      { "name": "Lone Rock", "lat": 43.20 , "lng": -90.18 },
      { "name": "Madison", "lat": 43.13 , "lng": -89.33 },
      { "name": "Manitowac", "lat": 44.13 , "lng": -87.67 },
      { "name": "Milwaukee", "lat": 42.95 , "lng": -87.90 },
      { "name": "Milwaukee", "lat": 43.12 , "lng": -88.05 },
      { "name": "Mosinee", "lat": 44.78 , "lng": -89.67 },
      { "name": "Neenah", "lat": 44.22 , "lng": -88.53 },
      { "name": "Oshkosh", "lat": 44.00 , "lng": -88.57 },
      { "name": "Rhinelander", "lat": 45.63 , "lng": -89.45 },
      { "name": "Rice Lake", "lat": 45.48 , "lng": -91.72 },
      { "name": "Volk Fld", "lat": 43.93 , "lng": -90.27 },
      { "name": "Wausau", "lat": 44.92 , "lng": -89.62 }
    ]
  },
  "WY": {
    "name": "Wyoming", "lat": 42.7475, "lng": -107.2085, "show": true,
    "cities": [
      { "name": "Big Piney", "lat": 42.57, "lng": -110.10 },
      { "name": "Casper", "lat": 42.92, "lng": -106.47 },
      { "name": "Cheyenne", "lat": 41.15, "lng": -104.82 },
      { "name": "Cody", "lat": 44.52, "lng": -109.02 },
      { "name": "Douglas", "lat": 42.75, "lng": -105.38 },
      { "name": "Evanston", "lat": 41.33, "lng": -111.00 },
      { "name": "Gillette", "lat": 44.35, "lng": -105.53 },
      { "name": "Jackson", "lat": 43.60, "lng": -110.73 },
      { "name": "Lander", "lat": 42.82, "lng": -108.73 },
      { "name": "Laramie", "lat": 41.32, "lng": -105.68 },
      { "name": "Moorcroft", "lat": 44.35, "lng": -104.81 },
      { "name": "Rawlins", "lat": 41.80, "lng": -107.20 },
      { "name": "Riverton", "lat": 43.05, "lng": -108.45 },
      { "name": "Rock Springs", "lat": 41.60, "lng": -109.07 },
      { "name": "Sheridan", "lat": 44.77, "lng": -106.97 },
      { "name": "Worland", "lat": 43.97, "lng": -107.97 },
      { "name": "Yellowstone", "lat": 44.55, "lng": -110.42 }
    ]
  },
  "AS": {
    "name": "American Samoa", "lat": -14.35, "lng": -170.73, "show": false,
    "cities": [
      { "name": "Pago Pago", "lat": 14.16, "lng": -170.42 },
      //{ "name": "Tafuna, Leone", "lat": 14.20, "lng": -170.43 },
      { "name": "Tau", "lat": 14.14, "lng": -169.30 },
      { "name": "Taulaga", "lat": 11.3, "lng": -171.5 }
    ]
  },
  "MP": {
    "name": "Northern Mariana Islands", "lat": 15.23, "lng": 145.74, "show": false,
    "cities": [
      { "name": "Saipan", "lat": 15.12, "lng": 145.45 },
      //{ "name": "Tinian", "lat": 14.57, "lng": 145.38 },
      //{ "name": "Agrihan", "lat": 18.46, "lng": 145.40 },
      { "name": "Pagan", "lat": 18.8, "lng": 145.47 }
    ]
  },
  "VI": {
    "name": "US Virgin Islands", "lat": 18.09, "lng": -64.83, "show": false,
    "cities": [
      { "name": "Charlotte Amalie", "lat": 18.20, "lng": -64.55 },
      //{ "name": "Cruz Bay", "lat": 18.19, "lng": -64.47 },
      //{ "name": "Christiansted", "lat": 17.44, "lng": -64.42 },
      { "name": "Saint Croix", "lat": 17.43, "lng": -64.44 }
    ]
  },
  "GU": {
    "name": "Guam", "lat": 18.09, "lng": 144.67, "show": false,
    "cities": [
      { "name": "Dededo Village", "lat": 13.31, "lng": 144.50 },
      //{ "name": "Yigo Village", "lat": 13.32, "lng": 144.53 },
      //{ "name": "Tamuning-Tumon-Harmon Village", "lat": 13.29, "lng": 144.46 },
      //{ "name": "Guam Government House", "lat": 13.28, "lng": 144.44 },
      { "name": "Agat Village", "lat": 13.23, "lng": 144.39 }
    ]
  },
  "PR": {
    "name": "Puerto Rico", "lat": 18.46, "lng": -66.10, "show": false,
    "cities": [
      { "name": "San Juan", "lat": 18.27, "lng": -66.6 },
      //{ "name": "Fajardo", "lat": 18.19, "lng": -65.39 },
      { "name": "Guayama", "lat": 17.59, "lng": -66.6 },
      { "name": "Ponce", "lat": 18.0, "lng": -66.36 },
      //{ "name": "Carolina", "lat": 18.22, "lng": -65.57 },
      //{ "name": "Bayamon", "lat": 18.23, "lng": -66.9 }
    ]
  },
  "NU": {
    "name": "Nunavut", "lat": 66.0, "lng": -85.0, "show": true,
    "cities": [
      { "name": "Alert", "lat": 82.30, "lng": -62.20 },
      { "name": "Eureka", "lat": 79.59, "lng": -85.56 },
      { "name": "Grise Fiord", "lat": 76.25, "lng": -82.54 },
      { "name": "Iqaluit", "lat": 63.45, "lng": -68.31 }
    ]
  },
  "NT": {
    "name": "Northwest Territories", "lat": 63.0, "lng": -118.0, "show": true,
    "cities": [
      { "name": "Tuktoyaktuk", "lat": 69.27, "lng": -133.02 },
      { "name": "Yellowknife", "lat": 62.27, "lng": -114.24 }
    ]
  },
  "YT": {
    "name": "Yukon", "lat": 63.0, "lng": -135.0, "show": true,
    "cities": [
      { "name": "Whitehorse", "lat": 60.43, "lng": -135.03 }
    ]
  },
  "AB": {
    "name": "Alberta", "lat": 54.40, "lng": -115.0, "show": true,
    "cities": [
      { "name": "Fort McMurray", "lat": 56.44, "lng": -111.23 },
      { "name": "Grande Prairie", "lat": 55.10, "lng": -118.48 },
      { "name": "Edmonton", "lat": 53.32, "lng": -113.30 },
      { "name": "Calgary", "lat": 51.03, "lng": -114.04 }
    ]
  },
  "SK": {
    "name": "Saskatchewan", "lat": 54.40, "lng": -106.0, "show": true,
    "cities": [
      { "name": "Saskatoon", "lat": 52.08, "lng": -106.41 },
      { "name": "Regina", "lat": 50.27, "lng": -104.36 }
    ]
  },
  "BC": {
    "name": "British Columbia", "lat": 55.00, "lng": -125.15, "show": true,
    "cities": [
      { "name": "Kamloops", "lat": 50.41, "lng": -120.20 },
      { "name": "Kelowna", "lat": 49.53, "lng": -119.30 },
      { "name": "Vancouver", "lat": 49.15, "lng": -123.06 },
      { "name": "Victoria", "lat": 48.26, "lng": -123.22 }
    ]
  },
  "MB": {
    "name": "Manitoba", "lat": 55.30, "lng": -97.0, "show": true,
    "cities": [
      { "name": "Winnipeg", "lat": 49.54, "lng": -97.08 }
    ]
  },
  "ON": {
    "name": "Ontario", "lat": 48.0, "lng": -83.0, "show": true,
    "cities": [
      { "name": "Thunder Bay", "lat": 48.23, "lng": -89.15 },
      { "name": "Sault Ste. Marie", "lat": 46.32, "lng": -84.21 },
      { "name": "Sudbury", "lat": 46.29, "lng": -81.01 },
      { "name": "North Bay", "lat": 46.18, "lng": -79.27 },
      { "name": "Ottawa", "lat": 45.25, "lng": -75.41 },
      { "name": "Toronto", "lat": 43.42, "lng": -79.24 },
      { "name": "Windsor", "lat": 42.17, "lng": -83.00 }
    ]
  },
  "NL": {
    "name": "Newfoundland and Labrador", "lat": 49.00, "lng": -55.0, "show": true,
    "cities": [
      { "name": "St. John's", "lat": 47.34, "lng": -52.42 }
    ]
  },
  "QC": {
    "name": "Quebec", "lat": 46.52, "lng": -71.13, "show": true,
    "cities": [
      { "name": "Quebec City", "lat": 46.49, "lng": -71.13 },
      { "name": "Montreal", "lat": 45.30, "lng": -73.34 }
    ]
  },
  "PI": {
    "name": "Prince Edward Island", "lat": 46.20, "lng": -63.20, "show": true,
    "cities": [
      { "name": "Charlottetown", "lat": 46.14, "lng": -63.09 }
    ]
  },
  "NB": {
    "name": "New Brunswick", "lat": 46.50, "lng": -66.30, "show": true,
    "cities": [
      { "name": "Moncton", "lat": 46.08, "lng": -64.46 },
      { "name": "Fredericton", "lat": 45.57, "lng": -66.40 },
      { "name": "Saint John", "lat": 45.17, "lng": -66.05 }
    ]
  },
  "NS": {
    "name": "Nova Scotia", "lat": 45.10, "lng": -63.0, "show": true,
    "cities": [
      { "name": "New Glasgow", "lat": 45.59, "lng": -62.64 },
      { "name": "Halifax", "lat": 44.51, "lng": -63.12 },
      { "name": "Yarmouth", "lat": 43.50, "lng": -66.07 }
    ]
  },
  "AIA": {
    "name": "Anguilla", "lat": 18.22, "lng": -63.06, "show": true, country: true,
    "cities": [
      { "name": "Bethel", "lat": 18.20, "lng": -63.10 },
      // { "name": "Bungalows", "lat": 18.18, "lng": -63.10 },
      { "name": "East End Village", "lat": 18.23, "lng": -63.00 },
      // { "name": "Little Dix", "lat": 18.23, "lng": -63.03 },
      // { "name": "Mahogany Tree", "lat": 18.20, "lng": -63.03 },
      { "name": "Shoal Village", "lat": 18.25, "lng": -63.02 },
      // { "name": "The Valley", "lat": 18.22, "lng": -63.05 }
    ]
  },
  "ATG": {
    "name": "Antigua", "lat": 17.07, "lng": -61.81, "show": false, country: true,
    "cities": [
      { "name": "St. John's", "lat": 17.12, "lng": -61.84 },
      { "name": "All Saints", "lat": 17.06, "lng": -61.79 },
      { "name": "Liberta", "lat": 17.03, "lng": -61.78 },
      // { "name": "Potter's Village", "lat": 17.10, "lng": -61.81 },
      { "name": "Swetes", "lat": 17.05, "lng": -61.79 },
      { "name": "Parham", "lat": 17.10, "lng": -61.76 }
    ]
  },
  "BHS": {
    "name": "Bahamas", "lat": 25.03, "lng": -77.39, "show": true, country: true,
    "cities": [
      { "name": "Nassau" ,"lat": 25.06, "lng": -77.34 },
      { "name": "Freeport" ,"lat": 26.54, "lng": -78.64 },
      { "name": "West End" ,"lat": 26.69, "lng": -78.97 },
      { "name": "Coopers Town" ,"lat": 26.87, "lng": -77.52 },
      // { "name": "Marsh Harbour" ,"lat": 26.55, "lng": -77.05 },
      { "name": "Freetown" ,"lat": 24.77, "lng": -76.27 },
      // { "name": "Bahamas City" ,"lat": 26.63, "lng": -78.28 },
      { "name": "Andros Town" ,"lat": 24.70, "lng": -77.77 },
      { "name": "Clarence Town" ,"lat": 23.10, "lng": -74.98 },
      // { "name": "Dunmore Town" ,"lat": 25.50, "lng": -76.63 },
      // { "name": "Rock Sound" ,"lat": 24.90, "lng": -76.20 },
      // { "name": "Arthur's Town" ,"lat": 24.66, "lng": -75.73 },
      { "name": "Cockburn Town" ,"lat": 24.03, "lng": -74.52 },
      { "name": "George Town" ,"lat": 23.52, "lng": -75.78 }
    ]
  },
  "BRB": {
    "name": "Barbados", "lat": 13.19, "lng": -59.54, "show": true, country: true,
    "cities": [
      { "name": "Bridgetown", "lat": 13.10, "lng": -59.62 },
      { "name": "Speightstown", "lat": 13.25, "lng": -59.64 },
      { "name": "Oistins", "lat": 13.07, "lng": -59.54 },
      // { "name": "Bathsheba", "lat": 13.21, "lng": -59.52 },
      { "name": "Holetown", "lat": 13.18, "lng": -59.63 },
      // { "name": "Crane", "lat": 13.10, "lng": -59.44 },
      { "name": "Crab Hill", "lat": 13.32, "lng": -59.63 },
      // { "name": "Greenland", "lat": 13.25, "lng": -59.57 },
      // { "name": "Blackmans", "lat": 13.18, "lng": -59.54 },
      { "name": "Hillaby", "lat": 13.21, "lng": -59.58 }
    ]
  },
  "BMU": {
    "name": "Bermuda", "lat": 32.30, "lng": -64.75, "show": true, country: true,
    "cities": [
      { "name": "Saint George", "lat": 32.38, "lng": -64.67 },
      { "name": "Hamilton", "lat": 32.29, "lng": -64.78 },
      { "name": "Stovel Bay", "lat": 32.30, "lng": -64.81 },
      { "name": "Cross Bay", "lat": 32.25, "lng": -64.83 }
    ]
  },
  "VGB": {
    "name": "British Virgin Islands", "lat": 18.42, "lng": -64.64, "show": false, country: true,
    "cities": [
      { "name": "Road Town", "lat": 18.42, "lng": -64.62 }
    ]
  },
  "CYM": {
    "name": "Cayman Islands", "lat": 19.31, "lng": -81.25, "show": false, country: true,
    "cities": [
      // { "name": "George Town", "lat": 19.28, "lng": -81.37 },
      { "name": "West Bay", "lat": 19.36, "lng": -81.41 },
      { "name": "Bodden Town", "lat": 19.27, "lng": -81.25 },
      { "name": "East End", "lat": 19.3, "lng": -81.11 },
      { "name": "North Side", "lat": 19.35, "lng": -81.2 }
    ]
  },
  "DMA": {
    "name": "Dominica", "lat": 15.41, "lng": -61.37, "show": true, country: true,
    "cities": [
      { "name": "Roseau", "lat": 15.30, "lng": -61.38 },
      { "name": "Portsmouth", "lat": 15.58, "lng": -61.46 },
      { "name": "Marigot", "lat": 15.53, "lng": -61.3 },
      { "name": "Berekua", "lat": 15.23, "lng": -61.31 },
      { "name": "Mahaut", "lat": 15.36, "lng": -61.39 },
      // { "name": "Saint Joseph", "lat": 15.4, "lng": -61.43 },
      // { "name": "Wesley", "lat": 15.56, "lng": -61.31 },
      // { "name": "Soufrière", "lat": 15.23, "lng": -61.35 },
      // { "name": "Salisbury", "lat": 15.43, "lng": -61.45 },
      { "name": "Castle Bruce", "lat": 15.43, "lng": -61.26 }
    ]
  },
  "DOM": {
    "name": "Dominican Republic", "lat": 18.73, "lng": -70.16, "show": true, country: true,
    "cities": [
      { "name": "Santo Domingo", "lat": 18.5, "lng": -69.98 },
      { "name": "Santiago de los Caballeros", "lat": 19.45, "lng": -70.69 },
      // { "name": "Santo Domingo Oeste", "lat": 18.5, "lng": -70 },
      // { "name": "Santo Domingo Este", "lat": 18.48, "lng": -69.85 },
      // { "name": "San Pedro de Macorís", "lat": 18.46, "lng": -69.29 },
      { "name": "La Romana", "lat": 18.42, "lng": -68.97 },
      // { "name": "San Cristóbal", "lat": 18.41, "lng": -70.1 },
      { "name": "Puerto Plata", "lat": 19.79, "lng": -70.68 },
      { "name": "San Francisco de Macorís", "lat": 19.30, "lng": -70.25 },
      { "name": "Salvaleón de Higüey", "lat": 18.61, "lng": -68.70 }
    ]
  },
  "GRD": {
    "name": "Grenada", "lat": 12.11, "lng": -61.67, "show": true, country: true,
    "cities": [
      { "name": "St. George's", "lat": 12.05, "lng": -61.75 },
      { "name": "Gouyave", "lat": 12.16, "lng": -61.73 },
      { "name": "Grenville", "lat": 12.12, "lng": -61.62 },
      // { "name": "Victoria", "lat": 12.19, "lng": -61.70 },
      // { "name": "Saint David’s", "lat": 12.04, "lng": -61.66 },
      { "name": "Sauteurs", "lat": 12.21, "lng": -61.63 },
      { "name": "Hillsborough", "lat": 12.48, "lng": -61.45 }
    ]
  },
  "JAM": {
    "name": "Jamaica", "lat": 18.10, "lng": -77.29, "show": true, country: true,
    "cities": [
      // { "name": "Kingston", "lat": 17.99, "lng": -76.79 },
      { "name": "Spanish Town", "lat": 17.99, "lng": -76.95 },
      // { "name": "Portmore", "lat": 17.95, "lng": -76.88 },
      { "name": "Montego Bay", "lat": 18.47, "lng": -77.91 },
      { "name": "Mandeville", "lat": 18.04, "lng": -77.50 },
      // { "name": "May Pen", "lat": 17.96, "lng": -77.24 },
      // { "name": "Old Harbour", "lat": 17.94, "lng": -77.10 },
      { "name": "Linstead", "lat": 18.13, "lng": -77.03 },
      // { "name": "Half Way Tree", "lat": 18.01, "lng": -76.79 },
      { "name": "Savanna-la-Mar", "lat": 18.21, "lng": -78.13 }
    ]
  },
  "MSR": {
    "name": "Montserrat", "lat": 16.70, "lng": -62.21, "show": true, country: true,
    "cities": [
      // { "name": "Brades", "lat": 16.79, "lng": -62.21 },
      { "name": "Cork Hill", "lat": 16.71, "lng": -62.21 },
      { "name": "Salem", "lat": 16.75, "lng": -62.22 },
      // { "name": "Saint John's Village", "lat": 16.78, "lng": -62.19 },
      { "name": "Gerald's", "lat": 16.79, "lng": -62.19 }
    ]
  },
  "SXM": {
    "name": "Sint Maarten", "lat": 18.04, "lng": -63.05, "show": false, country: true,
    "cities": [
      // { "name": "Cul de Sac", "lat": 18.04, "lng": -63.06 },
      { "name": "Lower Prince’s Quarter", "lat": 18.05, "lng": -63.03 },
      // { "name": "Koolbaai", "lat": 18.04, "lng": -63.08 },
      { "name": "Philipsburg", "lat": 18.02, "lng": -63.04 }
      // { "name": "Simson Bay Village", "lat": 18.03, "lng": -63.11 }
    ]
  },
  "KNA": {
    "name": "St. Kitts & Nevis", "lat": 17.35, "lng": -62.78, "show": false, country: true,
    "cities": [
      { "name": "Basseterre", "lat": 17.29, "lng": -62.72 },
      { "name": "Fig Tree", "lat": 17.12, "lng": -62.60 },
      // { "name": "Market Shop", "lat": 17.13, "lng": -62.57 },
      { "name": "Saint Paul’s", "lat": 17.40, "lng": -62.83 },
      { "name": "Middle Island", "lat": 17.32, "lng": -62.81 }
      // { "name": "Nicola Town", "lat": 17.38, "lng": -62.75 },
      // { "name": "Trinity", "lat": 17.3, "lng": -62.77 },
      // { "name": "Charlestown", "lat": 17.13, "lng": -62.61 },
      // { "name": "Sadlers", "lat": 17.40, "lng": -62.79 },
      // { "name": "Cayon", "lat": 17.35, "lng": -62.73 }
    ]
  },
  "LCA": {
    "name": "St. Lucia", "lat": 13.90, "lng": -60.97, "show": false, country: true,
    "cities": [
      { "name": "Castries", "lat": 13.99, "lng": -61.00 },
      // { "name": "Bisée", "lat": 14.02, "lng": -60.97 },
      { "name": "Vieux Fort", "lat": 13.71, "lng": -60.95 },
      { "name": "Micoud", "lat": 13.81, "lng": -60.9 },
      // { "name": "Soufrière", "lat": 13.85, "lng": -61.05 },
      // { "name": "Dennery", "lat": 13.9, "lng": -60.88 },
      { "name": "Gros Islet", "lat": 14.06, "lng": -60.95 },
      // { "name": "Praslin", "lat": 13.87, "lng": -60.89 },
      { "name": "Canaries", "lat": 13.90, "lng": -61.06 }
      // { "name": "Anse La Raye", "lat": 13.94, "lng": -61.03 }
    ]
  },
  "VCT": {
    "name": "St. Vincent & Grenadines", "lat": 12.98, "lng": -61.28, "show": false, country: true,
    "cities": [
      { "name": "Kingstown", "lat": 13.15, "lng": -61.22 },
      { "name": "Georgetown", "lat": 13.28, "lng": -61.11 },
      // { "name": "Byera Village", "lat": 13.25, "lng": -61.12 },
      // { "name": "Biabou", "lat": 13.19, "lng": -61.13 },
      // { "name": "Barrouallie", "lat": 13.23, "lng": -61.27 },
      { "name": "Port Elizabeth", "lat": 13.01, "lng": -61.23 },
      // { "name": "Chateaubelair", "lat": 13.29, "lng": -61.24 },
      { "name": "Dovers", "lat": 12.89, "lng": -61.18 },
      { "name": "Layou", "lat": 13.20, "lng": -61.27 }
    ]
  },
  "TTO": {
    "name": "Trinidad & Tobago", "lat": 10.69, "lng": -61.22, "show": true, country: true,
    "cities": [
      { "name": "Chaguanas", "lat": 10.51, "lng": -61.41 },
      { "name": "Mon Repos", "lat": 10.28, "lng": -61.44 },
      // { "name": "San Fernando", "lat": 10.28, "lng": -61.46 },
      { "name": "Port of Spain", "lat": 10.66, "lng": -61.51 },
      // { "name": "Rio Claro", "lat": 10.30, "lng": -61.17 },
      // { "name": "Arima", "lat": 10.63, "lng": -61.28 },
      // { "name": "Marabella", "lat": 10.30, "lng": -61.44 },
      // { "name": "Laventille", "lat": 10.64, "lng": -61.49 },
      { "name": "Point Fortin", "lat": 10.17, "lng": -61.68 }
      // { "name": "Tunapuna", "lat": 10.65, "lng": -61.38 }
    ]
  },
  "TCA": {
    "name": "Turks & Caicos Islands", "lat": 21.69, "lng": -71.79, "show": false, country: true,
    "cities": [
      { "name": "Cockburn Town", "lat": 21.46, "lng": -71.14 },
      { "name": "Cockburn Harbour", "lat": 21.49, "lng": -71.53 }
    ]
  }
};