var validators = require('../../utils/validators');

module.exports = function () {
  $('#resetForm').bootstrapValidator({
    fields: {
      password: validators.IdenticalPassword,
      password2: validators.IdenticalConfirmPassword
    }
  });
};
