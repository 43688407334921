var weForm = require('../utils/weForm');
var YellowPicClient;
function getYellowPicDataKey() {
  return '626c7565736e6170'.dexify();
}

function addYellowPicDataAttributes($form) {
  var yellowPicAttrKey = 'data-' + getYellowPicDataKey();

  $('#inputCardNumber', $form).attr(yellowPicAttrKey, 'encryptedCreditCard');
  $('#inputCvvNumber', $form).attr(yellowPicAttrKey, 'encryptedCvv');
}

function resetYellowPicForm($form) {
  var yellowPicAttrKey = 'data-' + getYellowPicDataKey();

  $('input[name="encryptedCreditCard"]', $form).remove();
  $('input[name="encryptedCvv"]', $form).remove();
  $('input[name="ccLast4Digits"]', $form).remove();

  $('#inputCardNumber', $form).attr('name', 'ccnumber').removeAttr(yellowPicAttrKey);
  $('#inputCvvNumber', $form).attr('name', 'cvv').removeAttr(yellowPicAttrKey);
}

function getYellowPicNonce($form, callback) {
  addYellowPicDataAttributes($form);
  try {
    YellowPicClient.encrypt($form.attr('id'));
  } catch (e) {
    return callback(e);
  }

  var yellowPicNonceJson = {
    month: weForm.dropdownValue($form, 'month'),
    year: weForm.dropdownValue($form, 'year'),
    encryptedCC: weForm.inputValue($form, 'encryptedCreditCard'),
    encryptedCvv: weForm.inputValue($form, 'encryptedCvv')
  };

  resetYellowPicForm($form);
  callback(null, JSON.stringify(yellowPicNonceJson));
}

function getNonce($form, clientToken, callback) {
  maybeLoadYellowPicJs(clientToken, function () {
    getYellowPicNonce($form, callback);
  }, callback);
}

function maybeLoadYellowPicJs(clientToken, successCallback, errorCallback) {
  if(YellowPicClient) {
    return successCallback();
  }

  var dataKey = getYellowPicDataKey();
  var jsUrl = 'https://gateway.' + dataKey + '.com/js/cse/v1.0.4/' + dataKey + '.js';

  $.getScript(jsUrl).done(function () {
    YellowPicClient = new window['426c7565536e6170'.dexify()](clientToken);
    successCallback();
  }).fail(function () {
    errorCallback({message: 'YellowPic Load Error'});
  });
}

module.exports = {
  getNonce: getNonce
};