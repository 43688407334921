var validators = require('../utils/validators');
var weAjax = require('../utils/weAjax');
var weForm = require('../utils/weForm');
var purchaseFormHandler = require('../modules/purchaseForm');

function isAnyBootstrapModalIsOpen() {
  return $('.modal.in').length > 0;
}

function getCookieNameForSignUpType(upType) {
  if (upType === 'email') {
    return '_self_email_popup';
  }
  if (upType === 'phone') {
    return '_self_phone_popup';
  }
}

function setupUpPopupEvents(upPopup, hasPlan) {
  upPopup.on('hidden.bs.modal', function () {
    WE.events.push('emailPrompt', 'D', {inp_state: 'dismissed', hasPlan: hasPlan}, false);
  });
  upPopup.on('shown.bs.modal', function () {
    WE.events.push('emailPrompt', 'O', {inp_state: 'open', hasPlan: hasPlan}, false);
  });
}

function showSignUpPopup(signUpType, signUpPopup) {
  $('[data-toggle="tooltip"]').tooltip('hide');
  signUpPopup.modal('show');

  var cookieName = getCookieNameForSignUpType(signUpType);
  $.cookie(cookieName + '.shown', 'true', {path: '/', expires: 365});
}

function initEmailSignupPopup() {
  var emailPopup = $('#modalEmailSignUp');
  if (!emailPopup.get(0)) {
    return;
  }
  var hasPlan = $('#eSignupHasPlan').val();
  $('#upgrade').bootstrapValidator({
    live: 'enabled',
    fields: {
      acceptTerms: validators.CheckBoxValidator
    }
  });
  setupUpPopupEvents(emailPopup, hasPlan);
}

function showEmailSignupPopup(email) {
  var emailPopup = $('#modalEmailSignUp');
  if (!emailPopup.get(0)) {
    return;
  }
  if (isAnyBootstrapModalIsOpen()) {
    return setTimeout(showEmailSignupPopup, 2 * 1000);
  }
  if (!email) {
    email = $('#eSignupUserEmail').val();
  }
  $('#emailSignupHeader').html(email);
  $('input[name="payload"]', emailPopup).val(email);
  showSignUpPopup('email', emailPopup);
}

function getPurchaseData($form) {
  var cc = weForm.inputValue($form, 'ccnumber').replaceAll(' ', '');
  var payloadType = weForm.inputValue($form, 'payloadType');
  var payload = weForm.inputValue($form, 'payload');
  var source = weForm.inputValue($form, 'clickSource');
  var deriveFromPayload = weForm.inputValue($form, 'deriveFromPayload');
  
  var serverData = {
    medium: weForm.inputValue($form, 'medium'),
    plan: weForm.inputValue($form, 'plan'),
    clickSource: source,
    bgReport: weForm.inputValue($form, 'bgReport'),
    bin: cc.slice(0, 6),
    last4: cc.slice(-4),
    type: payloadType
  };

  if(payloadType === 'phone') {
    serverData.phoneno = payload;
    serverData.deriveFromPayload = deriveFromPayload;
  }
  if(payloadType === 'email') {
    serverData.email = payload;
    serverData.deriveFromPayload = deriveFromPayload;
  }
  if(payloadType === 'name') {
    serverData.name = payload;
    serverData.phoneno = deriveFromPayload;
  }

  return serverData;
}

function getReportData(ele, payloadType) {
  var serverData = {
    medium: ele.attr('medium'),
    plan: ele.attr('plan'),
    bgReport: ele.attr('bgReport'),
    type: payloadType
  };

  if(payloadType === 'phone') {
    serverData.phoneno = ele.attr('payload');
  }
  if(payloadType === 'email') {
    serverData.email = ele.attr('payload');
  }
  if(payloadType === 'name') {
    serverData.name = ele.attr('payload');
    serverData.phoneno = ele.attr('deriveFromPayload');
    serverData.deriveFromPayload = ele.attr('deriveFromPayload');
  }

  return serverData;
}

function getLinkedNameReportData($form) {
  var serverData = {
    medium: weForm.inputValue($form, 'medium'),
    plan: weForm.inputValue($form, 'plan'),
    bgReport: weForm.inputValue($form, 'bgReport'),
    name: weForm.inputValue($form, 'payload'),
    phoneno: weForm.inputValue($form, 'phoneno'),
    type: weForm.inputValue($form, 'payloadType'),
    clickSource: weForm.inputValue($form, 'clickSource'),
    deriveFromPayload: weForm.inputValue($form, 'phoneno')
  };

  return serverData;
}

function isServiceErr(jqXhr) {
  var responseObject = jqXhr.responseJSON;
  return responseObject && responseObject.weRouteMessage && responseObject.weRouteMessage.action === 'serviceErr';
}

function getReport(ele, reportUrl, serverData) {
  ele.attr('disabled', true);

  weAjax.post(reportUrl, serverData, function () {

    ele.attr('disabled', false);
    reportSuccess(serverData);

  }, function (jqXhr) {

    ele.attr('disabled', false);
    reportFailure(serverData, isServiceErr(jqXhr));

  });
}

function reportFailure(serverData, serviceErr) {
  $('#backgroundReportPopup').modal('hide');
  $('#pdfReportMailPopup').modal('hide');
  $('#linkedPayloadNameReportPopup').modal('hide');
  $('#modalEmailSignUp').modal('hide');

  if(serviceErr === true) {
    $('#purchasePDFReportError').modal('show');
    return;
  }

  $('#purchaseReportAddCCPopup').modal('show');
  $('#reportMedium').val(serverData.medium);
  $('#clickSource').val(serverData.clickSource);
  $('#reportPlan').val(serverData.plan);
  $('#isBgReport').val(serverData.bgReport);
  $('#payloadType').val(serverData.type);
  $('#deriveFromPayload').val(serverData.deriveFromPayload || '');

  if(serverData.type === 'phone') {
    $('#inputPayload').val(serverData.phoneno);
  }
  if(serverData.type === 'email') {
    $('#inputPayload').val(serverData.email);
  }
  if(serverData.type === 'name') {
    $('#inputPayload').val(serverData.name);
  }
}

function reportSuccess(serverData) {
  $('#purchaseReportAddCCPopup').modal('hide');
  $('#pdfReportMailPopup').modal('hide');
  $('#backgroundReportPopup').modal('hide');
  $('#linkedPayloadNameReportPopup').modal('hide');
  $('#modalEmailSignUp').modal('hide');
  if (serverData.medium === 'email') {
    $('#bg-report-pdf-success').removeClass('hidden');
  }
}

function addCCFailure(responseObject, bvForm, bannedBinError) {
  var popup = $('#purchaseReportAddCCPopup');
  $('.text-danger', popup).hide();
  $('.error', popup).text('The card you entered cannot be used for this payment. Please provide a different credit or debit card number.').removeClass('hidden');
}

function getReportUrl(serverData) {
  if (serverData.type === 'phone') {
    return window.phoneReportURL;
  }
  if (serverData.type === 'email') {
    return window.emailReportURL;
  }
  if (serverData.type === 'name') {
    return window.nameReportURL;
  }
}

function setupSignUpPopupForUser(signUpType, initPopupHandler, showPopupHandler) {
  var cookieName = getCookieNameForSignUpType(signUpType);

  $('document').ready(function () {
    var timeout = parseInt(window.eUpPopupInterval || (10 * 1000));
    if (window.isDevMode) {
      timeout = parseInt($.cookie(cookieName + '.immediate') || (20 * 1000));
    }
    if ($.cookie(cookieName + '.shown') !== 'true') {
      setTimeout(showPopupHandler, timeout);
    }
    initPopupHandler();
  });
}

module.exports = function (payloadType) {
  var fields = {
    name: validators.CardHolderNameValidator,
    ccnumber: validators.CCVAlidator,
    month: validators.MonthValidator,
    cvv: validators.CVVValidator,
    year: validators.YearValidator
  };

  var addCCButton = $('#addCCButton');
  var purchaseForm = purchaseFormHandler('#addCCPaymentForm', '#addCCButton', fields, getReportUrl, getPurchaseData, reportSuccess, addCCFailure);

  addCCButton.click(function () {
    var bvForm = purchaseForm.data('bootstrapValidator');
    if (bvForm.isValid() !== true) {
      bvForm.validate();
    } else {
      purchaseForm.trigger('success.form.bv');
    }
    return false;
  });

  $('[data-toggle="tooltip"]').tooltip();

  $('.linked-basic-payloads').tooltip('show');
  $('.premium-report').click(function () {
    $('.linked-basic-payloads').tooltip('hide');
    $('.linked-name-payloads').tooltip('hide');
  });

  // ========== Email Click Handling ============= //
  $('.linked-basic-payloads a.weTrack').click(function () {
    var payload = $(this).attr('data-payload');
    var type = $(this).attr('data-type');
    var source = $(this).attr('click-source');
    showEmailSignupPopup(payload);
  });

  // ========== Name Click Handling ============= //
  var linkedPayloadNamePopup = $('#linkedPayloadNameReportPopup');
  var linkedPayloadNameHeader = $('#linkedPayloadNameHeader');
  var linkedPayloadNameForm = $('#linkedPayloadNameReportForm');

  $('.linked-name-payloads a').click(function () {
    var payload = $(this).attr('data-payload');
    var source = $(this).attr('click-source');
    linkedPayloadNameHeader.html(payload);
    linkedPayloadNamePopup.modal('show');
    $('input[name="clickSource"]', linkedPayloadNameForm).val(source);
    $('input[name="payload"]', linkedPayloadNameForm).val(payload);
    $('.buy-report-button', linkedPayloadNamePopup).attr('data-value', source);
  });

  $('.buy-report-button', linkedPayloadNamePopup).click(function () {
    var serverData = getLinkedNameReportData(linkedPayloadNameForm);
    getReport($(this), getReportUrl(serverData), serverData);
  });

  // ========== Bg/PDF Report Purchase Popup Button Handling ============= //
  var backgroundReportPopup = $('#backgroundReportPopup');
  var pdfReportMailPopup = $('#pdfReportMailPopup');

  $('.buy-report-button', backgroundReportPopup).click(function () {
    var serverData = getReportData($(this), payloadType);
    getReport($(this), getReportUrl(serverData), serverData);
  });
  $('.buy-report-button', pdfReportMailPopup).click(function () {
    var serverData = getReportData($(this), payloadType);
    getReport($(this), getReportUrl(serverData), serverData);
  });

  // ========== Up Popup Handling ============= //
  setupSignUpPopupForUser('email', initEmailSignupPopup, showEmailSignupPopup);
};