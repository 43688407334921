var validators = require('../utils/validators');
var weAjax = require('../utils/weAjax');
var weForm = require('../utils/weForm');

module.exports = function () {
  var formSelector = '#passwordResetForm';
  var payoutForm = weForm.getWEForm(formSelector);
  payoutForm.bootstrapValidator({
    fields: {
      password: validators.IdenticalPassword,
      password2: validators.IdenticalConfirmPassword
    }
  }).on('success.form.bv',
    function(e) {

      e.preventDefault();
      var $form = $(e.target);
      var serverData = {
        password: weForm.inputValue($form, 'password')
      };

      weAjax.post(
        $form.attr('action'),
        serverData,
        function () {
          location.reload();
        },
        function () {
          $(formSelector + ' button[type="submit"]').prop('disabled', false);
        }
      );
    }
  );
  validators.enableLazyValidation($(formSelector));
};
