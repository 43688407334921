var validators = require('../utils/validators');
var weForm = require('../utils/weForm');
var purchaseFormHandler = require('../modules/purchaseForm');

function getPurchaseData($form) {
  var cc = weForm.inputValue($form, 'ccnumber').replaceAll(' ', '');

  var serverData = {
    name: weForm.inputValue($form, 'name'),
    email: weForm.inputValue($form, 'email'),
    plan: weForm.inputValue($form, 'plan'),
    payload: weForm.inputValue($form, 'payload'),
    bin: cc.slice(0, 6),
    last4: cc.slice(-4)
  };

  return serverData;
}

function showPurchaseErrModalAndScroll(modalId, elementToFocus) {
  var errorModal = $(modalId);
  var checkoutFormOffset = $('.secure-checkout').offset() || { top: 275 };

  $('form .error').addClass('hidden');

  errorModal.modal({ show: true });
  errorModal.on('hidden.bs.modal', function () {
    $('body').scrollTop(checkoutFormOffset.top);
    $(elementToFocus).focus();
  });
}

function handlePurchaseSuccess(purchaseData) {
}

function handlePurchaseError(responseObject, bvForm, tryAnotherCard) {
  if(tryAnotherCard) {
    showPurchaseErrModalAndScroll('#tryAnotherCardModal', '#inputCardNumber');
  }
}

function initPurchaseForm(purchaseUrl) {
  var fields = {
    email: validators.EmailValidator,
    name: validators.CardHolderNameValidator,
    ccnumber: validators.CCVAlidator,
    month: validators.MonthValidator,
    cvv: validators.CVVValidator,
    acceptTerms: validators.CheckBoxValidator,
    year: validators.YearValidator
  };

  var purchaseButton = $('button[name="purchaseButton"]');
  var purchaseForm = purchaseFormHandler('#paymentForm', purchaseButton, fields, purchaseUrl, getPurchaseData, handlePurchaseSuccess, handlePurchaseError);

  purchaseForm.submit(function() {
    var bvForm = purchaseForm.data('bootstrapValidator');

    if (bvForm.isValid() !== true) {
      bvForm.validate();
    }

    var fields = bvForm.getInvalidFields();
    var errors = [];
    $.each(fields, function (index, ele) {
      var $ele = $(ele);
      var name = $ele.attr('name');
      var error = $('[data-bv-for=' + name + ']').filter(':visible').text();
      errors.push(error);
    });
    WE.events.push('purchaseButton', 'C', {inp_state: 'clicked', errors: errors.join(',') || 'none'}, false);
    $('form .error').addClass('hidden');

    if (bvForm.isValid() === true) {
      purchaseForm.trigger('success.form.bv')
    }

    return false;
  });

  purchaseButton.prop('type', 'submit');
}

module.exports = function (purchaseUrl) {
  var pForm = $('#paymentForm');
  var pButton = $('button[name="purchaseButton"]');
  var eMessage = 'DONE';
  try {
    initPurchaseForm(purchaseUrl);
  } catch(e) {
    eMessage = (e.message || 'Error initializing form');
  }
  WE.events.push('purchaseForm', 'I', {inp_state: 'init', inp_value: eMessage, btn_type: pButton.prop('type'), bv_init: pForm.data('bootstrapValidator') ? true : false}, false);
};
