function post(url, method, data, successHandler, errorHandler) {

  var onSuccess = function(data, textStatus, jqXhr) {
    if (data.redirect) {
      window.location = data.redirectURL;
    } else if(data.error === true ) {
      return onFailure(jqXhr, textStatus, data);
    } else if (successHandler) {
      return successHandler(data);
    }
  };

  var onFailure = function(jqXhr, textStatus, errorThrown) {
    var responseObject = jqXhr.responseJSON;
    if (responseObject && responseObject.weRouteMessage && responseObject.weRouteMessage.message) {
      $('form .error').text(responseObject.weRouteMessage.message);
      $('form .error').removeClass('hidden');
    }
    if (errorHandler) {
      return errorHandler(jqXhr, textStatus, errorThrown);
    }
  };

  $.ajax({ type: method, url: url, data: data }).done(onSuccess, 'json').fail(onFailure);
}

function getPaymentProvider(serverData, successCallback, errorCallback) {
  var handler = function (data) {
    if(data && data.success === true) {
      return successCallback(data.provider, data.paymentWebToken);
    }
    WE.events.push('provider', 'PPBIN', { inp_state: 'SEVERE_ERR', inp_value: (data && data.err) || 'EMPTY' }, false);
    if(errorCallback) {
      errorCallback();
    }
  };
  post(window.ppSwitchURL, 'POST', serverData, handler, function () {
    post(window.ppSwitchURL, 'POST', serverData, handler, function (jqXhr, status) {
      handler({ err: status });
    });
  });
}

module.exports = {
  post: function (url, data, successHandler, errorHandler) {
    post(url, 'POST', data, successHandler, errorHandler);
  },
  get: function (url, data, successHandler, errorHandler) {
    post(url, 'GET', data, successHandler, errorHandler);
  },
  getPaymentProvider: getPaymentProvider
};