var validators = require('../utils/validators');
var weForm = require('../utils/weForm');
var weAjax = require('../utils/weAjax');

function getContactFormData(contactForm) {
  return {
    reason: weForm.dropdownValue(contactForm, 'reason'),
    name: weForm.inputValue(contactForm, 'name'),
    email: weForm.inputValue(contactForm, 'email'),
    phone: weForm.inputValue(contactForm, 'phone'),
    feedback: weForm.textareaValue(contactForm, 'feedback'),
    'g-recaptcha-response': weForm.textareaValue(contactForm, 'g-recaptcha-response')
  }
}

module.exports = function () {
  var submitInProgress = false;
  var contactForm = $('#contactForm');
  var contactButton = $('#contactButton');
  var contactError = $('.contact-error');

  contactForm.bootstrapValidator({
    fields: {
      reason: validators.DropdownValidator,
      email: validators.EmailValidator,
      name: validators.NameValidator,
      feedback: validators.StringValidator(5, 500)
    }
  });

  contactForm.on('success.form.bv', function(e) {
    if(submitInProgress) {
      return;
    }

    contactButton.attr('disabled', true);
    WE.events.push('contactForm', 'S', { inp_state: 'submit' }, false);

    submitInProgress = true;
    e.preventDefault();

    contactError.addClass('hidden');

    weAjax.post('/contact', getContactFormData(contactForm), function () {
      $('.contact-done').removeClass('hidden');
      $('.contact-form').addClass('hidden');
    }, function () {
      submitInProgress = false;
      contactButton.attr('disabled', false);
      contactError.removeClass('hidden');
      if (window.grecaptcha) {
        window.grecaptcha.reset();
      }
    });

  });

  contactForm.submit(function() {
    var bvForm = contactForm.data('bootstrapValidator');

    if (bvForm.isValid() !== true) {
      bvForm.validate();
    }

    var fields = bvForm.getInvalidFields();
    var errors = [];
    $.each(fields, function (index, ele) {
      var $ele = $(ele);
      var name = $ele.attr('name');
      var error = $('[data-bv-for=' + name + ']').filter(':visible').html();
      errors.push(error);
    });
    WE.events.push('contactFormButton', 'C', {inp_state: 'clicked', errors: errors.join(',') || 'none'}, false);
    contactError.addClass('hidden');

    if (bvForm.isValid() === true) {
      contactForm.trigger('success.form.bv')
    }

    return false;
  });

  validators.enableLazyValidation(contactForm);

};