var validators = require('../../utils/validators');

module.exports = function () {
  $('#loginForm').bootstrapValidator({
    fields: {
      email: validators.EmailValidator,
      password: validators.PasswordValidator
    }
  });
  validators.enableLazyValidation($('#loginForm'));
};
