/* eslint-disable no-useless-escape, no-extend-native */
/* globals wePageModuleName, wePageDataKey, wePageDataContent */

String.prototype.format = function() {
  var s = this, i = arguments.length;
  while (i--) {
    s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), arguments[i]);
  }
  return s;
};

String.prototype.dexify = function() {
  return this.match(/.{1,2}/g).map(function(v){
    return String.fromCharCode(parseInt(v, 16));
  }).join('');
};

String.prototype.replaceAll = function(search, replacement) {
  var s = this;
  return s.replace(new RegExp(search, 'g'), replacement);
};

// http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
String.prototype.isEmail = function() {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(this);
};

String.prototype.decipher = function (salt) {
  var subMap = {};
  var subArray = salt.split('').map(function(v, i) { return [String.fromCharCode(97 + i), v]; });
  subArray.forEach(function (entry) {
    subMap[entry[1]] = entry[0];
  });
  return this.split('').map(function(v) {
    return subMap[v] || (subMap[v.toLowerCase()] ? subMap[v.toLowerCase()].toUpperCase() : v);
  }).join('');
};

if (wePageDataContent) {
  var userFacingUrls = wePageDataContent.decipher(wePageDataKey).split(';');
  userFacingUrls.forEach(function (entry) {
    var parts = entry.split(',');
    window[parts[0]] = parts[1];
  });
}

window.WE = {
  events: []
};

window.pageErrors = [];

if (window.console && window.console.error) {
  var oldErrFn = console.error;
  console.error = function (message) {
    pageErrors.push(message);
    oldErrFn.apply(console, arguments);
  }
}

try {
  $.cookie(DomainCookieBJ, 'w=' + $(window).width() + '&h=' + $(window).height(), {path: '/', secure: true, sameSite: 'none'});
} catch(e) {
}

var modules = require('./listing');

function callMyModule (modulePath) {
  require('./external/bootstrapValidator.min');
  require('./external/intltelinput.min');
  require('./modules/phoneAndEmailForm')();
  require('./utils/events');
  var moduleFinalPath = './pages' + modulePath;
  var moduleFunction = modules[moduleFinalPath];
  if (typeof moduleFunction === 'function') { moduleFunction(); }
}

callMyModule(wePageModuleName);