var validators = require('./validators');

function dropdownValue($form, name) {
  return $form.find("select[name=" + name + "] option:selected").val();
}

function inputValue($form, name) {
  return $form.find("input[name=" + name + "]").val();
}

function textareaValue($form, name) {
  return $form.find("textarea[name=" + name + "]").val();
}

function radioValue($form, name) {
  return $form.find("input[name=" + name + "]:checked").val();
}
 
function getWEForm(selector) {
  validators.enableLazyValidation($(selector));
  return $(selector);
}

module.exports = {
  textareaValue: textareaValue,
  dropdownValue: dropdownValue,
  inputValue: inputValue,
  radioValue: radioValue,
  getWEForm: getWEForm
};