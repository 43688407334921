var weForm = require('../utils/weForm');
var weAjax = require('../utils/weAjax');
var yellowpic = require('../helpers/yellowpic');

function resetCCDetails(bvForm) {
  $('#inputCardNumber').val('');
  $('#inputCvvNumber').val('');
  $('#selectMonth').val('');
  $('#selectYear').val('');
  bvForm.resetField('ccnumber');
  bvForm.resetField('month');
  bvForm.resetField('year');
  bvForm.resetField('cvv');
}

function isTryAnotherAction(jqXhr) {
  var responseObject = jqXhr.responseJSON;
  return responseObject && responseObject.weRouteMessage && responseObject.weRouteMessage.action === 'tryAnother';
}

function sendNonceRespEvent(err, providerAbbr) {
  WE.events.push('nonce', providerAbbr, {
    inp_state: err ? 'ERR' : 'VALID',
    inp_value: err ? (err.message || err.weRouteMessage || 'Error generating NONCE') : 'EMPTY'
  }, false);
}

function sendNonceReqEvent(providerAbbr) {
  WE.events.push('nonce_REQ', providerAbbr, {
    inp_state: 'REQ',
    inp_value: 'EMPTY'
  }, false);
}

module.exports = function (selector, button, fields, submitUrl, purchaseDataRetriever, onSuccess, onError) {
  var submitInProgress = false;
  var purchaseForm = weForm.getWEForm(selector);
  var purchaseFormButton = $(button);

  purchaseForm.on('status.field.bv', '[name="ccnumber"]', function(e, data) {
    var value = data.element.val();
    if(!value.match(/^(\d{4}\s)+\d{1,4}$/g)) {
      var groups = value.replace(/\D/g,'').match(/.{1,4}/g);
      if(groups) {
        var cc = value.replace(/\D/g,'').match(/.{1,4}/g).join(' ');
        data.element.val(cc);
      }
    }
  });

  purchaseForm.on('error.field.bv', function(e, data) {
    if(data.field === 'year' || data.field === 'month') {
      $('.cc-expiry [data-bv-result="INVALID"]').hide();
      $('.cc-expiry [data-bv-for="' + data.field + '"][data-bv-result="INVALID"]').show();
    }
    if(data.field === 'name') {
      var bvErrors = $('[data-bv-for="name"]');
      var visible = bvErrors.filter(':visible');
      if(visible.length > 1) {
        var firstError = bvErrors.filter(':visible').first();
        visible.hide();
        firstError.show();
      }
    }
  });

  purchaseForm.bootstrapValidator({
    fields: fields
  });
  
  function doneSubmit() {
    submitInProgress = false;
    purchaseFormButton.attr('disabled', false);
  }

  function sendPurchaseRequest(actionUrl, purchaseData) {
    weAjax.post(actionUrl, purchaseData, function () {
      doneSubmit();
      onSuccess(purchaseData);
    }, function (jqXhr) {
      doneSubmit();
      var tryAnotherCard = isTryAnotherAction(jqXhr);
      var bvForm = purchaseForm.data('bootstrapValidator');
      if(tryAnotherCard) {
        resetCCDetails(bvForm);
      }
      onError(jqXhr.responseJSON, bvForm, tryAnotherCard);
    });
  }

  purchaseForm.on('success.form.bv', function(e) {
    if(submitInProgress) { return; }

    WE.events.push('purchaseForm', 'S', { inp_state: 'submit' }, false);

    submitInProgress = true;
    e.preventDefault();

    purchaseFormButton.attr('disabled', true);
    $('form .error').addClass('hidden');

    var purchaseData = purchaseDataRetriever(purchaseForm);
    var actionUrl = (typeof submitUrl === 'function') ? submitUrl(purchaseData) : submitUrl;

    weAjax.getPaymentProvider(purchaseData, function (provider, paymentWebToken) {
      sendNonceReqEvent('BS');
      yellowpic.getNonce(purchaseForm, paymentWebToken, function (err, nonce) {
        sendNonceRespEvent(err, 'BS');
        if(err) {
          return doneSubmit();
        }
        purchaseData.provider = provider;
        purchaseData.payment_method_nonce = nonce;
        sendPurchaseRequest(actionUrl, purchaseData);
      });
    }, doneSubmit);

  });

  $('#purchaseTooltipWhatThis').tooltip();

  return purchaseForm;
};