var validators = require('../utils/validators');
var weForm = require('../utils/weForm');
var weAjax = require('../utils/weAjax');

function getFeedbackFormData(feedbackForm) {
  return {
    name: weForm.inputValue(feedbackForm, 'name'),
    email: weForm.inputValue(feedbackForm, 'email'),
    feedback: weForm.textareaValue(feedbackForm, 'feedback'),
    'g-recaptcha-response': weForm.textareaValue(feedbackForm, 'g-recaptcha-response')
  }
}

module.exports = function () {
  var submitInProgress = false;
  var feedbackForm = $('#feedbackForm');
  var feedbackButton = $('#feedbackButton');
  var feedbackError = $('.feedback-error');

  feedbackForm.bootstrapValidator({
    fields: {
      reason: validators.DropdownValidator,
      email: validators.EmailValidator,
      name: validators.NameValidator,
      feedback: validators.StringValidator(5, 500)
    }
  });

  feedbackForm.on('success.form.bv', function(e) {
    if(submitInProgress) {
      return;
    }

    feedbackButton.attr('disabled', true);
    WE.events.push('feedbackForm', 'S', { inp_state: 'submit' }, false);

    submitInProgress = true;
    e.preventDefault();

    feedbackError.addClass('hidden');

    weAjax.post('/userfeedback', getFeedbackFormData(feedbackForm), function () {
      $('.feedback-done').removeClass('hidden');
      $('.feedback-form').addClass('hidden');
    }, function () {
      submitInProgress = false;
      feedbackButton.attr('disabled', false);
      feedbackError.removeClass('hidden');
      if (window.grecaptcha) {
        window.grecaptcha.reset();
      }
    });

  });

  feedbackForm.submit(function() {
    var bvForm = feedbackForm.data('bootstrapValidator');

    if (bvForm.isValid() !== true) {
      bvForm.validate();
    }

    var fields = bvForm.getInvalidFields();
    var errors = [];
    $.each(fields, function (index, ele) {
      var $ele = $(ele);
      var name = $ele.attr('name');
      var error = $('[data-bv-for=' + name + ']').filter(':visible').html();
      errors.push(error);
    });
    WE.events.push('feedbackFormButton', 'C', {inp_state: 'clicked', errors: errors.join(',') || 'none'}, false);
    feedbackError.addClass('hidden');

    if (bvForm.isValid() === true) {
      feedbackForm.trigger('success.form.bv')
    }

    return false;
  });

  validators.enableLazyValidation(feedbackForm);

};