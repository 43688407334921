/* eslint-disable no-useless-escape */

function enableLazyValidation($form) {
  $form.on('error.field.bv', function(e, data) {
    if($(data.element).is(':focus') && !$(data.element).attr('show_errors')) {
        data.bv.updateStatus(data.field, 'NOT_VALIDATED');
    }
    if(!$(data.element).is(':focus')) {
      $(data.element).attr('show_errors', true);
    }
  });
}

function validateMonthYear(month, year) {
  var currentMonth = new Date().getMonth() + 1, currentYear = new Date().getFullYear();
  if (month === '' || year === '') {
    return false;
  }
  month = parseInt(month);
  if (month < 0 || month > 12) {
    return false;
  }

  if (year < currentYear || year > currentYear + 100) {
    return false;
  }

  year = parseInt(year);
  if (year > currentYear || (year === currentYear && month >= currentMonth)) {
    return true;
  } else {
    return false;
  }
}

var BasicEmailValidationRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
var AdvancedEmailValidationRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

var CardHolderNameRegex = /^((?!((\d\s*){5,})).)*$/;

module.exports = {
  emailValidationRegex: BasicEmailValidationRegex,
  NameValidator: {
    message: 'Please enter your full name',
    trigger: 'blur',
    validators: {
      notEmpty: {
        message: 'Please enter your full name'
      },
      stringLength: {
        min: 4,
        max: 100,
        message: 'The full name must be between 4 and 100 characters'
      }
    }
  },
  CardHolderNameValidator: {
    verbose: false,
    message: 'Please enter the full cardholder name',
    trigger: 'keyup blur',
    validators: {
      notEmpty: {
        message: 'Please enter the full cardholder name'
      },
      regexp: {
        regexp: CardHolderNameRegex,
        message: 'Name should not contain more than 5 consecutive digits'
      },
      stringLength: {
        min: 4,
        max: 100,
        message: 'The full name must be between 4 and 100 characters'
      }
    }
  },
  EmailValidator: {
    trigger: 'keyup blur',
    validators: {
      notEmpty: {
        message: 'Please enter a valid email address'
      },
      regexp: {
        regexp: BasicEmailValidationRegex,
        message: 'Please enter a valid email address'
      }
    }
  },
  PhoneValidator: {
    trigger: 'keyup blur',
    validators: {
      callback: {
        message: 'Please enter a valid phone number',
        callback: function(phone) {
          phone = $.trim(phone);
          phone = phone.replace(/\+\s*1|\s|\(|\)|-/g, '');
          var isValid = (/^\d{10}$/.test(phone));
          return isValid;
        }
      }
    }
  },
  EmailAjaxValidator: {
    verbose: false,
    trigger: 'keyup blur',
    validators: {
      notEmpty: {
        message: 'Please enter a valid email address'
      },
      regexp: {
        regexp: AdvancedEmailValidationRegex,
        message: 'Please enter a valid email address'
      },
      remote: {
        type: 'POST',
        url: window.emailValidationURL,
        message: 'Email address is already in use'
      }
    }
  },
  PasswordValidator: {
    validators: {
      notEmpty: {
        message: 'Please enter your password'
      },
      stringLength: {
        min: 6,
        max: 20,
        message: 'Password must be between 6 to 20 characters'
      }
    }
  },
  IdenticalPassword: {
    trigger: 'blur',
    validators: {
      notEmpty: {
        message: 'Please choose a password'
      },
      stringLength: {
        min: 6,
        max: 20,
        message: 'Your password must be between 6 and 20 characters'
      }
    }
  },
  IdenticalConfirmPassword: {
    trigger: 'blur',
    validators: {
      notEmpty: {
        message: 'The password and confirmation password do not match'
      },
      stringLength: {
        min: 6,
        max: 20,
        message: 'Your password must be between 6 and 20 characters'
      },
      identical: {
        field: 'password',
        message: 'The password and confirmation password do not match'
      }
    }
  },
  CCVAlidator: {
    trigger: 'keyup blur',
    validators: {
      notEmpty: {
        message: 'Please enter a valid card number'
      },
      creditCard: {
        message: 'Please enter a valid card number'
      }
    }
  },
  MonthValidator: {
    container: '.cc-expiry',
    trigger: 'blur change',
    validators: {
      callback: {
        message: 'Please select the correct card expiration month and year',
        callback: function(month, validator) {
          var year = validator.getFieldElements('year').val();
          var isValid = validateMonthYear(month, year);
          if (isValid) {
            validator.updateStatus('year', 'VALID');
          }
          return isValid;
        }
      }
    }
  },
  YearValidator: {
    container: '.cc-expiry',
    trigger: 'blur change',
    validators: {
      callback: {
        message: 'Please select the correct card expiration month and year',
        callback: function(year, validator) {
          var month = validator.getFieldElements('month').val();
          var isValid = validateMonthYear(month, year);
          if (isValid) {
            validator.updateStatus('month', 'VALID');
          }
          return isValid;
        }
      }
    }
  },
  CVVValidator: {
    trigger: 'keyup blur',
    validators: {
      notEmpty: {
        message: 'Please enter the correct CVV'
      },
      cvv: {
        creditCardField: 'ccnumber',
        message: 'Please enter the correct CVV'
      }
    }
  },
  CheckBoxValidator: {
    validators: {
      notEmpty: {
        message: 'Please check the box to agree to the terms'
      }
    }
  },
  CheckBoxMembershipValidator: {
    validators: {
      callback: {
        callback: function (value, validator, $field) {
          var checked = $($field).is(':checked');
          var payload = $($field).attr('payload');
          var message = 'Please check the box confirming that you agree to lose access to phone searches on the date indicated.';
          if (payload) {
            message = 'Please check the box confirming that you agree to lose access to future updates to ' + payload + '.';
          }
          return {
            valid: checked === true,
            message: message
          }
        }
      }
    }
  },
  RadioChangeSubReasonValidator: {
    validators: {
      notEmpty: {
        message: 'Please choose reason for your cancelation.'
      },
      callback: {
        message: 'Please provide details for your cancelation.',
        callback: function(value, validator, $field) {
          if(value === 'Other') {
            var feedback = validator.getFieldElements('feedback').val();
            var isValid = $.trim(feedback).length > 0;
            validator.updateStatus('feedback', isValid ? 'VALID' : 'INVALID');
          } else {
            validator.updateStatus('feedback', 'VALID');
          }
          return true;
        }
      }
    }
  },
  TextareaChangeSubReasonValidator: {
    trigger: 'keyup blur',
    validators: {
      callback: {
        message: 'Please provide details for your cancelation.',
        callback: function(value, validator, $field) {
          var reason = validator.getFieldElements('changeSubReason').filter(':checked').val();
          if(reason === 'Other') {
            if($.trim(value).length === 0) {
              return false;
            }
            else {
              return true;
            }
          }
          return true;
        }
      }
    }
  },
  StringValidator: function(min, max, field) {
    return {
      trigger: 'keyup blur',
      validators: {
        notEmpty: {
          message: field ? field + ' cannot be empty' : 'This field cannot be empty'
        },
        stringLength: {
          min: min,
          max: max,
          message: field ? field + ' must be between ' + min + ' to ' + max + ' characters' : 'Must be between ' + min + ' to ' + max + ' characters'
        }
      }
    };
  },
  DropdownValidator: {
    trigger: 'blur change',
    validators: {
      notEmpty: {
        message: 'Please choose from the drop down'
      }
    }
  },
  enableLazyValidation: enableLazyValidation
};