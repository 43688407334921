/* globals google: true */
var radar;
var styles = [
  {
    "featureType": "all",
    "elementType": "labels",
    "stylers": [{"visibility": "off"}]
  }
];

function init(container) {
  var map = new google.maps.Map(document.getElementById(container), {
    center: {lat: 30.8904725, lng: -100.466748},
    disableDefaultUI: true,
    scrollwheel: false,
    navigationControl: false,
    mapTypeControl: false,
    scaleControl: false,
    draggable: false,
    mapTypeId: google.maps.MapTypeId.SATELLITE,
    zoom: 4
  });
  map.setOptions({styles: styles});
  return map;
}

function addMarker(map, location) {
  var icon = window.weStaticAssetPrefix + '/images/cell-tower.png';
  return marker = new google.maps.Marker({
    position: new google.maps.LatLng(location.lat, location.lng),
    icon: icon,
    map: map
  });
}

function clearMarker(map, marker) {
  marker.setMap(null);
}

function getDistance(point1, point2) {
  var placePosition = new google.maps.LatLng(point1.lat, point1.lng);
  return google.maps.geometry.spherical.computeDistanceBetween(placePosition, new google.maps.LatLng(point2.lat, point2.lng));
}

function getOffsetBounds(map, bounds) {
  var zoomSteps = 2;
  var offsetx = 50;
  var offsety = $('.map-initiated-modal').height();
  var point1 = map.getProjection().fromLatLngToPoint(bounds.getSouthWest());
  var point2 = new google.maps.Point(
    ( (typeof(offsetx) === 'number' ? offsetx : 0) / Math.pow(2, map.getZoom() + zoomSteps) ) || 0,
    ( (typeof(offsety) === 'number' ? offsety : 0) / Math.pow(2, map.getZoom() + zoomSteps) ) || 0
  );
  var point = map.getProjection().fromPointToLatLng(new google.maps.Point(
    point1.x - point2.x,
    point1.y + point2.y
  ));
  bounds.extend(point);
  return bounds;
}

function getCircle(center) {
  return new google.maps.Circle({center: new google.maps.LatLng(center.lat, center.lng), radius: 2000000, strokeColor: '#C0C0C0', strokeWeight: 1});
}

function showRadar(map, center, callback) {
  if (!radar) {
    radar = getCircle(center);
  }

  var radius = radar.getRadius();
  radar.setMap(map);

  var redraw = function() {
    var r = radar.getRadius() - parseInt(radius * 0.05);
    radar.setRadius(r);
    if(r > radius/4 ) {
      setTimeout(redraw, 100);
      return;
    }
    if (map.getProjection()) {
      map.fitBounds(getOffsetBounds(map, radar.getBounds()));
    }
    if(callback) {
      callback();
    }
  };
  redraw();
}

module.exports = {
  init: init,
  addMarker: addMarker,
  clearMarker: clearMarker,
  getDistance: getDistance,
  showRadar: showRadar
};