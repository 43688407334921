/* globals hMapApiKey: true, H: true */
var radar;

function getTilesLayer(platform) {
  const rasterTileService = platform.getRasterTileService({
    queryParams: {
      style: 'satellite.day'
    }
  });
  const rasterTileProvider = new H.service.rasterTile.Provider(rasterTileService);
  return new H.map.layer.TileLayer(rasterTileProvider);
}

function init(container) {
  const containerEle = document.getElementById(container);
  var platform = new H.service.Platform({ apikey: hMapApiKey, useHTTPS: true});
  var map = new H.Map(containerEle, getTilesLayer(platform), {
    center: {lat: 30.8904725, lng: -100.466748},
    zoom: 4,
    engineType: H.map.render.RenderEngine.EngineType.P2D
  });
  return map;
}

function addMarker(map, location) {
  var icon = new H.map.Icon(window.weStaticAssetPrefix + '/images/cell-tower.png');
  var marker = new H.map.Marker({ lat: location.lat, lng: location.lng }, { icon: icon });
  map.addObject(marker);
  return marker;
}

function clearMarker(map, marker) {
  map.removeObject(marker);
}

function getDistance(point1, point2) {
  var placePosition = new H.geo.Point(point1.lat, point1.lng);
  return placePosition.distance(new H.geo.Point(point2.lat, point2.lng));
}

function setOffsetBounds(map, bounds) {
  var leftBottom = map.geoToScreen(new H.geo.Point(bounds.getBottom(), bounds.getLeft()));
  var center = map.screenToGeo(
    leftBottom.x - 50,
    leftBottom.y + 75
  );
  map.setCenter(center, true);
  setTimeout(function () {
    map.getViewModel().setLookAtData({
      bounds: bounds.mergePoint(center)
    }, true);
  }, 500);

}

function getCircle(center) {
  return new H.map.Circle({ lat: center.lat, lng: center.lng }, 2000000, { style: { strokeColor: '#C0C0C0', lineWidth: 1 } });
}

function showRadar(map, center, callback) {
  if (!radar) {
    radar = getCircle(center);
  }

  var radius = radar.getRadius();
  map.addObject(radar);

  var redraw = function() {
    var r = radar.getRadius() - parseInt(radius * 0.05);
    radar.setRadius(r);
    if(r > radius/4 ) {
      setTimeout(redraw, 100);
      return;
    }
    setOffsetBounds(map, radar.getBoundingBox());
    if(callback) {
      callback();
    }
  };
  redraw();
}

module.exports = {
  init: init,
  addMarker: addMarker,
  clearMarker: clearMarker,
  getDistance: getDistance,
  showRadar: showRadar
};